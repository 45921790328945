import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import { activateAccount } from "../../redux/actions/user"

export default function Activate() {
  const { user } = useSelector((state) => ({
    user: state.user,
  }))
  const [pending, setPending] = useState(false)
  const [succeeded, setSucceeded] = useState(false)
  const [errorMessage, setErrorMessage] = useState()
  const dispatch = useDispatch()

  if (succeeded) {
    return (
      <div className="forgot-password">
        <form>
          <p className="heading">Email sent!</p>
          <p className="success-msg">
            An email containing instructions to set a password has been sent to{" "}
            <b>{user.email}</b>.
          </p>

          <Link to="/login">Log In</Link>
        </form>
      </div>
    )
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    const form = e.target
    const email = form.querySelector("[name=email]").value
    let activateResponse = {}
    setPending(true)

    try {
      activateResponse = await activateAccount(email, dispatch)
      setSucceeded(true)
    } catch (err) {
      setErrorMessage(err.message)
      return
    } finally {
      setPending(false)
    }
  }

  return (
    <div className="login-container">
      <form onSubmit={onSubmit}>
        <h1>Activate your account</h1>
        <p className="heading">Enter the email you used for registration.</p>
        {errorMessage !== undefined && (
          <p className="error-msg">{errorMessage}</p>
        )}
        <input
          type="email"
          name="email"
          placeholder="you@organization.com"
          defaultValue={user.email}
        />
        <input type="submit" value="Submit" disabled={pending} />
        <div className="new-to-kinetic">
          <Link to="login">Cancel</Link>
        </div>
      </form>
    </div>
  )
}
