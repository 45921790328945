import { API } from "../../utils/api"

export const GET_COMPANY_LIST = "admin/GET_COMPANY_LIST"
export const GET_MANAGER_LIST = "admin/GET_MANAGER_LIST"
export const SET_CURRENT_COMPANY = "admin/SET_CURRENT_COMPANY"
export const CLEAR_CURRENT_COMPANY = "admin/CLEAR_CURRENT_COMPANY"
export const SET_CURRENT_ADMIN_LOCATION = "admin/SET_CURRENT_LOCATION"
export const CLEAR_CURRENT_ADMIN_LOCATION = "admin/CLEAR_CURRENT_LOCATION"
export const GET_SELF_SERVE_LIST = "admin/GET_SELF_SERVE_LIST"
export const GET_DATA = "admin/GET_DATA"
export const GET_CONNECTIVITY_DEVICES = "admin/GET_CONNECTIVITY_DEVICES"
export const GET_SETTINGS_PACKAGES = "admin/GET_SETTINGS_PACKAGES"
export const SET_DARK_MODE = "admin/SET_DARK_MODE"
export const GLOBAL_SEARCH = "admin/GLOBAL_SEARCH"

export async function saveCompany(company) {
  const { reporting_ergo_status, reporting_ct_status } = company
  const created_company = await API.createCompany(
    company.name,
    company.allowed_domains
  )
  await updateCompanies(
    Object.assign(created_company, {
      reporting_ct_status,
      reporting_ergo_status,
    })
  )
}

export async function updateCompanies(company) {
  await API.adminUpdateCompanies([company])
}

export async function clearCurrentCompany(dispatch) {
  dispatch({
    type: CLEAR_CURRENT_COMPANY,
    payload: {
      locationsList: [],
      selectedCompany: "",
    },
  })
}

export async function setCurrentCompany(companyUID, dispatch) {
  // const data = await API.getCompanyLocations(companyUID)
  const res = await API.adminCompanyLocationsGet(companyUID)
  dispatch({
    type: SET_CURRENT_COMPANY,
    payload: {
      locationsList: res,
      selectedCompany: companyUID,
    },
  })
}

export async function setCurrentLocation(location, dispatch) {
  API.locationUID = location
  let data = await API.getAdminData(location)
  const wusData = await API.getLocationsWus(location)

  data.devices = wusData
  dispatch({
    type: SET_CURRENT_ADMIN_LOCATION,
    payload: {
      selectedLocation: location,
      locationData: data,
    },
  })
}

export async function clearCurrentLocation(dispatch) {
  dispatch({
    type: CLEAR_CURRENT_ADMIN_LOCATION,
    payload: {
      selectedLocation: "",
      locationData: { location: {} },
    },
  })
}

export async function saveLocation(location, mode, dispatch) {
  let res
  if (mode === "Add") {
    res = await API.adminLocationsPost([location])
  } else {
    res = await API.adminLocationsPut([location])
  }
  setCurrentLocation(location.uid, dispatch)
  return res
}

export async function saveJob({ job, mode }) {
  mode === "Add" && (await API.createJob(job))
  mode === "Edit" && (await API.editJob(job))
}

export async function saveEmployee({ employee, mode, dispatch, location }) {
  if (mode == "Add") {
    const created = await API.createWorker(employee)

    if (employee.device) {
      await API.createDeviceAssignment(created.uid, employee.device)
    }
  }
  if (mode === "Edit") {
    await API.updateWorker(employee)
  }
  setCurrentLocation(location, dispatch)
}

export async function batchAddEmployees(file, autoAssign) {
  await API.uploadWorkerTemplate(file, autoAssign)
}

export async function getCompanyList(dispatch) {
  const data = await API.getCompanies()
  dispatch({
    type: GET_COMPANY_LIST,
    data: data,
  })
}

export async function getManagerList(companyUID, dispatch) {
  const data = await API.getManagers(companyUID)
  let manObj = {}
  data.map((i) => {
    if (!manObj[i.uid]) {
      manObj[i.uid] = i
      manObj[i.uid].locations = [i.groupUID]
    } else {
      manObj[i.uid].locations = [...manObj[i.uid].locations, i.groupUID]
    }
  })
  dispatch({
    type: GET_MANAGER_LIST,
    data: Object.values(manObj),
  })
}

export async function getSelfServeList(dispatch) {
  let data = await API.getSelfServeList()
  dispatch({
    type: GET_SELF_SERVE_LIST,
    data: data,
  })
}

export async function getData(dispatch) {
  let data = await API.getData()
  dispatch({
    type: GET_DATA,
    data: data,
  })
}

export async function changePassword(currentPassword, newPassword) {
  await API.changePassword(currentPassword, newPassword)
}

export async function getWus() {
  let data = await API.getWus()
  return data
}

export async function batchUpdateWus(device) {
  await API.batchUpdateWus([device])
}

export async function saveManager({
  manager,
  companyUID,
  mode,
  subscriptions,
  dispatch,
}) {
  mode === "Add" && (await API.addManager(manager, companyUID))
  mode === "Edit" &&
    (await API.updateManager(
      { ...manager, companyUID: companyUID },
      companyUID
    ))

  // this is disabled in the UI for now
  // if (subscriptions) {
  //   await API.setReportSubscriptionStatuses(subscriptions)
  // }
  await getManagerList(companyUID, dispatch)
}

export async function deleteManager({
  manager,
  companyUID,
  dispatch,
}) {
  await API.deleteManager(manager, companyUID)
  await getManagerList(companyUID, dispatch)
}

export async function getTermini() {
  await API.getTermini()
}

export async function getConnectivityDevices(dispatch) {
  const data = await API.getConnectivityDevices()
  dispatch({
    type: GET_CONNECTIVITY_DEVICES,
    data: data,
  })
}

export async function getSettingsPackages(dispatch) {
  const data = await API.getSettingsPackages()
  const shapedData = data.data.map((h) => {
    return { ...h.package, count: h.count }
  })
  dispatch({
    type: GET_SETTINGS_PACKAGES,
    data: shapedData,
  })
}

export async function saveSettingsPackage({ settingsPackage, mode }) {
  mode === "Add" && (await API.createSettingsPackage(settingsPackage))
  mode === "Edit" && (await API.updateSettingsPackage(settingsPackage))
}

export function setDarkMode(value, dispatch) {
  dispatch({
    type: SET_DARK_MODE,
    value: value,
  })
}

export function navbarGlobalSearch({ searchArgs, category }, dispatch) {
  dispatch({
    type: GLOBAL_SEARCH,
    value: {
      searchArgs: searchArgs,
      category: category,
    },
  })
}
