import React from "react"

import { Checkbox } from "../"

import { Icon } from "../../ui"
import ellipsis from "../../ui/icons/ellipsis.svg"
import xmark from "../../ui/icons/x-mark.svg"
import smallBlackArrow from "../../ui/icons/arrow-sm-blk.svg"
import largeBlackArrow from "../../ui/icons/arrow-lg-blk.svg"

class SelectBoxChoice extends React.Component {
  render() {
    const { chosen, choiceKey, value, makeSelection, multi } = this.props
    const full = this.props.full

    let chosenClass = chosen === true ? "chosen" : ""
    let checkBox = ""

    if (multi) {
      checkBox = <Checkbox checked={chosen} />
    }

    return (
      <div
        className={`choice ${chosenClass}`}
        onClick={() => {
          makeSelection(choiceKey)
        }}
      >
        {checkBox}
        {value}
      </div>
    )
  }
}

function none_key(options) {
  for (const [key, value] of Object.entries(options)) {
    if (value.toLowerCase() === "none") {
      return key
    }
  }
  return false
}

class SelectBoxChoiceList extends React.Component {
  render() {
    const {
      options,
      opened,
      makeSelection,
      currentChoice,
      multi,
      heading,
      alphasort,
    } = this.props
    const openClass = opened === true ? "active" : ""
    const multiClass = multi === true ? "multi" : ""

    let headingElem = ""

    let useOptions = Object.keys(options)

    if (alphasort === true) {
      useOptions = Object.keys(options).sort((a, b) => {
        return options[a] > options[b] ? 1 : -1
      })

      const key = none_key(options)
      if (key) {
        const idx = useOptions.indexOf(key)
        useOptions.splice(idx, 1)
        useOptions.unshift(key)
      }
    }

    if (heading) {
      headingElem = (
        <div className="choices-heading">
          {heading}
          <img
            src={xmark}
            className="close"
            role="presentation"
            onClick={this.clearSelection}
          />
        </div>
      )
    }

    return (
      <div className={`choices ${openClass} ${multiClass}`}>
        {headingElem}
        {useOptions.map((key) => (
          <SelectBoxChoice
            choiceKey={key}
            chosen={
              multi ? currentChoice.indexOf(key) > -1 : currentChoice === key
            }
            key={key}
            value={options[key]}
            makeSelection={makeSelection}
            multi={multi}
          />
        ))}
      </div>
    )
  }
}

/**
 * Select Box / Dropdown Menu component
 *
 * @prop onChange (fn) - a function passed in that is run whenever a selection is Made
 * @prop options (obj {k:v}) - select box options and a key that represents them
 * @prop pickerType (string)
 * - `arrow` can be used to render a simple dropdown enoted by an arrow instead
 * - `ellipsis` can be used to render the dropdown with three dots
 * - `color` can be used to colorize the background using the primary color
 * - `chip` can be used to render a Material UI-style chip
 *
 * @prop label (string)
 * - for all `pickerType !== chip` string displays next to the box
 * - for `pickerType === chip` the label will be the text in the chip if no option is selected
 * @prop initialValue (string) - should match a given key in options
 * @prop staticValue (string) - this value will ALWAYS show in the box, regardless of choice
 * @prop disabled (bool) - when disabled, a disabled class is supplied, and viewing choices does not work
 * @prop hoverMessage (string) - when provided, this message will pop up when the user hovers over the component
 * @prop multi (bool) - when true, you can select multiple options by checking them
 * @prop hidden (bool) - when true, adds a 'hidden' class to the select box
 * @prop selectBoxHeading (string) - when set, adds a heading to `SelectBoxChoices` with the provided text and close button
 * @prop typeAhead (bool) - when `true`, allows a user to enter text that filters on values in the `options` object
 * @prop alphasort (bool) - when `true`, sorts the values in the dropdown from A-Z
 */
export class SelectBox extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      enabled: true,
      opened: false,
      chosenOption: [],
      typeAhead: false,
    }
  }

  componentDidUpdate(_, prevState) {
    // whenever the select box is open, add an event listener to the body
    // any clicks outside the select box will auto-close the box
    if (this.state.opened) {
      const self = this
      window.$("body").on("click", function (evt) {
        if (
          !window.$(evt.target).within(".select-box-container.opened .choices")
            .length
        ) {
          if (evt.target.className != "check-check") {
            self.setState({ opened: false })
          }
        }
      })
    } else if (prevState.active) {
      // cleanup the body click event
      window.$("body").off("click")
    }
  }

  componentWillUnmount() {
    // cleanup the body click event
    window.$("body").off("click")
  }

  toggleOpen = () => {
    const { disabled } = this.props
    const { opened } = this.state
    const enabled = !disabled

    if (enabled) {
      this.setState({ opened: !opened })
    }
  }

  /**
   * makeSelection runs whenever a choice in the list is clicked on
   * passes the `id` prop of the selectbox into the given `onChange`
   * function so you can determine which selectbox is making the change
   * (helpful if using multiple selectboxes)
   *
   * @prop choice - corresponds with a key in the given `options` object
   */
  makeSelection = (choice) => {
    const { onChange, id, multi } = this.props

    if (multi) {
      let { chosenOption } = this.state
      const choiceIndex = chosenOption.indexOf(choice)
      if (choiceIndex < 0) {
        chosenOption.push(choice)
      } else {
        chosenOption.splice(choiceIndex, 1)
      }
      this.setState({
        chosenOption: chosenOption,
      })

      // pass in the option key to the given onChange function if the key is valid
      if (onChange !== undefined) {
        onChange(chosenOption)
      }
    } else {
      this.setState({
        chosenOption: choice,
      })

      // clean up the body click event just in case
      window.$("body").off("click")
      // pass in the option key to the given onChange function if the key is valid
      if (onChange !== undefined) {
        onChange(choice, id)
      }
    }
  }

  clearSelection = () => {
    const { onChange, multi, initialValue } = this.props

    const newVal = !multi ? initialValue : []

    this.setState({
      chosenOption: newVal,
    })

    if (onChange !== undefined) {
      onChange(newVal)
    }

    // clean up the body click event just in case
    window.$("body").off("click")
  }

  render() {
    const {
      label,
      options,
      initialValue,
      staticValue,
      pickerType,
      disabled,
      key,
      multi,
      hidden,
      hoverMessage,
      selectBoxHeading,
      typeAhead,
      alphasort,
    } = this.props
    const { opened, chosenOption } = this.state
    const defaultValue =
      initialValue !== undefined && options[initialValue]
        ? options[initialValue]
        : initialValue || "Select"
    const openClass = opened === true ? "active" : ""
    const disabledClass = disabled === true ? "disabled" : ""
    const hiddenClass = hidden ? "hidden" : ""

    let extraClass = ""

    let labelElem =
      label !== undefined && pickerType !== "chip" ? <label>{label}</label> : ""

    let currentSelection =
      chosenOption !== undefined &&
        chosenOption.length > 0 &&
        Object.keys(options).indexOf(chosenOption) > -1
        ? options[chosenOption]
        : defaultValue

    // to be used to build a tooltip-like hover
    let hoverElem = ""
    // to be used to make
    let clearElem = ""

    if (multi) {
      if (chosenOption.length > 0) {
        currentSelection = chosenOption.length + " Selected"
      }
    }

    if (
      chosenOption &&
      chosenOption.length > 0 &&
      chosenOption !== initialValue
    ) {
      extraClass = "some"
    } else {
      extraClass = ""
    }

    if (pickerType === "chip") {
      if (
        chosenOption &&
        chosenOption.length > 0 &&
        chosenOption !== initialValue
      ) {
        clearElem = (
          <img
            src={xmark}
            className="clearer"
            role="presentation"
            onClick={this.clearSelection}
          />
        )
      }

      if (!multi && (chosenOption === undefined || chosenOption.length === 0)) {
        currentSelection = label
      }
    }

    // staticValue overrides all
    if (staticValue) {
      currentSelection = staticValue
    }

    if (typeAhead === true) {
      currentSelection = <input type="text" value={currentSelection} />
    }

    let picker = (
      <div
        className={`picker ${opened ? "Open" : ""
          } ${pickerType} ${disabledClass} ${extraClass}`}
        onClick={this.toggleOpen}
      >
        {currentSelection}
        <Icon iconClass={"Chevron-down"} secondaryClass={"arrow-icon"} />
      </div>
    )

    if (pickerType === "arrow") {
      picker = (
        <div
          className={`picker ${pickerType} ${disabledClass}`}
          onClick={this.toggleOpen}
        >
          <img
            src={largeBlackArrow}
            className="arrow-icon-lg"
            role="presentation"
          />
        </div>
      )
    }

    if (pickerType === "ellipsis") {
      picker = (
        <div
          className={`picker ${pickerType} ${disabledClass}`}
          onClick={this.toggleOpen}
        >
          <img src={ellipsis} className="ellipsis" role="presentation" />
        </div>
      )
    }

    if (hoverMessage !== undefined && hoverMessage !== "") {
      hoverElem = <div className="hover-message">{hoverMessage}</div>
    }

    return (
      <div
        key={key}
        className={`select-box-container ${openClass} ${hiddenClass}`}
      >
        {label && labelElem}
        {hoverElem}
        <div className={`select-box ${this.props.full && "full"}`}>
          {clearElem}
          {picker}
          <SelectBoxChoiceList
            options={options}
            opened={opened}
            makeSelection={this.makeSelection}
            currentChoice={chosenOption}
            multi={multi}
            heading={selectBoxHeading}
            alphasort={alphasort}
          />
        </div>
      </div>
    )
  }
}
