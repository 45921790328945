/* orderColumns takes an array of strings and uses them to rebuild the order
 * of the provided array of objects
 *
 * @param [] requestedOrder - array of strings (requested order)
 * @param [] rowsToReorder - array of objects
 * return [] reordered rows
 */
export default function orderColumns(rowsToReorder, requestedOrder) {
  const reorderedArray = rowsToReorder.map((row) => {
    const newItem = {}

    requestedOrder.forEach((e) => {
      newItem[e] = row[e]
    })

    Object.assign(newItem, row)

    return newItem
  })

  return reorderedArray
}
