/* eslint-disable */

import {
  GET_COMPANY_LIST,
  SET_CURRENT_COMPANY,
  CLEAR_CURRENT_COMPANY,
  GET_MANAGER_LIST,
  SET_CURRENT_ADMIN_LOCATION,
  CLEAR_CURRENT_ADMIN_LOCATION,
  GET_SELF_SERVE_LIST,
  GET_DATA,
  GET_CONNECTIVITY_DEVICES,
  GET_SETTINGS_PACKAGES,
  SET_DARK_MODE,
  GLOBAL_SEARCH,
} from "../actions/admin"

// --------------------------- Reducer function --------------------------
const initialState = {
  companyList: [],
  selfServeLocations: [],
  locationsList: [],
  presetJobsList: [],
  locationData: {},
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_COMPANY_LIST:
      return {
        ...state,
        companyList: action.data[0],
        companyMap: action.data[1],
        //presetJobsList: presetJobsListData
      }
    case GET_MANAGER_LIST:
      return {
        ...state,
        managerList: action.data,
      }
    case GET_SELF_SERVE_LIST:
      return {
        ...state,
        selfServeLocations: action.data,
      }
    case CLEAR_CURRENT_COMPANY:
      return {
        ...state,
        locationsList: action.payload.locationsList,
        selectedCompany: action.payload.selectedCompany,
      }
    case SET_CURRENT_COMPANY:
      return {
        ...state,
        locationsList: action.payload.locationsList,
        selectedCompany: action.payload.selectedCompany,
      }
    case GET_DATA:
      return {
        ...state,
        data: action.data,
      }
    case SET_CURRENT_ADMIN_LOCATION:
      return {
        ...state,
        selectedLocation: action.payload.selectedLocation,
        locationData: action.payload.locationData,
        locationWus: action.payload.locationWus,
      }
    case CLEAR_CURRENT_ADMIN_LOCATION:
      return {
        ...state,
        locationData: action.payload.locationData,
      }
    case GET_CONNECTIVITY_DEVICES:
      return {
        ...state,
        connectivityDevices: action.data,
      }
    case "STORE_SELECTED_ITEMS":
      return {
        ...state,
        selectedCheckboxItems: action.data,
      }
    case "CLEAR_TABLE":
      return {
        ...state,
        clear_table: action.data,
      }
    case "SELECTED_EMPLOYEES":
      return {
        ...state,
        selected_employees: action.data,
      }
    case "FROM_PATH":
      return {
        ...state,
        fromPath: action.value,
      }
    case GET_SETTINGS_PACKAGES:
      return {
        ...state,
        settingsPackages: action.data,
      }
    case SET_DARK_MODE:
      return {
        ...state,
        darkMode: action.value,
      }
    case GLOBAL_SEARCH:
      return {
        ...state,
        globalSearchConfig: action.value,
      }
    default:
      return state
  }
}
