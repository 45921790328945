import React, { useEffect, useReducer, useState, useRef } from "react"
import { useParams } from "react-router-dom"
import { useSelector } from "react-redux"

import { Form, Input, Loading, Icon, WizardBar } from "../../../components/ui"

import { copyToClipboard, setMode } from "../../../pages/utils"

import { useBreadCrumbsContext } from "../../../hooks"

import { saveSettingsPackage } from "../../../redux/actions/admin"
import { API } from "../../../utils/api"

const reducer = (state, action) => {
  switch (action.type) {
    case "MODE":
      return {
        ...state,
        mode: action.value,
      }
    case "POPULATE":
      return {
        ...state,
        settingsPackage: {
          ...state.settingsPackage,
          [action.key]: action.value,
        },
      }
    default:
      break
  }
  return state
}

const initialState = {
  mode: "",
}

export default function SettingsPackageDetailPage() {
  const { settingsPackages } = useSelector((state) => ({
    settingsPackages: state.admin?.settingsPackages,
  }))
  const params = useParams()
  const [state, localDispatch] = useReducer(reducer, initialState)
  const [render, setRender] = useState(false)
  const [settingsPackage, setSettingsPackage] = useState(null)
  const [copied, setCopied] = useState(false)
  const _copyButton = useRef()
  const passBreadCrumbs = useBreadCrumbsContext()

  useEffect(() => {
    setMode(localDispatch)
  }, [])

  async function fetchSettingsPackage() {
    const _package = await API.getSettingsPackages(params.settingspackageuid)
    setSettingsPackage(_package.data[0].package)
  }

  useEffect(() => {
    if (settingsPackages && params.settingspackageuid) {
      setSettingsPackage(
        settingsPackages.filter((o) => {
          if (o.uid === params.settingspackageuid) {
            return o
          }
        })[0]
      )
    } else if (params.settingspackageuid) {
      fetchSettingsPackage()
    } else {
      setRender(true)
    }
  }, [])

  useEffect(() => {
    if (settingsPackage) {
      populate(settingsPackage)
      setTimeout(() => {
        setRender(true)
      }, 0)
    }
  }, [settingsPackage])

  const populate = (settingsPackage) => {
    for (let [key, value] of Object.entries(settingsPackage)) {
      localDispatch({ type: "POPULATE", key: key, value: value })
    }
  }

  const handleInput = (e, field) => {
    const value =
      field === "feature_flags"
        ? e.target.value.replace(/[ ,]+/g, ",").split(",")
        : e.target.value
    if (field === "jobUID" && !state?.employee?.jobUID) {
      delete state.employee.job
    }
    localDispatch({ type: "POPULATE", key: field, value: value })
  }

  const handleCopy = () => {
    copyToClipboard(state.settingsPackage?.feature_flags)
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 5000)
  }

  const form = () => {
    return (
      <Form>
        {state.mode === "View" && (
          <Input
            disabled
            mode={state.mode}
            label="Default Configuration"
            value={state.settingsPackage?.default_package}
            onChange={() => {
              return null
            }}
          />
        )}
        {state.mode !== "Add" && (
          <Input
            mode={state.mode}
            icons
            disabled
            label="Product Configuration UID"
            value={state.settingsPackage?.uid}
          />
        )}
        <Input
          mode={state.mode}
          label="Configuration Name"
          value={state.settingsPackage?.name}
          onChange={(e) => handleInput(e, "name")}
        />
        <Input
          mode={state.mode}
          label="Description"
          value={state.settingsPackage?.description}
          onChange={(e) => handleInput(e, "description")}
        />
        {state.mode === "View" ? (
          <div className="input-container">
            <div className="Feature-flag-list">
              <label>Feature Flags</label>
              {state.settingsPackage?.feature_flags &&
                state.settingsPackage?.feature_flags.map((i, key) => (
                  <p key={key + 1.2322}>{i}</p>
                ))}
            </div>
            <div
              ref={_copyButton}
              className={`Input-copy-button button ${
                copied ? "Copied" : "Copy"
              } Text-field`}
              onClick={() => handleCopy()}
            >
              <p>
                {copied ? (
                  <Icon iconClass="Circled-checkmark" />
                ) : (
                  <Icon iconClass="Copy" />
                )}
              </p>
            </div>
          </div>
        ) : (
          <Input
            mode={state.mode}
            label="Feature Flags"
            value={state.settingsPackage?.feature_flags}
            onChange={(e) => handleInput(e, "feature_flags")}
            textArea={true}
          />
        )}
      </Form>
    )
  }

  return (
    <div className="admin wrapper Settings-packages-detail">
      <main className="container">
        {passBreadCrumbs([
          {
            content: <Icon iconClass={"Cog-solid"} />,
            route: "/utilities#settings-packages",
          },
          {
            content:
              state.mode !== "View"
                ? state.mode + " Product Configuration"
                : state.settingsPackage?.name,
            route: "",
          },
        ])}
        <div className="main-content detail-page">
          <h1 className="main-heading">
            {`${state.mode} Product Configuration ${
              state.mode !== "Add" ? ` - ${state.settingsPackage?.name}` : ""
            }`}
          </h1>
          {render ? (
            form()
          ) : (
            <Loading
              background={"none"}
              positionRelative={true}
              height={"400px"}
            />
          )}
          <WizardBar
            backToRoute={"/utilities#settings-packages"}
            mode={state.mode}
            setMode={(mode) => {
              populate(settingsPackage)
              localDispatch({ type: "MODE", value: mode })
            }}
            apiCall={saveSettingsPackage}
            apiArgs={{
              settingsPackage: state.settingsPackage,
              mode: state.mode,
            }}
            redirectRoute={"/utilities#settings-packages"}
            disabled={
              state.settingsPackage?.name
                ? state.settingsPackage?.name.length < 1
                  ? true
                  : false
                : true
            }
            header={"Confirm"}
            promptMessage={
              state.mode === "Add"
                ? "Do you want to add a new product configuration?"
                : "Do you want to save your changes?"
            }
            category={"Product Configuration"}
            toastMessage={
              state.mode === "Add"
                ? `Product Configuration "${state.settingsPackage?.name}" successfully added!`
                : `Product Configuration "${state.settingsPackage?.name}" successfully updated!`
            }
          />
        </div>
      </main>
    </div>
  )
}
