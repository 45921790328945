import React, { useState, useEffect, useRef } from "react"

export function Loading({
  message,
  headline = "Oh no!",
  errorState,
  background = "rgba(0, 0, 0, 0.5",
  setLoading,
  backdropFilter = "none",
  positionRelative,
  height,
  maxWidth = "500px",
}) {
  const [error, setError] = useState(false)
  const _loadingContainer = useRef()
  const _loadingBox = useRef()
  let styles = { background: background, backdropFilter: backdropFilter }

  useEffect(() => {
    if (errorState) {setError(true)}
    else {setError(false)}
  }, [errorState])

  const closingAnitmation = () => {
    _loadingContainer.current.classList.add("Close")
    setTimeout(() => setLoading(false), 1000)
  }

  if (positionRelative)
    {styles = {
      ...styles,
      position: "relative",
      width: "100%",
      height: height,
    }}

  return (
    <div className="Loading-container" ref={_loadingContainer} style={styles}>
      <div
        className="loading-box"
        ref={_loadingBox}
        style={{ maxWidth: maxWidth }}
      >
        {error && (
          <>
            <div className="Error">
              <img
                className="error-image"
                src={process.env.PUBLIC_URL + "/images/alert-triangle.png"}
              />
            </div>
            <p className="Loading-headline">{headline}</p>
            <p className="Loading-message">{message ? message : "Error"}</p>
            <div className="button" onClick={closingAnitmation}>
              DISMISS
            </div>
          </>
        )}
        {!error && (
          <>
            <div className="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <p className="Loading-message">
              {message ? message : "Loading..."}
            </p>
          </>
        )}
      </div>
    </div>
  )
}
