import React from "react"

const ThemePreview = ({ theme, changeNavTheme, active }) => {
  const primaryColor = { backgroundColor: theme.primaryColor }

  return (
    <div
      className={`Theme-color-option ${
        active === theme.class ? "Active" : ""
      } ${theme.class}`}
      style={primaryColor}
      onClick={
        theme.class
          ? (
              e,
              b = theme.primaryColor,
              c = theme.color,
              d = theme.darkModeColor,
              k = theme.class,
              s = theme.secondaryColor
            ) => changeNavTheme(e, b, c, d, k, s)
          : () => null
      }
    >
      <p className={`Theme-name ${active === theme.class ? "Active" : ""}`}>
        {theme.name}
      </p>
    </div>
  )
}

export default ThemePreview
