import React from "react"
import { Helmet } from "react-helmet"
import { API } from "../../../utils/api"

import PasswordPanel from "./password-panel"
import ThemePanel from "./theme-panel"

function AdminSettingsPage() {
  const page = () => {
    return (
      <div className="admin wrapper">
        <Helmet>
          <meta charSet="utf-8" />
          <title> Settings • Kinetic </title>
          <link rel="canonical" href="http://mysite.com/example" />
        </Helmet>
        <main className="container">
          <div className="main-content Settings">
            <h1 className="main-heading"> Settings </h1>
            <div className="Settings-container">
              <div className="Settings-row">
                <PasswordPanel />
              </div>
              <ThemePanel />
            </div>
          </div>
        </main>
      </div>
    )
  }

  return <>{page()}</>
}

export default AdminSettingsPage
