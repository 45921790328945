import { useAuth0 } from "@auth0/auth0-react"
import React, { useEffect, Suspense, useState } from "react"
import { withRouter, useLocation } from "react-router-dom"

import { ToastMessageProvider } from "../contexts/toast-message-provider"
import { Loading } from "../components/ui"

import "./_layout.scss"

const AdminMainPage = React.lazy(() => import("../pages/admin/root"))

function Layout({ children }) {
  const location = useLocation()
  const path = location.pathname.split("/")
  const auth = useAuth0()
  let baseLayout = undefined

  const [ready, readySet] = useState(false)

  useEffect(async () => {
    if (auth.isLoading) return null

    if (!auth.isAuthenticated) {
      readySet(true)
      auth.loginWithRedirect()
      return null
    }

    const token = await auth.getAccessTokenSilently({
      audience: "https://backend2.wearkinetic.com",
    })

    localStorage.setItem("adminToken", token)

    readySet(true)
  }, [auth.isLoading])

  if (!ready || (ready && !auth.isAuthenticated)) {
    return (
      <div className="breathing-container">
        <img
          className="breathing-logo"
          src={process.env.PUBLIC_URL + "/images/breathingicon.svg"}
        />
      </div>
    )
  }

  baseLayout = (
    <Suspense fallback={<Loading background={"none"} />}>
      <AdminMainPage>{children}</AdminMainPage>
    </Suspense>
  )

  return (
    <>
      <ToastMessageProvider>{baseLayout}</ToastMessageProvider>
    </>
  )
}
export default withRouter(Layout)
