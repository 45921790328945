import React, { useState } from "react"

import { Form, Input, Button } from "../../../../components/ui"

import { changePassword } from "../../../../redux/actions/admin"

export default function PasswordPanel() {
  const [oldPassword, setOldPassword] = useState()
  const [newPassword, setNewPassword] = useState()
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState()
  const [match, setMatch] = useState()

  const handleSubmit = () => {
    if (oldPassword && newPassword && match) {
      changePassword(oldPassword, newPassword)
    }
  }

  const compareStrings = (str1, str2) => {
    if (str1 === str2) {
      setMatch(true)
    } else {
      setMatch(false)
    }
  }

  const confirmPass = (e) => {
    setNewPasswordConfirmation(e.target.value)
    compareStrings(newPassword, e.target.value)
  }

  return (
    <div className="Settings-panel Password-panel">
      <h3 className="Settings-panel-header">Password</h3>
      <h4 className="Settings-header">Change Password</h4>
      <Form>
        <Input
          type={"password"}
          label="Old Password"
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
        />
        <Input
          type={"password"}
          label="New Password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <Input
          type={"password"}
          label="Confirm New Password"
          value={newPasswordConfirmation}
          onChange={(e) => confirmPass(e)}
        />
        {newPasswordConfirmation ? (
          match ? (
            <p>passwords match!</p>
          ) : (
            <p>passwords do not match!</p>
          )
        ) : null}
      </Form>
      <div className="Button-container">
        <Button onClick={handleSubmit}>SUBMIT</Button>
      </div>
    </div>
  )
}
