import React, { useEffect, useState, useRef } from "react"
import { useHistory } from "react-router-dom"
import { useDispatch } from "react-redux"
import { format } from "timeago.js"

import { DropDownSelect, Loading, Icon, Checkbox } from "../../../components/ui"

import { removeDuplicateObjectsFromArray } from "../../../utils/removeDuplicateObjectsFromArray"

export function SimpleTableNext({
  rows,
  headers,
  checks = false,
  sort_header = null,
  sort_direction = null,
  handle_sort = null,
  handle_select = null,
  toggle_select_all = null,
  fallback,
}) {
  const [rowsPerPage, setRowsPerPage] = useState(50)
  const [startIndex, setStartIndex] = useState(0)
  const [endIndex, setEndIndex] = useState(null)
  const [lastPageReached, setLastPageReached] = useState(false)
  const [all_selected, set_all_selected] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    if (rows && rows.length > 0 && !rowsPerPage) {
      setRowsPerPage(50)
      setStartIndex(0)
      setEndIndex(rowsPerPage)
    }
  }, [rows])

  const navigate = (direction) => {
    if (direction === "next") {
      setStartIndex(startIndex + rowsPerPage)
      if (endIndex + rowsPerPage > rows.length) {
        setLastPageReached(true)
        setEndIndex(endIndex + rowsPerPage)
      } else {
        setEndIndex(endIndex + rowsPerPage)
      }
    }
    if (startIndex >= 0 && direction === "previous") {
      if (lastPageReached) {
        setLastPageReached(false)
      }
      setStartIndex(startIndex - rowsPerPage)
      setEndIndex(endIndex - rowsPerPage)
    }
  }

  useEffect(() => {
    setEndIndexHelper()
  }, [rowsPerPage])

  const setRowsPerPageHelper = (rowCount) => {
    setLastPageReached(false)
    setRowsPerPage(rowCount)
    setStartIndex(0)
    setEndIndex(rowCount)
  }

  const setEndIndexHelper = () => {
    setEndIndex(rowsPerPage)
  }

  function sort_class(header) {
    if (header !== sort_header) {
      return "Sort-none"
    }
    return sort_direction === "asc" ? "Sort-asc" : "Sort-desc"
  }

  useEffect(() => {
    if (rows) {
      const max = rows.slice(startIndex, startIndex + rowsPerPage).length

      set_all_selected(
        rows
          .slice(startIndex, startIndex + rowsPerPage)
          .filter((row) => row.checked).length >= max
      )
    }
  }, [rows, startIndex])

  return (
    <div className="Simple-table-container">
      <div className={`Simple-table-upper-section rows-${rowsPerPage}`}>
        <table className="Simple-table">
          <tbody>
            <tr className="Simple-table-headers">
              {checks && (
                <td
                  className="simple-next-check"
                  onClick={() => toggle_select_all(rowsPerPage, startIndex)}
                >
                  <Checkbox checked={all_selected} />
                </td>
              )}
              {headers.map((header, key) => {
                return (
                  <td key={key} className="Simple-table-headers-data ">
                    {header}
                    {
                      <div
                        onClick={() => handle_sort(header)}
                        style={{ display: "inline-block" }}
                        key={key * 99.5}
                      >
                        <Icon
                          iconClass={`Sort ${sort_class(header)}`}
                          size={"0.88rem"}
                        />
                      </div>
                    }
                  </td>
                )
              })}
            </tr>
            {rows &&
              rows.slice(startIndex, endIndex).map((row, key) => {
                return (
                  <tr
                    key={key}
                    className={`Simple-table-row clickable ${
                      row.checked ? "selected" : ""
                    }`}
                  >
                    {checks && (
                      <td
                        className="simple-next-check"
                        onClick={(e) => {
                          e.stopPropagation()
                          handle_select(row["UID"])
                        }}
                      >
                        <Checkbox checked={row.checked ? true : false} />
                      </td>
                    )}
                    {headers.map((header, idx) => {
                      return (
                        <td
                          className="Simple-table-data"
                          onClick={() => history.push(row["link"])}
                          key={idx}
                        >
                          {row[header]}
                        </td>
                      )
                    })}
                    {row["edit"] && (
                      <td className="Simple-table-data edit link">
                        <div className="Simple-table-row-action-menu">
                          <div
                            className="action Edit"
                            onClick={(e) => {
                              e.stopPropagation()
                              dispatch({
                                type: "FROM_PATH",
                                value: window.location.pathname,
                              })
                              history.push(row["edit"])
                            }}
                          >
                            <Icon iconClass={"Edit-admin"} size={"1.1rem"} />
                          </div>
                        </div>
                      </td>
                    )}
                  </tr>
                )
              })}
          </tbody>
        </table>
        {rows && rows.slice(startIndex, endIndex).length === 0 && (
          <div className="Simple-table-row">
            <div className="Simple-table-data no-data">
              {fallback ? fallback : "There is no data available."}{" "}
            </div>
          </div>
        )}
      </div>
      {rows && rows.length >= 1 && Object.keys(rows[0])[0] !== " " && (
        <div className="Simple-table-toolbar">
          <p>Rows Per Page:</p>
          <div>
            <DropDownSelect
              options={
                rows.length <= 200
                  ? [
                      { value: 50, display: "50" },
                      { value: rows.length, display: "Show All" },
                    ]
                  : rows.length <= 1000
                  ? [
                      { value: 50, display: "50" },
                      { value: 200, display: "200" },
                      { value: rows.length, display: "Show All" },
                    ]
                  : rows.length <= 2000
                  ? [
                      { value: 100, display: "50" },
                      { value: 200, display: "200" },
                      { value: 500, display: "500" },
                      { value: rows.length, display: "Show All" },
                    ]
                  : rows.length > 2000 && [
                      { value: 50, display: "50" },
                      { value: 200, display: "200" },
                      { value: 500, display: "500" },
                      { value: 1000, display: "1000" },
                      { value: 2000, display: "2000" },
                      { value: rows.length, display: "Show All" },
                    ]
              }
              val={rowsPerPage}
              onChange={(e) => setRowsPerPageHelper(parseInt(e.target.value))}
              nullOption={false}
              disabled={rows.length <= 50}
            />
          </div>
          <p className="Display-range">
            {startIndex + 1} -{" "}
            {rows?.length < rowsPerPage || lastPageReached
              ? rows.length
              : endIndex}{" "}
            of {rows?.length}
          </p>
          <div className="Navigation">
            <div
              className={`Navigation-button button ${
                startIndex <= 0 ? "disabled" : ""
              }`}
              onClick={() => navigate("previous")}
            >
              <p>Previous</p>
            </div>
            <div
              className={`Navigation-button button ${
                rows && endIndex >= rows.length ? "disabled" : ""
              }`}
              onClick={() => navigate("next")}
            >
              <p>Next</p>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
