import React, { useEffect, useReducer, useState } from "react"
import { useParams } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { WizardBar } from "../../../components/ui"
import {
  Form,
  Input,
  Loading,
  DropDownSelect,
  Icon,
} from "../../../components/ui"

import { setCurrentLocation, saveJob } from "../../../redux/actions/admin"

import { setMode } from "../../utils"

import { useBreadCrumbsContext } from "../../../hooks"

const reducer = (state, action) => {
  switch (action.type) {
    case "MODE":
      return {
        ...state,
        mode: action.value,
      }
    case "POPULATE":
      return {
        ...state,
        job: {
          ...state.job,
          [action.key]: action.value,
        },
      }
    default:
      break
  }
  return state
}

const initialState = {
  mode: "",
}

export default function JobDetailPage() {
  const { companyUID, selectedLocation, companyMap, jobs } = useSelector(
    (state) => ({
      employees: state.admin?.locationData.employees,
      companyUID: state.admin.locationData.location?.company_uid,
      jobs: state.admin.locationData.jobs,
      selectedLocation: state.admin.selectedLocation,
      companyMap: state.admin.companyMap,
    })
  )
  const params = useParams()
  const dispatch = useDispatch()
  const [state, localDispatch] = useReducer(reducer, initialState)
  const [render, setRender] = useState(false)
  const [job, setJob] = useState()
  const passBreadCrumbs = useBreadCrumbsContext()

  useEffect(() => {
    setCurrentLocation(params.locationid, dispatch)
    setMode(localDispatch)
  }, [])

  useEffect(() => {
    if (selectedLocation) {
      setJob(jobs[params.jobid])
    }
  }, [selectedLocation])

  useEffect(() => {
    if (job) {populateJobState()}
  }, [job])

  useEffect(() => {
    if (state.mode === "Add") {
      setJob({ active: null })
      setRender(true)
    }
  }, [state.mode])

  const populateJobState = () => {
    for (let [key, value] of Object.entries(job)) {
      localDispatch({ type: "POPULATE", key: key, value: value })
    }
    setRender(true)
  }

  const handleInput = (e, field) => {
    const value =
      field === "active"
        ? e.target.value.toLowerCase() === "true"
          ? true
          : false
        : e.target.value
    localDispatch({ type: "POPULATE", key: field, value: value })
  }

  const form = () => {
    return (
      <div className="admin wrapper">
        <main className="container">
          {passBreadCrumbs([
            { content: <Icon iconClass={"Skyline"} />, route: "/companies" },
            {
              content: companyMap && companyMap[companyUID],
              route: `/companies/${companyUID}/`,
            },
            {
              content: selectedLocation && selectedLocation,
              route: `/locations/${selectedLocation}/`,
            },
            {
              content:
                state.mode !== "View" ? state.mode + " Group" : state.job?.name,
              route: "",
            },
          ])}
          <div className="main-content detail-page">
            <h1 className="main-heading">
              {`${state.mode} Group `}
              {state.mode === "Edit" && `- ${state.job?.name}`}
            </h1>
            <Form>
              {state.mode !== "Add" && (
                <Input
                  mode={state.mode}
                  icons
                  disabled
                  label="Group ID"
                  value={state.job?.uid}
                />
              )}
              <Input
                mode={state.mode}
                label="Group"
                value={state.job?.name}
                onChange={(e) => handleInput(e, "name")}
              />
            </Form>
            <WizardBar
              backToRoute={`/locations/${selectedLocation}/#jobs`}
              category={"Groups"}
              mode={state.mode}
              setMode={(mode) => localDispatch({ type: "MODE", value: mode })}
              apiCall={saveJob}
              apiArgs={{ job: state.job, mode: state.mode }}
              redirectRoute={`/locations/${selectedLocation}/#jobs`}
              disabled={
                state.job?.name == "None"
                  ? true
                  : state.mode === "Add" || state.mode === "Edit"
                    ? state.job?.name
                      ? state.job?.name.length < 1
                        ? true
                        : state.job?.name.toLowerCase() === "none"
                          ? true
                          : false
                      : true
                    : false

                
              }
              header={"Confirm"}
              promptMessage={
                state.mode === "Add"
                  ? "Do you want to add this new group?"
                  : "Do you want to save your changes?"
              }
            />
          </div>
        </main>
      </div>
    )
  }

  return <>{render ? form() : <Loading background={"none"} />}</>
}
