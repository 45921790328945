import React, { Component } from "react"

export class Form extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className={"form-group " + (this.props.styleClass || "")}>
        {this.props.children}
      </div>
    )
  }
}
