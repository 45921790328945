import React, { useEffect, useState } from "react"
import ReactDOM from "react-dom"
import styled from "styled-components"

export const DropdownContainerClass = styled.div`
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.21);
  left: ${(props) => props.coords.x}px;
  top: ${(props) => props.coords.y}px;
  margin: ${(props) => props.margin};
  position: absolute;
  min-width: 12rem;
  max-height: 25rem;
  overflow: auto;
  z-index: 5000;
`

export const DropdownContainer = styled.div`
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.21);
  left: ${(props) => props.coords.left}px;
  top: ${(props) => props.coords.top}px;
  margin: ${(props) => props.margin};
  position: absolute;
  min-width: 12rem;
  max-height: 25rem;
  overflow: auto;
  z-index: 5000;
  background: white;
  visibility: ${(props) => (props.active ? "visible" : "hidden")};
`

DropdownContainer.defaultProps = {
  coords: {
    left: 0,
    top: 0,
  },
}

export const DropdownOption = styled.div`
  font-size: 0.95rem;
  color: #32343e;
  line-height: 1.9rem;
  transition: opacity 200ms cubic-bezier(0.65, 0, 0.35, 1);
  cursor: pointer;
  white-space: nowrap;
  padding: 0.5rem 1rem;
  background: white;

  &:hover {
    opacity: 0.5;
  }
`

export const DropdownClass = React.forwardRef((props, ref) => {
  return ReactDOM.createPortal(
    <DropdownContainerClass {...props} ref={ref}>
      {props.children}
    </DropdownContainerClass>,
    document.body
  )
})

export const Dropdown = React.forwardRef((props, ref) => {
  return ReactDOM.createPortal(
    <DropdownContainer {...props} ref={ref}>
      {props.children}
    </DropdownContainer>,
    document.body
  )
})

export function DropdownHook(target) {
  let coords = { x: 0, y: 0 }
  if (target) {
    const rect = target.getBoundingClientRect()
    coords = { x: rect.left, y: rect.top + rect.height + window.scrollY + 10 }
  }

  let visible = false

  return {
    props: {
      coords,
    },
  }
}

export function useDropdown(target, self) {
  const [active, set_active] = useState(false)
  const [coords, set_coords] = useState({ top: 0, left: 0 })

  useEffect(() => {
    handle_resize()
    window.addEventListener("resize", handle_resize)
    return () => window.removeEventListener("resize", handle_resize)
  }, [])

  useEffect(() => {
    function check_click(e) {
      if (self && !self.contains(e.target)) {
        set_active(false)
      }
    }

    window.addEventListener("mousedown", check_click)
    return () => window.removeEventListener("mousedown", check_click)
  })

  return {
    props: {
      active,
      coords,
    },
    show: () => {
      set_active(true)
    },
    hide: () => {
      set_active(false)
    },
  }

  function handle_resize() {
    if (target) {
      const rect = target.getBoundingClientRect()

      const left = rect.left
      const top = rect.top + rect.height + window.scrollY + 2

      if (coords.left !== left || coords.top !== top) {
        set_coords({ top, left })
      }
    }
  }
}
