import React, { useEffect, useState } from "react"
import { useSelector, useDispatch, useStore } from "react-redux"

import { DropDownSelect } from "../../../../../components/ui"

function LocationDropDown({ setLocation, location }) {
  const { locations } = useSelector((state) => ({
    locations: state.admin.locationsList,
  }))
  const [options, setOptions] = useState()
  const [val, setVal] = useState("")

  useEffect(() => {
    let tempArr = []

    locations.map((i) => {
      let tempObj = { display: i.name, value: i.uid }
      tempArr.push(tempObj)
    })
    setOptions(tempArr)
  }, [])

  const handleInput = (e) => {
    setLocation(e.target.value)
  }

  return (
    <>
      <h3 className="Toggle-menu-header">Select Location</h3>
      <DropDownSelect
        mode={"Edit"}
        options={options}
        label="Move To"
        val={location}
        onChange={(e) => handleInput(e)}
      />
    </>
  )
}

export default LocationDropDown
