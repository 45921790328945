// import { API } from '../../utils/api'

// import { put, call } from 'redux-saga/effects'

export const GET_GOALS_ATTEMPT = "goals/GET_GOALS_ATTEMPT"
export const GET_GOALS_SUCCEEDED = "goals/GET_GOALS_SUCCEEDED"
export const GET_GOALS_FAILED = "goals/GET_GOALS_FAILED"

const initialState = {
  goals: {},
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_GOALS_SUCCEEDED:
      return {
        goals: {
          ...state.goals,
          [`${action.person}`]: action.goals,
        },
      }
    default:
      return state
  }
}

export function getGoals(person) {
  return {
    type: GET_GOALS_ATTEMPT,
    payload: {
      person,
    },
  }
}

// Enable this if you want to load goal data on the dashboard.
// Warning this creates a lot of load on the backend since there is no bulk way to load goals.

// function* goalsWorker(action) {
//   try {
//     const response = yield call(API.getGoals, action.payload.person);
//     yield put({type: GET_GOALS_SUCCEEDED, goals: response.data, person: action.payload.person});
//    } catch (e) {
//       yield put({type: GET_GOALS_FAILED, message: (e.message || 'invalid request')});
//    }
// }

export function* goalsSaga() {
  //   yield takeEvery(GET_GOALS_ATTEMPT, goalsWorker);
}
