import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"

import { Toggle } from "../../../../components/ui"
import ThemePreview from "./theme-preview"

import { themes } from "./theme-preview/themes"
import { setTheme } from "./setTheme"

import { setDarkMode } from "../../../../redux/actions/admin"

function ThemePanel() {
  const { darkMode } = useSelector((state) => ({
    darkMode: state.admin.darkMode,
  }))
  const [active, setActive] = useState()
  const [babyYoda, setBabyYoda] = useState()
  const [hiddenFeatureUnlocked, setHiddenFeatureUnlocked] = useState(false)
  const code = [""]
  const dispatch = useDispatch()

  useEffect(() => {
    setActive(localStorage.getItem("currentTheme"))
    if (localStorage.getItem("baby-yoda")) {
      if (localStorage.getItem("baby-yoda") === "true") {
        setHiddenFeatureUnlocked(true)
        setBabyYoda(true)
      } else if (localStorage.getItem("baby-yoda") === "false") {
        setBabyYoda(false)
      }
    }
  }, [])

  const toggleDarkMode = () => {
    if (!darkMode) {
      document.querySelector("html").classList.add("dark")
      localStorage.setItem("darkMode", "true")
      setDarkMode(true, dispatch)
    } else {
      document.querySelector("html").classList.remove("dark")
      localStorage.setItem("darkMode", "false")
      setDarkMode(false, dispatch)
    }
    setTheme()
  }

  // const checkForMatch = () => {
  //   const _code = code.join("").toString();
  //   if (_code.includes("383840403739373966656665")){
  //     setHiddenFeatureUnlocked(true)
  //     document.removeEventListener("keydown", keylogCheck)
  //   }
  // }

  // const keylogCheck = e => {
  //   code.push(e.keyCode)
  //   setTimeout( () => {checkForMatch()}, 0)
  // }

  // useEffect(() => {
  //   document.addEventListener("keydown", keylogCheck)
  //   return () => {
  //     document.removeEventListener("keydown", keylogCheck)
  //   };
  // }, []);

  const toggleBabyYoda = () => {
    if (!babyYoda) {
      document.querySelector("*").classList.add("baby-yoda")
      localStorage.setItem("baby-yoda", "true")
      setBabyYoda(true)
    } else {
      document.querySelector("*").classList.remove("baby-yoda")
      localStorage.setItem("baby-yoda", "false")
      setBabyYoda(false)
    }
  }

  const changeNavTheme = (e, b, c, d, k, s) => {
    document.documentElement.style.setProperty("--color-primary", b)
    document.documentElement.style.setProperty("--color-secondary", s)
    if (localStorage.getItem("darkMode")) {
      if (localStorage.getItem("darkMode") === "false") {
        document.documentElement.style.setProperty("--color-primary-text", c)
      } else {
        document.documentElement.style.setProperty("--color-primary-text", d)
      }
    }

    localStorage.setItem("currentTheme", k)
    setActive(k)
  }

  const themeRows = () => {
    const j = themes.length
    const chunk = 2
    let subset
    let rowArray = []

    for (let i = 0; i < j; i += chunk) {
      subset = themes.slice(i, i + chunk)
      let themeItems = subset.map((theme, key) => {
        return (
          <ThemePreview
            theme={theme}
            key={key}
            changeNavTheme={changeNavTheme}
            active={active}
          />
        )
      })
      let row = (
        <div className="Theme-row" key={i + 1}>
          {themeItems}
        </div>
      )
      rowArray.push(row)
    }
    return rowArray
  }

  return (
    <div className="Settings-row">
      <div className="Settings-panel">
        <h3 className="Settings-panel-header">Theme</h3>
        <Toggle
          label={"Dark Mode"}
          value={darkMode}
          setValue={toggleDarkMode}
        />
        <Toggle
          label={"Custom Cursor"}
          value={babyYoda}
          setValue={toggleBabyYoda}
        />
        {/* {
          hiddenFeatureUnlocked && <Toggle
                              label={"Custom Cursor"}
                              value={babyYoda}
                              setValue={toggleBabyYoda}
                            />
        } */}
        <h3 className="Settings-panel-header">Accent Color</h3>
        <div className="Theme-preview-container">
          <div className="Theme-preview-preview">
            <p>Theme Preview</p>
            <div className="Theme-preview">
              <div className="Theme-preview-nav"></div>
              <div className="Theme-preview-div">
                <div className="Theme-bullet"></div>
                <div className="Theme-text"></div>
              </div>
            </div>
          </div>
          {themeRows()}
        </div>
      </div>
    </div>
  )
}

export default ThemePanel
