import React, { useEffect, useState } from "react"
import ReactDOM from "react-dom"
import { Route, BrowserRouter, Switch, Redirect } from "react-router-dom"
import { createBrowserHistory } from "history"
import Cookies from "js-cookie"
import "./styles/shared/index.scss"

import { PrivateRoute } from "./private-route"

import Login from "./components/wfm-2.0/login"
import ForgotPassword from "./components/wfm-2.0/forgot-password"
import ResetPassword from "./components/wfm-2.0/reset-password"

import Activate from "./pages/activate-account"
import { Provider } from "react-redux"
import configureStore from "./redux"

import { Auth0Provider, useAuth0 } from "@auth0/auth0-react"

// These pages are for admin dashboard
import GlobalSearchPage from "./pages/admin/global-search-page"
import CompanyListingPage from "./pages/admin/company-listing-page"
import CompanyDetailPage from "./pages/admin/company-detail-page"
import LocationListingPage from "./pages/admin/location-listing-page"
import LocationDetailPage from "./pages/admin/location-detail-page"
import EmployeeListingPage from "./pages/admin/employee-listing-page"
import EmployeeDetailPage from "./pages/admin/employee-detail-page"
import InventoryCompanyListingPage from "./pages/admin/inventory-company-listing-page"
import InventoryLocationListingPage from "./pages/admin/inventory-location-listing-page"
import DeviceDetailPage from "./pages/admin/device-detail-page"
import JobDetailPage from "./pages/admin/job-detail-page"
import AdminSettingsPage from "./pages/admin/settings-page"
import ManagerDetailPage from "./pages/admin/manager-detail-page"
import CSVUploadPage from "./pages/admin/csv-upload-page"
import ReportingPage from "./pages/admin/reporting-page"
import InventoryDeviceListingPage from "./pages/admin/inventory-device-listing-page"
import InventoryDeviceDetailPage from "./pages/admin/inventory-device-detail-page"
import UtilitiesPage from "./pages/admin/utilities-page"
import SettingsPackageDetailPage from "./pages/admin/settings-package-detail-page"

import { syncHistoryWithStore } from "react-router-redux"

import $ from "jquery"

import "react-select/dist/react-select.css"

import * as Sentry from "@sentry/browser"

import Layout from "./layout/Layout"

import { initialRedirectTo, setLoggedIn } from "./redux/actions/user"

// Initialize Sentry if the SENTRY_DSN config var is set.

if (window.SENTRY_DSN) {
  Sentry.init({ dsn: window.SENTRY_DSN })
}

const store = configureStore()
window.testValue = "TRUE"
window.store = store
window.$ = $
window.Cookies = Cookies

// polyfill for stupid IE
if (!("remove" in Element.prototype)) {
  Element.prototype.remove = function () {
    if (this.parentNode) {
      this.parentNode.removeChild(this)
    }
  }
}

export const history = syncHistoryWithStore(createBrowserHistory(), store)

if (window.KINETIC_DASHBOARD_AUTH && !window.KINETIC_DASHBOARD_AUTH.message) {
  // This page load is the completion of a SAML auth flow, so redirect
  // accordingly.

  const { email, relayState, ...data } = window.KINETIC_DASHBOARD_AUTH

  setLoggedIn(email, data, store.dispatch)
  initialRedirectTo(relayState)
}

let routes = (
  <>
    <Switch>
      <Redirect from="/select-location" to="/" />
      <PrivateRoute path="/" exact component={GlobalSearchPage} />
      <Route path="/forgot-password" exact component={ForgotPassword} />
      <Route path="/login" exact component={Login} />
      <Route path="/activate" component={Activate} />
      <Route path="/reset-password" exact component={ResetPassword} />
      <PrivateRoute path="/email-reports" exact component={ReportingPage} />
      <PrivateRoute path="/settings" exact component={AdminSettingsPage} />

      <PrivateRoute path="/utilities" exact component={UtilitiesPage} />
      <PrivateRoute
        path="/utilities/settings-packages/add"
        exact
        component={SettingsPackageDetailPage}
      />
      <PrivateRoute
        path="/utilities/settings-packages/:settingspackageuid"
        exact
        component={SettingsPackageDetailPage}
      />
      <PrivateRoute
        path="/utilities/settings-packages/:settingspackageuid/edit"
        exact
        component={SettingsPackageDetailPage}
      />

      <PrivateRoute
        path="/inventory"
        exact
        component={InventoryCompanyListingPage}
      />
      <PrivateRoute
        path="/inventory/:companyid"
        exact
        component={InventoryLocationListingPage}
      />
      <PrivateRoute
        path="/inventory/devices/:deviceuid"
        exact
        component={InventoryDeviceDetailPage}
      />
      <PrivateRoute
        path="/inventory/location/:locationid"
        exact
        component={InventoryDeviceListingPage}
      />
      <PrivateRoute
        path="/inventory/location/:locationid/devices/:deviceid"
        exact
        component={DeviceDetailPage}
      />
      <PrivateRoute
        path="/inventory/location/:locationid/devices/:deviceid/edit"
        exact
        component={DeviceDetailPage}
      />

      <PrivateRoute path="/companies" exact component={CompanyListingPage} />
      <PrivateRoute path="/companies/add" exact component={CompanyDetailPage} />
      <PrivateRoute
        path="/companies/:companyid"
        exact
        component={LocationListingPage}
      />
      <PrivateRoute
        path="/companies/:companyid/edit"
        exact
        component={CompanyDetailPage}
      />
      <PrivateRoute
        path="/companies/:companyid/devices/csv"
        exact
        component={CSVUploadPage}
      />
      <PrivateRoute
        path="/companies/:companyid/managers/add"
        exact
        component={ManagerDetailPage}
      />
      <PrivateRoute
        path="/companies/:companyid/managers/csv"
        exact
        component={CSVUploadPage}
      />
      <PrivateRoute
        path="/companies/:companyid/managers/:managerid"
        exact
        component={ManagerDetailPage}
      />
      <PrivateRoute
        path="/companies/:companyid/managers/:managerid/edit"
        exact
        component={ManagerDetailPage}
      />

      <PrivateRoute
        path="/locations/add"
        exact
        component={LocationDetailPage}
      />
      <PrivateRoute path="/locations/csv" exact component={CSVUploadPage} />
      <PrivateRoute
        path="/locations/:locationid"
        exact
        component={EmployeeListingPage}
      />
      <PrivateRoute
        path="/locations/:locationid/edit"
        exact
        component={LocationDetailPage}
      />
      <PrivateRoute
        path="/locations/:locationid/employees/add"
        exact
        component={EmployeeDetailPage}
      />
      <PrivateRoute
        path="/locations/:locationid/employees/csv"
        exact
        component={CSVUploadPage}
      />
      <PrivateRoute
        path="/locations/:locationid/employees/:employeeid"
        exact
        component={EmployeeDetailPage}
      />
      <PrivateRoute
        path="/locations/:locationid/employees/:employeeid/edit"
        exact
        component={EmployeeDetailPage}
      />
      <PrivateRoute
        path="/locations/:locationid/devices/:deviceid"
        exact
        component={DeviceDetailPage}
      />
      <PrivateRoute
        path="/locations/:locationid/devices/:deviceid/edit"
        exact
        component={DeviceDetailPage}
      />
      <PrivateRoute
        path="/locations/:locationid/jobs/add"
        exact
        component={JobDetailPage}
      />
      <PrivateRoute
        path="/locations/:locationid/jobs/:jobid"
        exact
        component={JobDetailPage}
      />
      <PrivateRoute
        path="/locations/:locationid/jobs/:jobid/edit"
        exact
        component={JobDetailPage}
      />
      <PrivateRoute
        path="/locations/:locationid/managers/:managerid"
        exact
        component={ManagerDetailPage}
      />
      <PrivateRoute
        path="/locations/:locationid/managers/:managerid/edit"
        exact
        component={ManagerDetailPage}
      />
      <Route component={Login} />
    </Switch>
  </>
)

function App() {
  return (
    <Auth0Provider
      domain={"login.wearkinetic.com"}
      clientId={"s3gPXDejvVnRc6e5rTCAWJvFxTYfM6cy"}
      redirectUri={window.location.origin}
      audience="https://backend2.wearkinetic.com"
    >
      <Provider store={store}>
        <BrowserRouter history={history}>
          <AuthWrapper>
            <Layout>{routes}</Layout>
          </AuthWrapper>
        </BrowserRouter>
      </Provider>
    </Auth0Provider>
  )
}

function AuthWrapper(props) {
  const auth = useAuth0()

  useEffect(() => {
    if (auth.isLoading) return null
    if (!auth.isAuthenticated) return auth.loginWithRedirect()

    async function fetchToken() {
      const token = await auth.getAccessTokenSilently({
        audience: "https://backend2.wearkinetic.com",
      })

      localStorage.setItem("adminToken", token)
    }

    fetchToken()
  }, [auth])

  if (auth.isLoading) return null
  return <>{props.children}</>
}

ReactDOM.render(<App />, document.getElementById("root"))

window.$ = $

// add jQuery function
window.$.extend(window.$.fn, {
  // Name of our method & one argument (the parent selector)
  within: function (pSelector) {
    // Returns a subset of items using jQuery.filter
    return this.filter(function () {
      // Return truthy/falsey based on presence in parent
      return window.$(this).closest(pSelector).length
    })
  },
})
