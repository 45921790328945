import { MetricEmployee } from "./metric"

const PCT = 10
const MIN_EMP = 3

class Rank {
  constructor(metricsIterable) {
    this.metricEmployees = []

    if (metricsIterable) {
      this.rank(metricsIterable)
    }
  }

  /* How to rank a metrics map, in ascending order (best employee = less risky = lower metric) */
  rank(metricsIterable) {
    this.metricEmployees = Array.from(metricsIterable)
      .filter((metric) => metric.activeTimeSeconds > 0)
      .sort((a, b) => a.metric() - b.metric())
  }

  getRiskiest() {
    if (this._overwriteValueRiskiest) {
      const nMetric = new MetricEmployee()
      nMetric._overwriteValue = this._overwriteValueRiskiest
      return nMetric
    }

    if (this.metricEmployees.length === 0) {
      return new MetricEmployee()
    }

    return this.metricEmployees[this.metricEmployees.length - 1]
  }

  getSafest() {
    if (this.metricEmployees.length === 0) {
      return new MetricEmployee()
    }

    return this.metricEmployees[0]
  }

  /* This computes the number of items corresponding to a given percentile
  For instance the top 25 percentile of the ordered list [1, 2, 3, 4, 5, 6, 7, 8, 9, 10] are the first 4 items
  so this function will return 4 in this case
  */
  _percentileToLength(pct) {
    const pctAsLength = Math.ceil((this.metricEmployees.length * pct) / 100)

    // If pctAsLength < MIN_EMP
    if (pctAsLength >= MIN_EMP) {
      return pctAsLength
    }

    // We don't want an overlap in the top and worst employees
    const minEmp = Math.min(Math.floor(this.metricEmployees.length), MIN_EMP)

    return Math.max(pctAsLength, minEmp)
  }

  // _aggregateMetricEmployees returns a sum of a bunch of employees
  static aggregateMetricEmployees(metricEmployees) {
    const aggregateEmployee = new MetricEmployee("aggregateTopRiskier")
    metricEmployees.forEach((metricEmployee) => {
      aggregateEmployee.addLifts(metricEmployee.nLifts)
    })
    metricEmployees.forEach((metricEmployee) => {
      aggregateEmployee.addActiveTime(metricEmployee.activeTimeSeconds)
    })
    return aggregateEmployee
  }

  getTopSafer(pct = PCT) {
    if (this.metricEmployees.length === 0) {
      return []
    }

    const l = this._percentileToLength(pct)
    return this.metricEmployees.slice(0, l)
  }

  aggregateTopRiskier(pct = PCT) {
    return Rank.aggregateMetricEmployees(this.getTopRiskier(pct))
  }

  getTopRiskier(pct = PCT) {
    if (this.metricEmployees.length === 0) {
      return []
    }

    const l = this._percentileToLength(pct)
    return this.metricEmployees.slice(this.metricEmployees.length - l).reverse()
  }

  aggregateTopSafer(pct = PCT) {
    return Rank.aggregateMetricEmployees(this.getTopSafer(pct))
  }
}

export { Rank }
