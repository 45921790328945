export default function replaceUIDs(
  originalObj,
  refObj,
  originalKey,
  refKey,
  replacementKey
) {
  const objCopy = JSON.parse(JSON.stringify(originalObj))
  const refArr = Object.values(refObj)
  let orginalObjCopyArr = Object.values(objCopy)

  orginalObjCopyArr.reduce((acc, cval) => {
    for (let i = 0; i < refArr.length; i++) {
      if (cval[originalKey] === refArr[i][refKey]) {
        cval[originalKey] = refArr[i][replacementKey]
        acc.push(cval)
      }
    }
    return acc
  }, [])
  return orginalObjCopyArr
}
