import React, { useState, useEffect, useRef } from "react"
import { useSelector, useStore, useDispatch } from "react-redux"
import { Helmet } from "react-helmet"
import axios from "axios"
import Cookies from "js-cookie"

import {
  DropDownSelect,
  SimpleTable,
  Icon,
  Loading,
} from "../../../components/ui"

import { globalSearchCategories } from "../../../constants/globalSearchCategories"
import { fakeTableData } from "./fake-table-data"
import { ignoreKeys } from "./ignore-keys"

import {
  navbarGlobalSearch,
  setCurrentLocation,
} from "../../../redux/actions/admin"
import { API } from "../../../utils/api"

export default function GlobalSearchPage() {
  const { globalSearchConfig } = useSelector((state) => ({
    globalSearchConfig: state.admin.globalSearchConfig,
  }))
  const [tableData, setTableData] = useState()
  const [searchConfig, setSearchConfig] = useState({
    searchArgs: null,
    category: "device",
  })
  const [instructions, setInstructions] = useState(
    "Search by Device UID or SKU"
  )
  const [searchResults, setSearchResults] = useState()
  const debounce = useRef(null)
  const _searchBah = useRef()
  const [fetching, setFetching] = useState()
  const store = useStore()
  const [getSearchConfig, setGetSearchConfig] = useState()
  const dispatch = useDispatch()

  const tableDataHelper = () => {
    if (searchConfig?.category && searchResults && searchResults.length > 0) {
      setTableData(shapeTableData(searchResults))
    } else if (searchResults && searchResults.length === 0) {
      setTableData([{ noData: "No results." }])
      setSearchResults()
    }
  }

  const shapeTableData = (data) => {
    switch (searchConfig.category) {
      case "device":
        return data.map((device) => {
          const rowLink = {
            clickableRow: `/locations/${device["device"].location_uid}/devices/${device["device"].uid}`,
          }
          return {
            SKU: device["device"].sku,
            UID: device["device"].uid,
            company: device["device"].company,
            location: device["device"].location,
            Employee: device["device"].name,
            version: device["device"].version,
            ...rowLink,
          }
        })
      case "employee":
        return data.map((employee) => {
          const rowLink = {
            clickableRow: `/locations/${employee["employee"].location_uid}/employees/${employee["employee"].uid}`,
          }
          return {
            name: employee["employee"].name,
            company: employee["employee"].company_name,
            location: employee["employee"].location_name,
            group: employee["employee"].job_name,
            SKU: employee["employee"].sku,
            ...rowLink,
          }
        })
      case "location":
        return data.map((location) => {
          const rowLink = {
            clickableRow: `/locations/${location["location"].uid}`,
          }
          return { ...location["location"], ...rowLink }
        })
      case "manager":
        let managersIDs = []
        let filteredManagers = []

        data.map((manager) => {
          if (!managersIDs.includes(manager["manager"].uid)) {
            managersIDs.push(manager["manager"].uid)
          }
        })

        managersIDs.map((id) => {
          const man = {
            uid: id,
            name: "",
            email: "",
            company: "",
            company_uid: "",
            locations: [],
          }
          data.map((manager) => {
            const m = manager["manager"]
            if (man.uid == m.uid) {
              man.name = m.name
              man.email = m.email
              man.company = m.company
              man.company_uid = m.company_uid
              man.locations.push(m.location)
            }
          })
          filteredManagers.push(man)
        })

        return filteredManagers.map((manager) => {
          const rowLink = {
            clickableRow: `/companies/${manager.company_uid}/managers/${manager.uid}`,
          }

          const l = manager.locations.join().replace(/,/g, ", ")

          return {
            name: manager.name,
            email: manager.email,
            company: manager.company,
            locations: l,
            ...rowLink,
          }
        })
      default:
        break
    }
  }

  useEffect(() => {
    if (searchResults && searchConfig?.category) {
      tableDataHelper()
    }
  }, [searchResults])

  const handleChangesToStore = () => {
    if (!getSearchConfig && store.getState().admin.globalSearchConfig) {
      setGetSearchConfig(true)
    }
  }

  useEffect(() => {
    if (getSearchConfig) {
      if (store.getState().admin.globalSearchConfig) {
        setSearchConfigFromRedux()
      }
      setTimeout(() => {
        setGetSearchConfig()
      }, 150)
    }
  }, [getSearchConfig])

  const setSearchConfigFromRedux = () => {
    if (globalSearchConfig) {
      if (!globalSearchConfig.searchArgs) {
        if (Cookies.get("searchArgs")) {
          setSearchConfig({
            searchArgs: Cookies.get("searchArgs"),
            category: Cookies.get("searchCat"),
          })
          if (_searchBah.current) {
            _searchBah.current.value = Cookies.get("searchArgs")
          }
        }
      } else {
        setSearchConfig({
          ...globalSearchConfig,
        })
        setTimeout(() => {
          if (_searchBah.current) {
            _searchBah.current.value = globalSearchConfig.searchArgs
          }
        }, 250)
      }
    } else if (Cookies.get("searchArgs")) {
      setSearchConfig({
        searchArgs: Cookies.get("searchArgs"),
        category: Cookies.get("searchCat"),
      })
      if (_searchBah.current) {
        _searchBah.current.value = Cookies.get("searchArgs")
      }
    }
  }

  useEffect(() => {
    setSearchConfigFromRedux()

    return () => {
      navbarGlobalSearch(
        {
          searchArgs: null,
          category: "device",
        },
        dispatch
      )
    }
  }, [])

  useEffect(() => {
    store.subscribe(handleChangesToStore)
    const unsubscribe = store.subscribe(handleChangesToStore)

    return unsubscribe()
  }, [])

  useEffect(() => {
    if (searchConfig?.searchArgs && searchConfig?.searchArgs.length < 3) {
      setTableData()
    } else if (!searchConfig.searchArgs) {
      setTableData()
    } else if (
      searchConfig?.searchArgs &&
      searchConfig?.searchArgs.length >= 3
    ) {
      getResults(searchConfig.searchArgs)
    }
  }, [searchConfig])

  useEffect(() => {
    const inst = globalSearchCategories.filter((item) => {
      if (searchConfig.category == item.value) {
        return item
      }
    })
    setInstructions(inst)
  }, [searchConfig])

  const resetData = (category) => {
    setTableData()
    _searchBah.current.value = ""
    setSearchConfig({ searchArgs: "", category: category })
    Cookies.remove("searchCat")
    Cookies.remove("searchArgs")
  }

  const cleanUpFakeHeaders = (stringToSplit) => {
    const unformattedHeader = stringToSplit.split(/(?=[A-Z_])/)
    const header = unformattedHeader.map((word, index, unformattedHeader) => {
      word = word.charAt(0).toUpperCase() + word.slice(1)
      if (index !== unformattedHeader.length - 1) {
        if (
          /^[A-Z]/.test(unformattedHeader[index + 1]) &&
          unformattedHeader[index].length === 1 &&
          unformattedHeader[index + 1].length === 1
        ) {
          return word
        }
        return word + " "
      } else {
        return word
      }
    })
    return header
  }

  function searchBah({ placeholder }) {
    return placeholder ? (
      <div className="searchbar">
        {searchConfig?.searchArgs && searchConfig?.searchArgs.length > 0 ? (
          <Icon
            iconClass="Circled-plus"
            size={"1.1rem"}
            onClick={() => {
              resetData(searchConfig.category)
            }}
          />
        ) : (
          <Icon iconClass="Search" size={"1.1rem"} />
        )}
        <input
          ref={_searchBah}
          type="text"
          placeholder={`${placeholder}s...`}
          onChange={(e) => {
            const searchArgs = e.target.value
            setSearchConfig({
              searchArgs: searchArgs,
              category: searchConfig?.category,
            })
            if (
              searchConfig &&
              searchConfig.category &&
              searchArgs &&
              searchArgs.length >= 3
            ) {
              setFetching(true)
            }
            if (debounce.current) {
              clearTimeout(debounce.current)
            }
            debounce.current = setTimeout(() => {
              if (
                searchConfig &&
                searchConfig.category &&
                searchArgs &&
                searchArgs.length >= 3
              ) {
                getResults(searchArgs)
              }
            }, 1000)
          }}
        />
      </div>
    ) : (
      <Loading background={"none"} positionRelative={true} height={"20px"} />
    )
  }

  async function getResults(searchArgs) {
    await axios
      .get(
        `${API.newBackendUrl}/v1/admin/search?category=${searchConfig.category}&query=${searchArgs}`,
        {
          headers: { Authorization: API.getAuthHeader() },
        }
      )
      .then((data) => {
        Cookies.set("searchCat", searchConfig.category)
        Cookies.set("searchArgs", searchArgs)
        setSearchResults(data.data)
      })
      .finally(() => {
        setFetching(false)
      })
  }

  const directions = () => {
    return (
      <div className="Global-search-directions">
        <p>
          Use the dropdown above to search for either{" "}
          {globalSearchCategories.map((i, key) => {
            return (
              <span key={key * 2.534}>
                {`${key === globalSearchCategories.length - 1 ? " or" : ""}`}
                <span key={key * 2} className="category">{` ${i.value.replace(
                  "-",
                  " "
                )}s`}</span>
                {`${key !== globalSearchCategories.length - 1 ? "," : ""}`}
              </span>
            )
          })}
          .{" "}
        </p>
        <p>The search requires 3 characters minimum</p>
      </div>
    )
  }

  const loadingAnimation = () => {
    return (
      <Loading height={"450px"} positionRelative={true} background={"none"} />
    )
  }

  return (
    <div className="admin wrapper Global-search">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Find Something • Kinetic</title>
      </Helmet>
      <main className="admin container">
        <div className="main-content">
          <h1 className="main-heading">Find Something</h1>
          <div className="Global-search-search-category-section">
            <div className="Global-search-search-category-section-left">
              <h4 className="field-label">What are you looking for?</h4>
              {searchBah({
                placeholder: `Search ${
                  searchConfig ? searchConfig?.category : ""
                }`,
              })}
              <div className="search-sub-text">
                {instructions[0].instruction}
              </div>
            </div>
            <div className="Global-search-search-category-section-right">
              <h4 className="field-label">Search within:</h4>
              <DropDownSelect
                options={globalSearchCategories}
                val={searchConfig?.category}
                onChange={(e) => {
                  setSearchConfig({
                    searchArgs: searchConfig?.searchArgs,
                    category: e.target.value,
                  })
                }}
                nullOption={false}
                className={"round"}
              />
            </div>
          </div>
          {tableData && !fetching && (
            <SimpleTable
              ignoreKeys={ignoreKeys[searchConfig.category]}
              rows={tableData}
            />
          )}
          {searchConfig?.searchArgs &&
            searchConfig?.searchArgs.length > 2 &&
            fetching &&
            loadingAnimation()}
          <div>
            {!tableData && !searchConfig?.searchArgs
              ? directions()
              : searchConfig?.searchArgs && searchConfig?.searchArgs.length < 3
              ? directions()
              : !searchConfig?.searchArgs && directions()}
          </div>
        </div>
      </main>
    </div>
  )
}
