import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import { API } from "../../../../src/utils/api"

import {
  SimpleTable,
  SearchBar,
  Loading,
  Icon,
  SimpleTableNext,
} from "../../../components/ui"
import { Helmet } from "react-helmet"

import {
  getCompanyList,
  clearCurrentCompany,
} from "../../../redux/actions/admin"

export default function CompanyPageListing() {
  const [filteredRows, setFilteredRows] = useState([])
  const [rows, setRows] = useState([])
  const dispatch = useDispatch()
  const { user, companies } = useSelector((state) => ({
    user: state.user,
    companies: state.admin.companyList,
  }))
  const [render, setRender] = useState(true)
  const [sort_header, set_sort_header] = useState()
  const [sort_direction, set_sort_direction] = useState()

  useEffect(() => {
    if (user.loggedIn) {
      clearCurrentCompany(dispatch)
      const runEffect = async () => {
        await getCompanyList(dispatch)
        setRows()
      }
      runEffect()
    }
  }, [])

  useEffect(() => {
    if (companies) {
      setRows(Object.values(cleanData(companies)))
    }
    if (!companies) {
      setRows([])
    }
  }, [companies])

  useEffect(() => {
    if (filteredRows[0]?.noData || rows.length < 0) {
      setRender(false)
    } else {
      setRender(true)
    }
  }, [filteredRows, rows])

  function handle_sort_direction(header) {
    if (header == sort_header) {
      return sort_direction === "asc" ? "desc" : "asc"
    }
    return "asc"
  }

  function handle_sort(header) {
    const sort_dir = handle_sort_direction(header)

    set_sort_header(header)
    set_sort_direction(sort_dir)

    setRows(sortRows(rows, header, sort_dir))
    setFilteredRows(sortRows(filteredRows, header, sort_dir))
  }

  function sortRows(data, header, sort_dir) {
    return data.sort((a, b) => {
      const left = a[header].toLowerCase()
      const right = b[header].toLowerCase()

      if (left < right) {
        if (sort_dir === "asc") {
          return -1
        }
        if (sort_dir === "desc") {
          return 1
        }
      } else {
        if (sort_dir === "asc") {
          return 1
        }
        if (sort_dir === "desc") {
          return -1
        }
      }
      return 0
    })
  }

  const onFilterData = (results) => {
    setFilteredRows(results)
  }

  const clearFilterData = () => {
    setFilteredRows([])
  }

  const cleanData = () => {
    if (companies) {
      console.log(companies)
      return companies.map((company) => {
        return {
          Name: company.name,
          // "Company Email Reporting": company.reporting_ergo_status
          //   ? "Active"
          //   : "",
          // "Social Distancing Reports": company.reporting_ct_status
          //   ? "Active"
          //   : "",
          company: company.name,
          link: `/companies/${company.uid}`,
        }
      })
    }
  }
  return (
    <div className="admin wrapper Company-listing">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Companies • Kinetic</title>
      </Helmet>
      <main className="admin container">
        <div className="main-content">
          <h1 className="main-heading">Client Manager</h1>
          <h1 className="sub-heading">Companies</h1>
          <div className="search-and-action-parent">
            <SearchBar
              placeholder="Search Companies"
              handleSearch={onFilterData}
              data={rows}
              clearFilterData={clearFilterData}
            />
            <div className="Action-menu-parent">
              <div className="Action-menu">
                <Link to={"/companies/add"} className="add-button button">
                  <Icon iconClass={"Circled-plus"} />
                  ADD COMPANY
                </Link>
              </div>
            </div>
          </div>
          <section className="table-container">
            {render ? (
              <SimpleTableNext
                rows={filteredRows.length > 0 ? filteredRows : rows}
                headers={[
                  "Name",
                  //"Company Email Reporting",
                  //"Social Distancing Reports",
                ]}
                handle_sort={(header) => handle_sort(header)}
              />
            ) : (
              <Loading
                background={"none"}
                positionRelative={true}
                height={"500px"}
              />
            )}
          </section>
        </div>
      </main>
    </div>
  )
}
