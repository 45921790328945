import React, { useEffect, useState, useReducer } from "react"
import { useParams, useLocation, useHistory } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { format } from "timeago.js"

import {
  Form,
  Input,
  Loading,
  Icon,
  DropDownSelect,
  SimpleTable,
  DialogueBox,
  WizardBar,
} from "../../../components/ui"
import { CommandsDetailModal } from "./pending-commands-detail-modal"
import { PendingCommandsModal } from "../../../components/pending-commands-modal"

import {
  setCurrentLocation,
  batchUpdateWus,
} from "../../../redux/actions/admin"
import { setMode } from "../../utils"
import { API } from "../../../utils/api"

import { deviceStatusOptions } from "../../../constants/deviceStatusOptions"

import { useBreadCrumbsContext } from "../../../hooks"

const reducer = (state, action) => {
  switch (action.type) {
    case "MODE":
      return {
        ...state,
        mode: action.value,
      }
    case "POPULATE":
      return {
        ...state,
        device: {
          ...state.device,
          [action.key]: action.value,
        },
      }
    default:
      break
  }
  return state
}

const initialState = {
  mode: "",
}

export default function DeviceDetailPage(props) {
  const {
    employees,
    companyUID,
    companyMap,
    devices,
    selectedLocation,
  } = useSelector((state) => ({
    employees: state.admin?.locationData.employees,
    companyUID: state.admin.locationData.location?.company_uid,
    devices: state.admin.locationData.devices,
    selectedLocation: state.admin.selectedLocation,
    companyMap: state.admin.companyMap,
  }))
  const [state, localDispatch] = useReducer(reducer, initialState)
  const [render, setRender] = useState(false)
  const params = useParams()
  const dispatch = useDispatch()
  const [device, setDevice] = useState(null)
  const location = useLocation()
  const root = location.pathname.split("/")[1]
  const [inventory, setInventory] = useState(false)
  const [pendingCommands, setPendingCommands] = useState()
  const [pendingCommand, setPendingCommand] = useState()
  const [pendingCommandModal, setPendingCommandModal] = useState()
  const [executedCommands, setExecutedCommands] = useState()
  const [executedCommand, setExecutedCommand] = useState()
  const [executedCommandModal, setExecutedCommandModal] = useState()
  const [dialogueConfig, setDialogueConfig] = useState()
  const [dialogueResponse, setDialogueResponse] = useState()
  const [sendCommandModalToggle, setSendCommandModalToggle] = useState(false)
  const passBreadCrumbs = useBreadCrumbsContext()

  useEffect(() => {
    const runEffect = async () => {
      if (!selectedLocation || selectedLocation !== params.locationid) {
        await setCurrentLocation(params.locationid, dispatch)
      }
    }
    runEffect()
    root.includes("inventory") && setInventory(true)
    getPendingCommands()
    getExecutedCommands()
  }, [])

  useEffect(() => {
    if (devices && employees && params.deviceid) {
      const matchedDevice = devices.filter((o) => {
        if (o.uid === params.deviceid) {
          return o
        }
      })
      if (matchedDevice[0]?.worker_uid) {
        matchedDevice[0].employee = employees[matchedDevice[0].worker_uid]?.name
      }
      setDevice(matchedDevice[0])
    }
  }, [selectedLocation])

  useEffect(() => {
    if (device) {
      populateDevice(device)
      setMode(localDispatch, setRender)
    }
  }, [device])

  async function getPendingCommands() {
    const res = await API.getPendingCommands(params.deviceid)

    if (res) {
      const _res = res.map((p) => {
        return { ...p, clickableRow: "_delete-only" }
      })
      setPendingCommands(_res)
    }
  }

  async function getExecutedCommands() {
    const res = await API.getExecutedCommands(params.deviceid)

    if (res) {
      setExecutedCommands(res)
    }
  }

  async function deletePendingCommand() {
    const res = await API.deletePendingCommand(
      device.uid,
      pendingCommand.groupedCommandUID
    ).then(() => {
      const _tempTableData = pendingCommands.filter((o) => {
        return o.groupedCommandUID !== pendingCommand.groupedCommandUID
      })

      setTimeout(() => {
        setPendingCommands(_tempTableData)
        setPendingCommandModal(false)
        setPendingCommand()
        setDialogueResponse()
      }, 50)
    })
  }

  useEffect(() => {
    if (dialogueResponse && dialogueConfig.action.toLowerCase() === "delete") {
      deletePendingCommand()
    }
  }, [dialogueResponse])

  const populateDevice = (device) => {
    for (let [key, value] of Object.entries(device)) {
      localDispatch({ type: "POPULATE", key: key, value: value })
    }
  }

  const handleInput = (e, field) => {
    localDispatch({ type: "POPULATE", key: field, value: e.target.value })
  }

  const openCommandsModal = (row, type) => {
    if (type === "pending_command") {
      setPendingCommandModal(true)
      setPendingCommand(row)
    } else {
      setExecutedCommandModal(true)
      setExecutedCommand(row)
    }
  }

  const deletePendingCommandHelper = (row) => {
    setDialogueConfig({
      header: "Delete this Pending Command?",
      promptMessage: `Pending command "${row.body}"`,
      deleteFlag: true,
      action: "Delete",
    })
    setPendingCommand(row)
  }

  useEffect(() => {
    if (!sendCommandModalToggle) {
      getPendingCommands()
    }
  }, [sendCommandModalToggle])

  const form = () => {
    return (
      <div className="admin wrapper">
        <main className="container">
          {passBreadCrumbs([
            {
              content: <Icon iconClass={inventory ? "Device" : "Skyline"} />,
              route: inventory ? "/inventory" : "/companies",
            },
            {
              content: companyMap && companyMap[companyUID],
              route: `/${
                inventory
                  ? `inventory/${companyUID}/`
                  : `companies/${companyUID}/`
              }`,
            },
            {
              content: selectedLocation && selectedLocation,
              route: `/${
                inventory
                  ? `inventory/location/${selectedLocation}`
                  : `locations/${selectedLocation}`
              }`,
            },
            {
              content:
                state.mode !== "View"
                  ? state.mode + " Device"
                  : state?.device.serial_number,
              route: "",
            },
          ])}
          <div className="main-content detail-page">
            <h1 className="main-heading">
              {`${state.mode} Device `}
              {state.mode === "Edit" && `- ${state.device.screen_label}`}
            </h1>
            <Form>
              <Input
                mode={state.mode}
                icons
                disabled
                label="UID"
                value={state?.device.uid}
              />
              <Input
                mode={state.mode}
                icons
                disabled
                label="Current Device Version"
                value={state?.device.version}
              />
              <Input
                mode={state.mode}
                icons
                disabled
                label="Serial Number"
                value={state?.device.serial_number}
              />
              <Input
                mode={state.mode}
                label="Screen Label"
                value={state?.device.screen_label}
                onChange={function (e) {
                  handleInput(e, "screen_label")
                }}
              />
              <Input
                mode={state.mode}
                disabled
                icons
                label="Last IP"
                value={state?.device.last_ip}
              />
              <Input
                mode={state.mode}
                disabled
                icons
                label="Employee"
                value={state?.device?.employee}
              />
              <DropDownSelect
                mode={state.mode}
                label="Status"
                options={deviceStatusOptions}
                val={state?.device?.status}
                name="Status"
                nullOption={true}
                onChange={(e) => handleInput(e, "status")}
              />
              {/* <Input
                mode={state.mode}
                disabled
                icons
                label="Pending Commands"
                value={state?.device?.PendingCommands}
              /> */}
              <Input
                mode={state.mode}
                disabled
                icons
                label="Last WU Push Time"
                value={format(state.device?.last_push_wu_time)}
              />
              <Input
                mode={state.mode}
                disabled
                icons
                label="Last Server Push Time"
                value={format(state?.device?.last_push_server_time)}
              />
              <Input
                mode={state.mode}
                disabled
                icons
                label="Linux Version"
                value={state?.device?.linux_version}
              />
            </Form>
            <WizardBar
              mode={state.mode}
              backToRoute={`/${
                inventory
                  ? `inventory/location/${selectedLocation}/#devices`
                  : `locations/${selectedLocation}/#devices`
              }`}
              category={"Devices"}
              setMode={(mode) => {
                localDispatch({ type: "MODE", value: mode })
                populateDevice(device)
              }}
              apiCall={batchUpdateWus}
              apiArgs={state.device}
              redirectRoute={`/locations/${selectedLocation}/#devices`}
              header={"Confirm"}
              promptMessage={"Do you want to save your changes?"}
            />
          </div>
        </main>
        <div className="Pending-commands main-content detail-page">
          <h1 className="main-heading">Pending Commands</h1>
          <h2 className="Detail-page-sub-header">{`${
            pendingCommands ? pendingCommands.length : 0
          } Pending Command(s)`}</h2>
          <div className="Pending-commands-actions">
            <div
              className={"button round"}
              onClick={() => setSendCommandModalToggle(true)}
            >
              <Icon iconClass={"Circled-plus"} />
              Send Pending Commands
            </div>
          </div>
          <SimpleTable
            rows={
              pendingCommands && pendingCommands.length > 0
                ? pendingCommands
                : [{ noData: "No pending commands." }]
            }
            clickableCallbackFunction={(row) =>
              openCommandsModal(row, "pending_command")
            }
            deleteCallback={(row) => {
              deletePendingCommandHelper(row)
            }}
          />
          {pendingCommand && pendingCommandModal && (
            <CommandsDetailModal
              command={pendingCommand}
              close={() => {
                setPendingCommand(null)
                setPendingCommandModal(false)
              }}
              deletePendingCommandHelper={(pC) =>
                deletePendingCommandHelper(pC)
              }
              type={"pending"}
            />
          )}
        </div>
        <div className="Executed-commands main-content detail-page">
          <h1 className="main-heading">Executed Commands</h1>
          <h2 className="Detail-page-sub-header">{`${
            executedCommands ? executedCommands.length : 0
          } Executed Command(s)`}</h2>
          <SimpleTable
            rows={
              executedCommands && executedCommands.length > 0
                ? executedCommands
                : [{ noData: "No executed commands." }]
            }
            clickableCallbackFunction={(row) => openCommandsModal(row)}
          />
          {executedCommand && executedCommandModal && (
            <CommandsDetailModal
              command={executedCommand}
              close={() => {
                setExecutedCommand(null)
                setExecutedCommandModal(false)
              }}
              type={"executed"}
            />
          )}
        </div>
        {dialogueConfig && (
          <DialogueBox
            setDialogue={setDialogueConfig}
            setDialogueResponse={(res) => {
              setDialogueResponse(res)
              if (!res) {
                setPendingCommand(null)
                setDialogueConfig(null)
              }
            }}
            header={dialogueConfig.header}
            promptMessage={dialogueConfig.promptMessage}
            deleteFlag={dialogueConfig.deleteFlag}
            action={dialogueConfig.action}
          />
        )}
        {sendCommandModalToggle && (
          <PendingCommandsModal
            placeholder="Type commands here..."
            action="Send Commands"
            header={"Send Pending Commands"}
            closeModal={setSendCommandModalToggle}
            deviceUID={device.uid}
          />
        )}
      </div>
    )
  }

  return (
    <>
      {render ? (
        form()
      ) : (
        <Loading
          background={"var(--color-background)"}
          positionRelative={true}
          height={"81vh"}
        />
      )}
    </>
  )
}
