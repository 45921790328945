import { combineReducers } from "redux"

import filters from "./filters"
import devices from "./devices"
import delta from "./delta"
import keen from "./keen"
import appState from "./app-state"
import goals from "./goals"
import user from "./user"
import { routerReducer } from "react-router-redux"
import admin from "./admin"

export default combineReducers({
  filters,
  devices,
  delta,
  admin,
  keen,
  appState,
  goals,
  user,
  routing: routerReducer,
})
