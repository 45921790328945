import React from "react"
import moment from "moment"

import { Icon } from "../../../../../components/ui"

export default function DropZone({
  stagedFile,
  isDragAccept,
  getRootProps,
  file,
  getInputProps,
  templateDownloadLink,
  status,
  handleRemoveFile,
}) {
  const { title, subtitle, border } = translate_status(status, stagedFile)

  return (
    <div className="Csv-upload-container mb-6">
      <div
        className={`Csv-upload-drop-zone text-center ${border} border-4 ${status} ${
          isDragAccept ? "Drag-Accepted" : ""
        }`}
        {...(!stagedFile && { ...getRootProps() })}
      >
        <input {...getInputProps()} />
        {status === "accepted" && stagedFile && (
          <div>
            <div className="py-2 px-6 text-lg text-white bg-gray-600 inline-block rounded">
              <span>{stagedFile.name}</span>
              <div className="pl-5" onClick={() => handleRemoveFile()}>
                <Icon iconClass="X-mark" />
              </div>
            </div>
          </div>
        )}
        {
          <div className="text-center">
            <div className={"text-2xl mb-6 text-black"}>{title}</div>
            <div className="font-bold">{subtitle}</div>
          </div>
        }
      </div>
    </div>
  )
}

function translate_status(status, stagedFile) {
  const settings = { title: "", subtitle: "", border: "" }

  if (status === "max") {
    return setSettings(
      "File size must be less than 2MB",
      "Drag and drop another file or click to browse",
      "border-red-400 border-dashed"
    )
  }
  if (status === "accepted" && stagedFile) {
    const converted = moment(stagedFile.lastModified)
    const modified =
      converted.format("MMM D, YYYY") + " at " + converted.format("h:mma")
    return setSettings(
      "",
      `File last modified ${modified}`,
      "border-gray-400 border-solid"
    )
  }
  return setSettings(
    "Drag & Drop your file or click to browse",
    "2MB file size limit",
    "border-dashed border-gray-400"
  )
}

function setSettings(title, subtitle, border) {
  return { title: title, subtitle: subtitle, border: border }
}
