import React, { useState, useEffect, useCallback } from "react"
import { Link } from "react-router-dom"
import { useDropzone } from "react-dropzone"
import Moment from "react-moment"
import Papa from "papaparse"

import { API } from "../../../../utils/api"

import { Icon, DialogueBox, Loading } from "../../../../components/ui"
import { useToastContext } from "../../../../hooks"

import DropZone from "./drop-zone"
import { set } from "js-cookie"

function AngelScriptDropZone() {
  const [stagedFile, setStagedFile] = useState(null)
  const [status, setStatus] = useState("idle")
  const addToastMessage = useToastContext()
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState("")
  const [errorState, setErrorState] = useState(false)
  const [dialogue, setDialogue] = useState(false)
  const [dialogueResponse, setDialogueResponse] = useState(false)
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    inputRef,
    isDragAccept,
  } = useDropzone({
    multiple: false,
    maxSize: 2097152,
  })

  useEffect(() => {
    const accepted = acceptedFiles[0]
    if (accepted) {
      if (accepted.size > 2000000) {
        setStatus("max")
        handleRemoveFile()
      } else {
        setStatus("accepted")
        setStagedFile(acceptedFiles[0])
      }
    }
  }, [acceptedFiles])

  const handleRemoveFile = useCallback(
    (fileName, click) => {
      const dt = new DataTransfer()
      const files = Array.from(inputRef.current.files)

      for (let file of files) {
        file.name !== fileName && dt.items.add(file)
      }

      inputRef.current.files = dt.files
      setStagedFile(null)
    },
    [inputRef]
  )

  const file = acceptedFiles.map((file, key) => (
    <>
      <div className="File-name" key={key + 1}>
        <p>{file.name}</p>
        <p className="Last-edited" key={key + 2}>
          {"Last Edited - "}
          <Moment format="MMM D YYYY HH:mm">
            {file?.lastModifiedDate?.toString()}
          </Moment>
        </p>
      </div>
      <div onClick={handleRemoveFile} key={key + 3}>
        <Icon iconClass={"X-mark"} />
      </div>
    </>
  ))

  // useEffect(() => {
  //   if (stagedFile) {
  //     const reader = new FileReader(stagedFile)
  //     reader.onload = function (e) {
  //       const parsed = Papa.parse(e.target.result, { header: true })
  //       console.log(parsed)
  //     }
  //     reader.readAsText(stagedFile)
  //   }
  // }, [stagedFile])

  const importDevices = () => {
    const reader = new FileReader(stagedFile)
    reader.onload = function (e) {
      const parsed = Papa.parse(e.target.result, { header: true })
      setMessage("Processing File...")
      setLoading(true)

      setTimeout(() => {
        API.batchUpdateWus(
          parsed.data
            .filter((item) => item.uid)
            .map((item) => ({
              uid: item.uid,
              serial_number: item.rx_number,
              screen_label: item.rx_number,
            }))
        )
          .then(() => {
            setLoading(false)
            addToastMessage({
              message: `${
                parsed?.data && parsed.data.filter((item) => item.uid).length
              } devices were added`,
              status: "success",
              iconClass: "Circled-checkmark",
            })
            setStagedFile(null)
          })
          .catch(() => {
            setMessage("Please check your file and try again.")
            setErrorState(true)
          })
      }, 500)
    }
    reader.readAsText(stagedFile)
  }

  useEffect(() => {
    dialogueResponse && importDevices()
  }, [dialogueResponse])

  return (
    <div className="Angelscript-csv-upload-container">
      <h2 className="Main-header">Add Devices via AngelScript Import</h2>
      <div className="Angelscript-csv-upload-content">
        <div className="left">
          <DropZone
            stagedFile={stagedFile}
            isDragAccept={isDragAccept}
            getRootProps={getRootProps}
            isDragActive={isDragActive}
            getInputProps={getInputProps}
            file={file}
            // templateDownloadLink={templateDownloadLink} //ask for this link
            status={status}
            handleRemoveFile={handleRemoveFile}
          />
          <div
            className={`button ${stagedFile ? "" : "disabled"}`}
            onClick={() => setDialogue(!dialogue)}
          >
            <Icon iconClass="Upload" />
            Import Devices
          </div>
        </div>
        <div className="right">
          <h2>Quick Links</h2>
          <h3>Location:</h3>
          <Link to={"/locations/kineticinventory"}>Kinetic Inventory</Link>
          <h3>Company:</h3>
          <Link to={"/companies/a852ee3b-1dfd-48d9-9cbb-f10a11b8d132"}>
            Kinetic
          </Link>
        </div>
      </div>
      {loading && (
        <Loading
          errorState={errorState}
          headline={errorState && "Couldn’t import devices"}
          message={message}
          setLoading={setLoading}
          backdropFilter={"blur(4px)"}
        />
      )}
      {dialogue && (
        <DialogueBox
          setDialogue={setDialogue}
          setDialogueResponse={setDialogueResponse}
          header={"Add devices"}
          promptMessage={"Import devices?"}
        />
      )}
    </div>
  )
}

export default AngelScriptDropZone
