import React, { useState, useRef } from "react"

import { Icon } from "../"

import { copyToClipboard } from "../../../pages/utils"

/**
 * Input
 *
 * @prop onChange (fn) - a function passed in that is run whenever the textbox changes
 * @prop label (string) - if a label is provided, a label element is included in the render
 * @prop disabled (bool) - if true, the element is disabled
 * @prop type (string) - controls the type of input (`text` or `password`)
 * @prop placeholder (string) - sets the input's placeholder text
 * @prop defaultValue (string) - sets the value of the field
 * @prop autoComplete (bool) - if true, browser auto-complete is allowed
 * @prop icons (bool) - if true, edit/lock icons are displayed
 * @prop required (bool) - if true, the element is required
 */
export function Input({
  label,
  placeholder,
  value,
  onChange,
  autoComplete,
  required,
  reference,
  mode,
  validatation = false,
  readonly,
  textArea,
  style,
  icons,
  disabled,
  type,
  validated,
  children,
  onKeyUp,
  message,
}) {
  const _copyButton = useRef()
  const [copied, setCopied] = useState(false)

  const labelElem = () => {
    return <label>{label}</label>
  }

  const iconElem = () => {
    return (
      <span className={disabled ? "icon locked" : "icon active"}>
        {disabled && <i className="fas fa-lock" />}
        {readonly && <i className="fas fa-lock" />}
        {type === "password" && (
          <i
            className="fas fa-eye"
            onClick={() => {
              return null
            }}
          ></i>
        )}
      </span>
    )
  }

  const handleCopy = () => {
    copyToClipboard(value)
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 6000)
  }

  function isEmpty(value) {
    if (!value && value !== 0) return true
    return false
  }

  return (
    <div
      className={`input-container ${
        validatation ? (validated ? "valid-field" : "invalid-field") : ""
      }`}
    >
      {mode !== "View" && icons && iconElem()}
      {label && labelElem()}
      <div>
        {textArea ? (
          <textarea
            disabled={mode === "View" ? true : disabled}
            required={required}
            type={type}
            placeholder={placeholder}
            autoComplete={autoComplete}
            defaultValue={value}
            onChange={onChange}
            ref={reference}
            style={style}
            className={`${disabled || readonly ? "disabled-readonly" : ""} ${
              (mode === "Edit" && disabled) || readonly ? "not-allowed" : ""
            } ${mode === "View" ? "view" : ""}`}
          />
        ) : (
          <input
            value={value}
            disabled={mode === "View" ? true : disabled}
            required={required}
            type={type}
            placeholder={placeholder}
            autoComplete={autoComplete}
            defaultValue={value}
            onKeyUp={onKeyUp}
            onChange={onChange}
            ref={reference}
            style={style}
            className={`${disabled || readonly ? "disabled-readonly" : ""} ${
              (mode === "Edit" && disabled) || readonly ? "not-allowed" : ""
            } ${mode === "View" ? "view" : ""} ${
              isEmpty(value) ? "empty" : ""
            }`}
          />
        )}
        {mode === "View" &&
          value !== undefined &&
          value !== null &&
          value !== "" && (
            <div
              ref={_copyButton}
              className={`Input-copy-button button ${
                copied ? "Copied" : "Copy"
              }`}
              onClick={() => handleCopy()}
            >
              <p>
                {copied ? (
                  <Icon iconClass="Circled-checkmark" />
                ) : (
                  <Icon iconClass="Copy" />
                )}
              </p>
            </div>
          )}
      </div>
      {mode && mode.toLowerCase() !== "view" && validatation && (
        <p className={"validation-field-label"}>Valid {label} required*</p>
      )}
      {message && <p className={"field-message"}>{message}</p>}
      {children}
    </div>
  )
}
