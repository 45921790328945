import "../styles.css"

import React from "react"

/**
 * A Standard Button
 *
 * @prop size - applies a size class to the button (`large` & `xlarge` currently supported)
 * @prop onClick - a function that will be called whenever the button is clicked (unless state is disabled)
 */
export class Button extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      warning: false,
    }
  }

  render() {
    const { children, size, onClick, disabled } = this.props
    const { warning } = this.state

    let stateModifierClass = ""
    let clickEvent = onClick

    // Disabled state should always take precedence over warning state
    if (disabled) {
      stateModifierClass = "disabled"
      clickEvent = () => {}
    } else if (warning) {
      stateModifierClass = "warn"
    }

    return (
      <div
        className={`button ${stateModifierClass} ${size || ""}`}
        onClick={clickEvent}
      >
        {children}
      </div>
    )
  }
}
