import React, { useEffect, useState, useReducer } from "react"
import { useParams } from "react-router-dom"
import { useSelector, useDispatch, useStore } from "react-redux"
import { format } from "timeago.js"

import { WizardBar } from "../../../components/ui"
import { Form, Input, Loading, Icon } from "../../../components/ui"

import {
  setCurrentLocation,
  batchUpdateWus,
} from "../../../redux/actions/admin"
import { setMode } from "../../utils"
import { API } from "../../../utils/api"

import { useBreadCrumbsContext } from "../../../hooks"

const reducer = (state, action) => {
  switch (action.type) {
    case "MODE":
      return {
        ...state,
        mode: action.value,
      }
    case "POPULATE":
      return {
        ...state,
        device: {
          ...state.device,
          [action.key]: action.value,
        },
      }
    default:
      break
  }
  return state
}

const initialState = {
  mode: "",
}

export default function InventoryDeviceDetailPage() {
  const { employees } = useSelector((state) => ({
    employees: state.admin?.locationData.employees,
  }))
  const [state, localDispatch] = useReducer(reducer, initialState)
  const [render, setRender] = useState(false)
  const params = useParams()
  const dispatch = useDispatch()
  const [device, setDevice] = useState(null)
  const store = useStore()
  const [getEmployees, setGetEmployees] = useState()
  const [getCompanyUID, setGetCompanyUID] = useState()
  const passBreadCrumbs = useBreadCrumbsContext()

  useEffect(() => {
    const runEffect = async () => {
      await setCurrentLocation(device.company, dispatch)
    }
    if (device) {
      runEffect()
    }
  }, [device])

  const handleChangesToStore = () => {
    if (!getEmployees && store.getState().admin.locationData?.employees) {
      setGetEmployees(true)
    }
    if (!getCompanyUID && store.getState().admin.locationData?.company?.name) {
      setGetCompanyUID(true)
    }
  }

  useEffect(() => {
    store.subscribe(handleChangesToStore)
    const runEffect = async () => {
      let res = await API.getWu(params.deviceuid)
      setDevice(res[0])
    }
    runEffect()
    const unsubscribe = store.subscribe(handleChangesToStore)

    return unsubscribe()
  }, [])

  useEffect(() => {
    if (getEmployees) {
      let matchedEmployee = Object.values(employees).filter((employee) => {
        if (employee.device === device.uid) {
          return employee
        }
      })
      localDispatch({
        type: "POPULATE",
        key: "employee",
        value: matchedEmployee[0]?.name,
      })
    }
  }, [getEmployees])

  useEffect(() => {
    if (getCompanyUID) {
      localDispatch({
        type: "POPULATE",
        key: "companyUID",
        value: store.getState().admin.locationData?.company?.name,
      })
    }
  }, [getCompanyUID])

  useEffect(() => {
    if (device) {
      populateDevice(device)
      setMode(localDispatch, setRender)
    }
  }, [device])

  const populateDevice = (device) => {
    for (let [key, value] of Object.entries(device)) {
      localDispatch({ type: "POPULATE", key: key, value: value })
    }
  }

  const handleInput = (e, field) => {
    localDispatch({ type: "POPULATE", key: field, value: e.target.value })
  }

  const form = () => {
    return (
      <div className="admin wrapper">
        <main className="container">
          {passBreadCrumbs([
            {
              content: <Icon iconClass="Device" />,
              route: "/inventory",
            },
            {
              content:
                state.mode !== "View"
                  ? state.mode + " Device"
                  : state?.device.sku,
              route: "",
            },
          ])}
          <div className="main-content detail-page">
            <h1 className="main-heading">
              {`${state.mode} Device `}
              {state.mode === "Edit" && `- ${state.device.screenLabel}`}
            </h1>
            <Form>
              <Input
                mode={state.mode}
                icons
                disabled
                label="UID"
                value={state?.device.uid}
              />
              <Input
                mode={state.mode}
                icons
                disabled
                label="Company"
                value={state?.device.companyUID}
              />
              <Input
                mode={state.mode}
                icons
                disabled
                label="Location"
                value={state?.device.company}
              />
              <Input
                mode={state.mode}
                icons
                disabled
                label="Version"
                value={state?.device.version}
              />
              <Input
                mode={state.mode}
                icons
                disabled
                label="SKU"
                value={state?.device.sku}
              />
              <Input
                mode={state.mode}
                label="Screen Label"
                value={state?.device.screenLabel}
                onChange={function (e) {
                  handleInput(e, "screenLabel")
                }}
              />
              <Input
                mode={state.mode}
                disabled
                icons
                label="Last IP"
                value={state?.device.lastIP}
              />
              <Input
                mode={state.mode}
                disabled
                icons
                label="Employee"
                value={state?.device.employee}
              />
              <Input
                mode={state.mode}
                label="Status"
                value={state?.device.status}
              />
              <Input
                mode={state.mode}
                disabled
                icons
                label="Last WU Push Time"
                value={format(state?.device?.lastPushWUTime)}
              />
              <Input
                mode={state.mode}
                disabled
                icons
                label="Last Server Push Time"
                value={format(state?.device?.lastPushServerTime)}
              />
            </Form>
            <WizardBar
              backToRoute={"/inventory#devices"}
              apiCall={batchUpdateWus}
              apiArgs={state.device}
              redirectRoute={`/locations/${state?.device?.company}/#devices`}
              header={"Confirm"}
              promptMessage={"Do you want to save your changes?"}
              category={"Devices"}
            />
          </div>
        </main>
      </div>
    )
  }

  return <>{render ? form() : <Loading background={"none"} />}</>
}
