/* renameColumns takes an array of objects and scans each key
 *  then compares agains the keysAndReplacements object and checks to replace the key
 *  Used most to change the column heading for simpleTable as it uses the keys for the headings
 *
 * @param [{}] originalArr - array of objects
 * @param {} keysAndReplacements - object with key,val pairs {origKey: newKey}
 */
export default function renameColumns(originalArr, keysAndReplacements) {
  const keysToChangeArr = Object.keys(keysAndReplacements)
  const newArr = originalArr.map((i) => {
    let newObject = {}
    for (let [key, value] of Object.entries(i)) {
      if (keysToChangeArr.includes(key)) {
        const newKey = keysAndReplacements[key]
        newObject = { ...newObject, [newKey]: value }
      } else {
        newObject = { ...newObject, [key]: value }
      }
    }
    return newObject
  })

  return newArr
}
