import React, { useState } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import QS from "query-string"

import "./styles.scss"
import { resetPassword } from "../../../redux/actions/user"

const ResetPassword = ({ dispatch }) => {
  const [succeeded, setSucceeded] = useState(false)
  const [pending, setPending] = useState(false)
  const [errorMessage, setErrorMessage] = useState()

  const { email, resetToken, mode } = QS.parse(window.location.search)

  if (succeeded) {
    return (
      <div className="reset-password">
        <form>
          <p className="heading">
            Password {mode !== "activate" ? "reset" : "set"}
          </p>
          <p className="success-msg">
            Your password has been{" "}
            {mode !== "activate"
              ? "reset"
              : "set! Login to begin using your devices"}
            .
          </p>

          <Link to="/login">Log in now</Link>
        </form>
      </div>
    )
  }

  const onSubmit = async (e) => {
    e.preventDefault()

    const form = e.target

    const password = form.querySelector("[name=password]").value
    const confirmPassword = form.querySelector("[name=confirm-password]").value

    if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match.")
      return
    }

    if (password.length < 8) {
      setErrorMessage("Password must be at least 8 characters long.")
      return
    }

    if (
      !/[a-z]/.test(password) ||
      !/[A-Z]/.test(password) ||
      !/[0-9]/.test(password)
    ) {
      setErrorMessage(
        "Password must contain at least one lowercase letter, one uppercase letter, and one digit."
      )
      return
    }

    setPending(true)

    try {
      await resetPassword(email, password, resetToken, dispatch)

      setSucceeded(true)
    } catch (err) {
      setErrorMessage(err.message)
    } finally {
      setPending(false)
    }
  }

  let title = "Password Reset"

  if (mode === "activate") {
    title = "Create Password"
  }

  return (
    <div className="reset-password">
      <form onSubmit={onSubmit}>
        <h4 className="heading">{title}</h4>
        <p className="instructions">
          Enter a password for your Kinetic account.
        </p>

        {errorMessage !== undefined && (
          <p className="error-msg">{errorMessage}</p>
        )}

        <input type="password" name="password" placeholder="Password" />
        <input
          type="password"
          name="confirm-password"
          placeholder="Confirm Password"
        />

        <input type="submit" value="Set my password" disabled={pending} />
      </form>
    </div>
  )
}

export default connect()(ResetPassword)
