import React from "react"
import { Icon } from "../"
import { useHistory } from "react-router-dom"

export function BackButton({ label, backToRoute }) {
  const history = useHistory()

  return (
    <div className="Back-button" onClick={() => history.push(backToRoute)}>
      <Icon iconClass={"Arrow-left"} size={"1.2rem"} />
      <i className="Back-button-label">{label}</i>
    </div>
  )
}
