import Cookies from "js-cookie"

import {
  SET_EMAIL,
  SET_CURRENT_LOCATION,
  LOGGED_IN,
  LOGGED_OUT,
} from "../actions/user"

import { TOKEN } from "../../utils/api"

const STATIC_AUTH = window.KINETIC_DASHBOARD_AUTH

const initialState =
  STATIC_AUTH && !STATIC_AUTH.message
    ? {
        company: STATIC_AUTH.companyID,
        currentLocation: Cookies.get("currentLocation"),
        email: STATIC_AUTH.email,
        locations: STATIC_AUTH.locations,
        loggedIn: true,
      }
    : {
        company: Cookies.get("company") || undefined,
        email:
          Cookies.get("email") || localStorage.getItem("email") || undefined,
        currentLocation: Cookies.get("currentLocation"),
        locations: JSON.parse(localStorage.getItem("locations") || "[]"),
        loggedIn: Boolean(Cookies.get(TOKEN) || localStorage.getItem(TOKEN)),
      }

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case SET_EMAIL:
      return { ...state, email: action.email }

    case SET_CURRENT_LOCATION:
      return { ...state, currentLocation: action.uid }

    case LOGGED_IN:
      state = {
        ...state,
        company: action.data.company,
        locations: action.data.locations || [],
        loggedIn: true,
      }

      return state

    case LOGGED_OUT:
      return { ...state, loggedIn: false }

    default:
      return state
  }
}

/** Gets the WFM version based on the current location. */
export function getWFMVersion({ currentLocation, locations }) {
  const location = locations.find((x) => x.uid === currentLocation)

  if (location && location.featureFlags.indexOf("self-serve") !== -1) {
    return 2
  }

  return 1
}

export function isContactTracing({ currentLocation, locations }) {
  const location = locations.find((x) => x.uid === currentLocation)
  return location && location.featureFlags.indexOf("contact-tracing") !== -1
}

export function getEnabledTableauEmbeds({ currentLocation, locations }) {
  const location = locations.find((x) => x.uid === currentLocation)

  if (location) {
    return location.featureFlags
      .filter((f) => f.startsWith("te-"))
      .map((f) => f.slice(3))
  }
}

export function hideErgo({ currentLocation, locations }) {
  const location = locations.find((x) => x.uid === currentLocation)
  return location && location.featureFlags.indexOf("hide-ergo") !== -1
}

export function isSelfServe({ currentLocation, locations }) {
  const location = locations.find((x) => x.uid === currentLocation)
  return location && location.featureFlags.indexOf("self-serve") !== -1
}

export function showDataPage({ currentLocation, locations }) {
  const location = locations.find((x) => x.uid === currentLocation)
  return (
    location &&
    location.featureFlags.indexOf("self-serve") !== -1 &&
    location.featureFlags.indexOf("data-page") !== -1
  )
}

export function isLocationSelfServe(location) {
  return location.featureFlags.indexOf("self-serve") !== -1
}
