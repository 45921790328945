import React, { useState, useEffect } from "react"

export const Checkbox = ({ checked, indeterminate, onClick, small }) => {
  const [icon, setIcon] = useState("checkbox-empty")

  useEffect(() => {
    if (checked) {
      setIcon("checkbox-filled")
    } else if (indeterminate) {
      setIcon("checkbox-indeterminate")
    } else if (!checked) {
      setIcon("checkbox-empty")
    }
  }, [checked, indeterminate])

  return (
    <div
      className={`ui-checkbox  ${small ? "small" : ""} ${icon}`}
      onClick={onClick}
    >
      <div className={`check-check Icon ${icon}`}></div>
    </div>
  )
}
