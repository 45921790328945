import "./styles.css"
import React from "react"
import { connect } from "react-redux"
import { API } from "../../utils/api"
import { Button, Link } from "../../components/ui"
import { fetchLocationSetup } from "../../redux/reducers/app-state"
import { isContactTracing, hideErgo } from "../../redux/reducers/user"
/*
* SetupTermsPage - Represents the terms and conditions page of the app.
They must be agreed to in order to continue.
*/

//Setup Wizard was removed 07/2020
class SetupTermsPage extends React.Component {
  state = { disagreed: false }

  disagreeToTerms = () => {
    this.setState({ disagreed: true })
  }

  agreeToTerms = async () => {
    API.acceptSelfServiceTerms()
    API.completeProgramSetup()
    let d = new Date()
    let currentDate = d.toISOString()
    let mixpanel = window.mixpanel
    mixpanel.people.set({ ID: this.props.user.email })
    mixpanel.alias(this.props.user.email)
    mixpanel.track("terms", {
      name: "terms",
      timestamp: currentDate,
      email: this.props.user.email,
      location: API.locationUID,
    })
    this.props.fetchLocationSetup()
  }

  render() {
    const { disagreed } = this.state
    let nextUrl = "your-program"
    return (
      <div className="main-contain setup-terms">
        <p>
          By continuing, you agree to the Kinetic{" "}
          <a
            href="https://wearkineticstatic.s3.amazonaws.com/documents/Kinetic_Self+Serve+T%26Cs_June+2020.pdf"
            target="_blank"
          >
            Terms and Conditions
          </a>
          .
          {disagreed && (
            <>
              <br />
              <br />
              <span className="setup-terms-disagreed">
                Without agreeing to our terms and conditions, you will not be
                able to proceed with using Kinetic.
              </span>
            </>
          )}
        </p>

        <div className="button-group">
          <Button size="xlarge" onClick={() => this.disagreeToTerms()}>
            Disagree
          </Button>
          <Link
            href={nextUrl}
            onClick={this.agreeToTerms}
            linkStyle="button xlarge"
          >
            Agree
          </Link>
        </div>
      </div>
    )
  }
}

export default connect(
  (state) => ({
    locationStatus: state.appState.locationSetup,
    user: state.user,
  }),
  {
    fetchLocationSetup: fetchLocationSetup,
  },
  null,
  { forwardRef: true }
)(SetupTermsPage)
