export function setMode(localDispatch, setRender) {
  const url = window.location.href.toLowerCase().split("/")

  if (url[url.length - 1] === "edit") {
    localDispatch({ type: "MODE", value: "Edit" })
    setRender && setRender(true)
  } else if (url[url.length - 1] === "add") {
    localDispatch({ type: "MODE", value: "Add" })
    setRender && setRender(true)
  } else {
    localDispatch({ type: "MODE", value: "View" })
    setRender && setRender(true)
  }
}
