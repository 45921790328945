import React, { useState, useEffect, useReducer } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useParams, useHistory } from "react-router-dom"
import { Helmet } from "react-helmet"

import {
  setCurrentCompany,
  saveCompany,
  updateCompanies,
  getCompanyList,
} from "../../../redux/actions/admin"
import { setMode } from "../../utils"
import validateEmailDomain from "../../utils/validateEmailDomain"
import {
  Form,
  Input,
  Loading,
  Icon,
  ArrayTable,
  WizardBar,
  Checkbox,
} from "../../../components/ui"

import { useBreadCrumbsContext } from "../../../hooks"

const reducer = (state, action) => {
  switch (action.type) {
    case "MODE":
      return {
        ...state,
        mode: action.value,
      }
    case "POPULATE":
      return {
        ...state,
        company: {
          ...state.company,
          [action.key]: action.value,
        },
      }
    case "UPDATE_DOMAIN":
      return {
        ...state,
        [action.key]: action.value,
      }
    case "ADD_DOMAIN":
      return {
        ...state,
        newDomains: action.nArr,
        newDomain: "",
        company: {
          ...state.company,
          allowed_domains: action.curArr,
        },
      }
    case "REMOVE_DOMAIN":
      return {
        ...state,
        newDomains: action.nArr,
        removedDomains: action.remArr,
        company: {
          ...state.company,
          allowed_domains: action.curArr,
        },
      }
    case "UNDO_REMOVE_DOMAIN":
      return {
        ...state,
        removedDomains: action.remArr,
        company: {
          ...state.company,
          allowed_domains: action.curArr,
        },
      }
    default:
      break
  }
  return state
}

const initialState = {
  company: {},
  newDomain: "",
  newDomains: [],
  removedDomains: [],
}

export default function CompanyDetailPage() {
  const params = useParams()
  const dispatch = useDispatch()
  const [state, localDispatch] = useReducer(reducer, initialState)
  const [render, setRender] = useState(false)
  const [validEmailDomain, setValidEmailDomain] = useState()
  const { currentCompany, companies, companyMap } = useSelector((state) => ({
    currentCompany: state.admin.selectedCompany,
    companies: state.admin.companyList,
    companyMap: state.admin.companyMap,
  }))
  const company = companies
    ? companies.filter((c) => c.uid === currentCompany)[0]
    : {}
  const passBreadCrumbs = useBreadCrumbsContext()

  const history = useHistory()

  const [reporting_ergo_status, set_reporting_ergo_status] = useState(false)
  const [reporting_ct_status, set_reporting_ct_status] = useState(false)

  useEffect(() => {
    set_reporting_ergo_status(state.company.reporting_ergo_status)
    set_reporting_ct_status(state.company.reporting_ct_status)
  }, [state.company.reporting_ergo_status, state.company.reporting_ct_status])

  async function setCompany() {
    await setCurrentCompany(params.companyid, dispatch)
  }

  useEffect(() => {
    const runEffect = async () => {
      if (params?.companyid) {
        await setCompany()
        setMode(localDispatch, setRender)
        if (currentCompany !== params.companyid) {
          await setCompany()
        }
      } else {
        setMode(localDispatch)
      }
    }
    runEffect()
    if (state.mode === "Add") {
      delete state.company.name
      localDispatch({
        type: "POPULATE",
        key: "allowed_domains",
        value: ["wearkinetic.com"],
      })
      setRender(true)
      setTimeout(() => {
        document.querySelectorAll(".crumb-history")[1].style.display = "none"
      }, 100)
    }
  }, [state.mode])

  const handleInput = (e, field) => {
    localDispatch({ type: "POPULATE", key: field, value: e.target.value })
  }

  const handleArrayInputChange = (domain) => {
    setValidEmailDomain(validateEmailDomain(domain))
    localDispatch({ type: "UPDATE_DOMAIN", key: "newDomain", value: domain })
  }

  const handleAddedArrayData = () => {
    let curarray =
      state.company.allowed_domains != null ? state.company.allowed_domains : []

    if (
      state.newDomain != "" &&
      state.newDomains.indexOf(state.newDomain.toLowerCase()) == -1 &&
      curarray.indexOf(state.newDomain.toLowerCase()) == -1
    ) {
      let newarray = state.newDomains.concat([state.newDomain])
      curarray = curarray.concat([state.newDomain])
      localDispatch({ type: "ADD_DOMAIN", nArr: newarray, curArr: curarray })
    }
  }

  const handleRemovedArrayData = (domain) => {
    let newarray = state.newDomains.filter((d) => d !== domain)
    let remarray = state.removedDomains
    const origDomains = state.company.allowed_domains.filter(
      (c) => !state.newDomains.includes(c)
    )
    if (origDomains.includes(domain)) {
      remarray = remarray.concat([domain])
    }
    let curarray = state.company.allowed_domains.filter(
      (d) => d !== domain || d.toLowerCase() === "wearkinetic.com"
    )
    localDispatch({
      type: "REMOVE_DOMAIN",
      nArr: newarray,
      curArr: curarray,
      remArr: remarray,
    })
  }

  const handleUndoRemovedArrayData = () => {
    let curarray = state.company.allowed_domains.concat(state.removedDomains)
    let remarray = []
    localDispatch({
      type: "UNDO_REMOVE_DOMAIN",
      curArr: curarray,
      remArr: remarray,
    })
  }

  useEffect(() => {
    company && populateCompanyState()
  }, [company])

  const populateCompanyState = () => {
    for (let [key, value] of Object.entries(company)) {
      localDispatch({ type: "POPULATE", key: key, value: value })
    }
  }

  const form = () => {
    return (
      <div className="admin wrapper" id="company-detail">
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            {state.mode === "Add" ? "Add" : "Edit"} Company • Kinetic
          </title>
          <link rel="canonical" href="http://mysite.com/example" />
        </Helmet>
        <main className="container">
          {passBreadCrumbs([
            { content: <Icon iconClass={"Skyline"} />, route: "/companies" },
            {
              content: companyMap && companyMap[currentCompany],
              route: `/companies/${state.company?.uid}`,
            },
            { content: state.mode + " Company", route: "" },
          ])}
          <div className="main-content detail-page">
            <h1 className="main-heading">{state.mode} Company</h1>
            <Form>
              <div className="flex-container narrow">
                <div className="left">
                  <div className="comp-form-container">
                    <Input
                      name="companyname"
                      label="Company Name"
                      value={state.company?.name ? state.company?.name : ""}
                      onChange={(e) => handleInput(e, "name")}
                    />
                    {state.mode !== "Add" && (
                      <Input
                        readonly
                        icons
                        name="uniqueid"
                        label="Unique ID"
                        value={state.company?.uid}
                      />
                    )}
                  </div>
                  {/* <div className="reporting-container">
                    <div className="checkbox-container">
                      <Checkbox
                        checked={reporting_ergo_status}
                        onClick={() =>
                          set_reporting_ergo_status(!reporting_ergo_status)
                        }
                      />
                      <div>Activate company email reports</div>
                    </div>
                    <div className="text">
                      When enabled, company-wide aggregate reports will be sent
                      to subscribed recipients each Monday at 12pm UTC, as long
                      as at least one location has passed its Start Date.
                    </div> */}
                  {/* <div className="checkbox-container">
                      <Checkbox
                        checked={reporting_ct_status}
                        onClick={() =>
                          set_reporting_ct_status(!reporting_ct_status)
                        }
                      />
                      <div>Social Distancing reports active</div>
                    </div> */}
                  {/* </div> */}
                </div>
                <div className="right">
                  <div className="comp-form-container">
                    <ArrayTable
                      currentData={
                        state.company?.allowed_domains
                          ? state.company?.allowed_domains
                          : []
                      }
                      inputData={state.newDomain}
                      removedData={state.removedDomains}
                      onInputChange={handleArrayInputChange}
                      newData={state.newDomains}
                      dataAdded={handleAddedArrayData}
                      dataRemoved={handleRemovedArrayData}
                      removeUndo={handleUndoRemovedArrayData}
                      disabled={!validEmailDomain}
                    />
                  </div>
                </div>
              </div>
            </Form>
            <WizardBar
              backToRoute={"/companies"}
              mode={state.mode}
              setMode={(mode) => localDispatch({ type: "MODE", value: mode })}
              apiCall={state.mode === "Add" ? saveCompany : updateCompanies}
              apiArgs={{
                name: state?.company?.name,
                uid: state.company?.uid,
                allowed_email_domains: state?.company?.allowed_domains,
                reporting_ergo_status,
                reporting_ct_status,
              }}
              redirectRoute={`/companies/${state.company?.uid}`}
              disabled={
                state.company?.name
                  ? state.company?.name === ""
                    ? true
                    : false
                  : true
              }
              header={"Confirm"}
              promptMessage={
                state.mode === "Add"
                  ? "Do you want to add a new company?"
                  : "Do you want to save your changes?"
              }
              category={"Companies"}
              buttons={() => {
                if (state.mode === "Add") {
                  history.push(`/companies`)
                } else {
                  history.push(`/companies/${state.company.uid}`)
                }
              }}
            />
          </div>
        </main>
      </div>
    )
  }

  return (
    <>
      {render && form()}
      {!render && <Loading background={"none"} />}
    </>
  )
}
