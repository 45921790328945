import React, { Component } from "react"
import ReactDOM from "react-dom"
import { Link } from "../"
import Xmark from "../icons/x-mark.svg"

class CloseButton extends Component {
  render() {
    return (
      <i onClick={this.props.closeModal} className={"close-button"}>
        <img src={Xmark} />
      </i>
    )
  }
}

/**
 * Uses a `_falseRender` method to render a modal-like element on the page.
 * This can be accomplished in the future using React Portals
 * @see https://reactjs.org/docs/portals.html
 *
 * @prop backButton (Component) - a custom back button.
 *       Should be provided by the parent page with the click logic.
 * @prop unmountFunction (func) - a function that should be run when the component unmounts
 * @prop options (Object) - contains params to customize the modal
 *       - solidTitleBar (bool) display a solid-color titlebar
 *       - closeButton (bool) display a close button in the titlebar
 *  */
export class Modal extends Component {
  /**
   * ComponentWillMount creates a Div at the root of the page
   * that we can be used as a render target
   */
  componentWillMount() {
    const div = document.createElement("div")
    div.id = "modal-" + this.props.id
    document.getElementsByTagName("body")[0].appendChild(div)
    this.falseRender = this._falseRender.bind(this)
  }

  /**
   * _falseRender takes the place of the normal render method
   * it's done this way to create the illusion that a modal
   * has loaded over the current page
   */
  _falseRender() {
    const elem = document.getElementById("modal-" + this.props.id)

    const {
      children,
      shown,
      title,
      modalOptions,
      backButton,
      closeButton,
      closeModal,
    } = this.props

    const shownClass = shown ? "shown" : ""
    let solidTitleClass = ""

    let close = <CloseButton closeModal={closeModal} />

    if (modalOptions) {
      solidTitleClass = modalOptions["solidTitleBar"] === true ? "solid" : ""
    }

    if (closeButton) {
      close = closeButton
    }

    const content = (
      <div className={`modal-wrapper ${shownClass}`}>
        <div
          className="ui-modal"
          style={{ display: shown ? "initial" : "none" }}
        >
          <div className={`modal-title-bar ${solidTitleClass}`}>
            {backButton}
            {close}
            {title}
          </div>
          <div className="modal-body">{children}</div>
        </div>
      </div>
    )

    ReactDOM.render(content, elem)
  }

  // Because of the use of _falseRender, we have to manually
  // render this element instead of letting React do it for us
  componentDidMount() {
    if (this.falseRender) {
      this.falseRender()
    }
  }

  componentDidUpdate() {
    if (this.falseRender) {
      this.falseRender()
    }
  }

  // Because of the use of _falseRender, we have to manually
  // clean up the element instead of letting React do it for us
  componentWillUnmount() {
    const { unmountFunction } = this.props
    var elem = document.getElementById("modal-" + this.props.id)
    ReactDOM.unmountComponentAtNode(elem)

    if (unmountFunction) {
      unmountFunction()
    }
    elem.remove()
  }

  // render does nothing
  // @see _falseRender
  render() {
    return null
  }
}
