/* eslint-disable */

import { GET_DATA_ATTEMPT } from "./app-state"

import { put, takeLatest, call } from "redux-saga/effects"

const CREATE_JOB = "jobs/CREATE_JOB"
const DELETE_JOB = "jobs/DELETE_JOB"

import { API } from "../../utils/api"

export function createJob(job) {
  return {
    type: CREATE_JOB,
    payload: {
      job,
    },
  }
}

export function deleteJob(job) {
  return {
    type: DELETE_JOB,
    payload: {
      job,
    },
  }
}

function* createJobWorker(action) {
  try {
    yield call(API.createJob, action.payload.job)
    yield put({ type: GET_DATA_ATTEMPT })
  } catch (e) {}
}

export function* createJobSaga() {
  yield takeLatest(CREATE_JOB, createJobWorker)
}

function* deleteJobWorker(action) {
  try {
    yield call(API.deleteJob, action.payload.job)
    yield put({ type: GET_DATA_ATTEMPT })
  } catch (e) {}
}

export function* deleteJobSaga() {
  yield takeLatest(DELETE_JOB, deleteJobWorker)
}
