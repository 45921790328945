export function validatingEmailDomain(email, domains) {
  const parts = email.split("@")

  if (parts.length > 1 && parts[1].length > 0 && parts[0] != '') {
    const toTest = parts[1]

    const matchingDomains = domains.filter((domain) => {
      return toTest === domain
    })

    if (matchingDomains && matchingDomains.length > 0) {
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}
