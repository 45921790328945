import React, { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"

import { Input, Icon, Loading, DialogueBox } from "../ui"

import { API } from "../../utils/api"

export function PendingCommandsModal({
  closeModal,
  promptMessage,
  width = "550px",
  placeholder,
  action = "Yes",
  header,
  deviceUID = null,
}) {
  const { devices } = useSelector((state) => ({
    devices: state.admin.selectedCheckboxItems,
  }))
  const _pendingCommandsDialogueContainer = useRef()
  const _pendingCommandsDialogueBox = useRef()
  const [commands, setCommands] = useState()
  const [deviceUIDs, setDeviceUIDs] = useState()
  const [sendCommandsFlag, setSendCommandsFlag] = useState()
  const [dilalogueResponse, setDialogueResponse] = useState()
  const [responsePending, setResponsePending] = useState()
  const [loadingConfig, setLoadingConfig] = useState()

  const handleButtonClick = (res) => {
    if (res) {
      setSendCommandsFlag(res)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      _pendingCommandsDialogueContainer.current.classList.add("Active")
    }, 100)
    setTimeout(() => {
      _pendingCommandsDialogueBox.current.classList.add("Active")
    }, 150)
    document.addEventListener("keydown", handleKeydown)
    document.querySelector("html").style.overflow = "hidden"

    return () => {
      document.removeEventListener("keydown", handleKeydown)
      document.querySelector("html").style.overflow = "auto"
    }
  }, [])

  useEffect(() => {
    if (devices) {
      setDeviceUIDs(
        devices.map((o) => {
          return o.uid
        })
      )
    }
  }, [devices])

  function sendCommands() {
    let res = API.createPendingCommands(
      deviceUID ? [deviceUID] : deviceUIDs,
      commands
    )

    setResponsePending(true)
    res
      .then(() => {
        setTimeout(() => {
          setResponsePending(false)
          closeModalHelper()
        }, 1000)
      })
      .catch((e) => {
        console.log(`Saving Error: ${e}`)
        setLoadingConfig({
          message: "There was an error",
          errorState: true,
        })
      })
  }

  const closeModalHelper = () => {
    _pendingCommandsDialogueContainer.current.classList.remove("Active")
    _pendingCommandsDialogueBox.current.classList.remove("Active")
    setTimeout(() => {
      closeModal(false)
    }, 300)
  }

  useEffect(() => {
    dilalogueResponse && sendCommands()
  }, [dilalogueResponse])

  const handleInput = (e) => {
    setCommands(e.target.value)
  }

  const handleKeydown = (e) => {
    if (e.key === "Enter") {
      handleButtonClick(true)
    }
  }

  return (
    <div
      className="Pending-commands-modal Modal-container"
      ref={_pendingCommandsDialogueContainer}
    >
      <div
        className="Modal"
        ref={_pendingCommandsDialogueBox}
        style={{ width: width }}
      >
        <div className={"Close-button"} onClick={() => closeModalHelper()}>
          <Icon iconClass={"X-mark"} size={"1.4rem"} />
        </div>
        {header && <h2 className="Modal-header">{header}</h2>}
        <p className="Modal-sub-header">{promptMessage}</p>
        <Input
          placeholder={placeholder}
          mode={"edit"}
          label="Commands"
          onChange={(e) => handleInput(e)}
        />
        <div className="Button-container">
          <div className="button secondary" onClick={() => closeModalHelper()}>
            Nevermind
          </div>
          <div
            className={`button ${commands ? "" : "disabled"}`}
            onClick={() => handleButtonClick(true)}
          >
            {action}
          </div>
        </div>
      </div>
      {sendCommandsFlag && (
        <DialogueBox
          setDialogue={setSendCommandsFlag}
          setDialogueResponse={setDialogueResponse}
          header={"Confirm Pending Commands"}
          promptMessage={"Are you sure you want to send Pending Commands?"}
        />
      )}
      {responsePending && (
        <Loading
          errorState={loadingConfig?.errorState}
          message={loadingConfig?.message}
          setLoading={setResponsePending}
          backdropFilter={"blur(4px)"}
        />
      )}
    </div>
  )
}
