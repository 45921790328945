import React from "react"

import { Input, Button, Icon } from "../../../components/ui"

export function ArrayTable({
  currentData,
  inputData,
  removedData,
  onInputChange,
  newData,
  dataAdded,
  dataRemoved,
  removeUndo,
  disabled,
}) {
  return (
    <>
      <div className="array-table">
        <div className="array-table-input">
          <Input
            name="whitelistdomains"
            label="Whitelisted Domains"
            value={inputData}
            onChange={(e) => onInputChange(e.target.value.toLowerCase())}
            placeholder={"example.com"}
          />
          <Button onClick={dataAdded} disabled={disabled}>
            Add
          </Button>
        </div>
        <div className="array-table-table">
          <table>
            <tbody>
              {newData.map((d) => {
                return (
                  <tr key={d}>
                    <td className="td-status">
                      <div className="domain-status">
                        <div className="domain-text">unsaved</div>
                      </div>
                    </td>
                    <td>{d}</td>
                    <td className="td-button">
                      <div
                        className="action Delete"
                        onClick={() => dataRemoved(d)}
                      >
                        <Icon iconClass={"Delete-trash"} size={"1.1rem"} />
                      </div>
                    </td>
                  </tr>
                )
              })}

              {currentData
                .filter((c) => !newData.includes(c))
                .map((d) => {
                  return (
                    <tr key={d}>
                      <td className="td-status"></td>
                      <td>{d}</td>
                      <td className="td-button">
                        {d.toLowerCase() != "wearkinetic.com" && (
                          <div
                            className="action Delete"
                            onClick={() => dataRemoved(d)}
                          >
                            <Icon iconClass={"Delete-trash"} size={"1.1rem"} />
                          </div>
                        )}
                      </td>
                    </tr>
                  )
                })}
            </tbody>
          </table>
          {removedData.length > 0 && (
            <p className="doamin-remove-text">
              {removedData.length +
                " existing domain" +
                (removedData.length > 1 ? "s" : "") +
                " removed. "}{" "}
              <button className="undo-remove" onClick={removeUndo}>
                Undo
              </button>
            </p>
          )}
        </div>
      </div>
    </>
  )
}
