import "./styles.scss"
import React, { useState, useRef, useEffect } from "react"

import { Icon } from "../../../components/ui"

export function SearchBar({
  data,
  handleSearch,
  placeholder,
  clearFilterData,
  handleOnChange,
  setValue,
  value,
}) {
  const [fieldClear, setFieldClear] = useState(true)
  const _searchField = useRef()
  const [disabled, setDisabled] = useState(false)

  const handleChange = (event) => {
    if (data.length > 1) {
      let keys = Object.keys(data[0])
      let skipThisIndex = keys.indexOf(" ")
      let resultsArr = [] //will contain new results without duplicates
      let results = data.reduce((acc, cval) => {
        for (let i = 0; i < keys.length; i++) {
          if (cval[keys[i]] && i !== skipThisIndex) {
            if (
              cval[keys[i]]
                .toString()
                .toLowerCase()
                .includes(event.target.value.toLowerCase())
            )
              {acc.push(cval)}
          }
        }
        return acc
      }, [])

      if (_searchField.current.value !== "") {
        setFieldClear(false)
      } else {
        setFieldClear(true)
      }
      resultsArr = [...new Set(results)]
      if (resultsArr.length > 0) {handleSearch(resultsArr)}
      else {handleSearch([{ noData: "No results.（◞‸◟）" }])}
    }
  }

  useEffect(() => {
    if (value) {
      setFieldClear(false)
      _searchField.current.value = value
    }
  }, [value])

  useEffect(() => {
    if (!data || (data && data.length <= 1)) {setDisabled(true)}
    else {setDisabled(false)}
  }, [data])

  const clearSearchField = () => {
    _searchField.current.value = ""
    setFieldClear(true)
    clearFilterData()
    value && setValue(null)
  }

  return (
    <div className={`searchbar ${disabled ? "disabled" : ""}`}>
      {!fieldClear ? (
        <Icon
          iconClass="Circled-plus"
          size={"1.1rem"}
          onClick={clearSearchField}
        />
      ) : (
        <Icon iconClass="Search" size={"1.1rem"} />
      )}
      <input
        ref={_searchField}
        type="text"
        placeholder={placeholder ? `${placeholder}...` : ""}
        onChange={(e) => {
          handleChange(e)
          handleOnChange && handleOnChange(e)
        }}
        disabled={disabled}
      />
    </div>
  )
}
