import React, { useEffect, useState, useRef } from "react"
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { format } from "timeago.js"

import { DropDownSelect, Loading, Icon } from "../../../components/ui"

import { removeDuplicateObjectsFromArray } from "../../../utils/removeDuplicateObjectsFromArray"

export function SimpleTable({
  rows,
  ignoreKeys,
  dontSort,
  checkbox,
  checkboxActionLabel,
  category,
  checkboxKey,
  editCallback,
  deleteCallback,
  checkboxActionConfig,
  clickableCallbackFunction,
  editDisabled,
  order,
  header_props,
}) {
  const { clear_table, selected_employees } = useSelector((state) => ({
    clear_table: state.admin.clear_table,
    selected_employees: state.admin.selected_employees,
  }))

  const [base, setBase] = useState([])
  const [tableData, setTableData] = useState()
  const [sortConfig, setSortConfig] = useState(null)
  const [rowsPerPage, setRowsPerPage] = useState(null)
  const [render, setRender] = useState(false)
  const [startIndex, setStartIndex] = useState(0)
  const [endIndex, setEndIndex] = useState(null)
  const [lastPageReached, setLastPageReached] = useState(false)
  const [selectedArr, setSelectedArr] = useState([])
  const [selectedIds, setSelectedIds] = useState([])
  const [rowsCopy, setRowsCopy] = useState()
  const history = useHistory()
  const dispatch = useDispatch()
  const [renderedCheckIds, setRenderedCheckIds] = useState([])
  const [translate, set_translate] = useState({})

  const dontSortHeaders = [
    '" "',
    "selected",
    "clickableRow",
    "previewLink",
    "noData",
  ]

  useEffect(() => {
    const translation = {}

    if (rows) {
      const headers = Object.keys(rows[0]).filter((header) =>
        ignoreKeys ? ignoreKeys.indexOf(header) < 0 : true
      )

      for (let header of headers) {
        const cleansed = cleanUpHeaders(header, "")
        if (cleansed) {
          const format_lower = (Array.isArray(cleansed)
            ? cleansed.map((c) => c.trim()).join("")
            : cleansed
          ).toLowerCase()

          const format_underscore = (Array.isArray(cleansed)
            ? cleansed.map((c) => c.trim()).join("_")
            : cleansed
          ).toLowerCase()

          if (cleansed) {
            translation[format_underscore] = header
            translation[format_lower] = header
          }
        }
      }

      set_translate(translation)
    }
  }, [rows])

  const capitalizeTheseHeaders = ["uid", "sku"]
  const [actionMenuOpen, setActionMenuOpen] = useState(false)
  const _checkboxActionButton = useRef()

  useEffect(() => {
    if (actionMenuOpen) {
      document.addEventListener("mousedown", handleClickOutside)
    } else {
      document.removeEventListener("mousedown", handleClickOutside)
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [actionMenuOpen])

  const handleClickOutside = (e) => {
    if (
      _checkboxActionButton.current &&
      !_checkboxActionButton.current.contains(e.target)
    ) {
      setTimeout(() => {
        setActionMenuOpen()
      }, 200)
    }
  }

  useEffect(() => {
    if (clear_table) {
      setSelectedIds([])
      setSelectedArr([])
      dispatch({
        type: "CLEAR_TABLE",
        data: false,
      })
    }
  }, [clear_table])

  useEffect(() => {
    dispatch({
      type: "CLEAR_TABLE",
      data: true,
    })
  }, [])

  useEffect(() => {
    dispatch({
      type: "SELECTED_EMPLOYEES",
      data: selectedIds.map((id) => id.slice(9)),
    })
  }, [selectedIds])

  useEffect(() => {
    setSelectedArr([])
    setSelectedIds([])
  }, [category])

  useEffect(() => {
    setSortConfig(null)
  }, [rows])

  useEffect(() => {
    setRender(false)
    if (rows && rows.length >= 1 && !sortConfig) {
      setRowsPerPage(50)
      setRowsCopy(JSON.parse(JSON.stringify(rows)))
      if (checkbox && Object.values(rows[0]).length > 1) {
        setRowsCopy(
          rows.map((i, key) => {
            return { selected: `checkbox-${i[checkboxKey]}`, ...i }
          })
        )
      }
      setStartIndex(0)
      setEndIndex(rowsPerPage)
    }
  }, [rows, sortConfig])

  useEffect(() => {
    if (rowsCopy) {
      const tempCopy = JSON.parse(JSON.stringify(rowsCopy))
      setTableData(tempCopy)
      setBase(tempCopy)
    }
  }, [rowsCopy])

  useEffect(() => {
    tableData && removeKeys()
  }, [tableData])

  useEffect(() => {
    sortConfig && tableSort()
  }, [sortConfig])

  async function removeKeys() {
    ignoreKeys &&
      ignoreKeys.map((i) => {
        tableData.map((obj) => {
          delete obj[i]
        })
      })
    await new Promise((r) => setTimeout(r, 0))
    setRender(true)
  }

  useEffect(() => {
    const checkedCollection =
      document.querySelectorAll(".box-checked").length > 0
        ? document.querySelectorAll(".box-checked")
        : null
    setRenderedCheckIds([])
    if (checkedCollection) {
      let tempRenderedCheckIds = []
      Array.from(checkedCollection).map((o) => {
        console.log(o.parentElement.id)
        tempRenderedCheckIds.push(o.parentElement.id)
      })
      setRenderedCheckIds(tempRenderedCheckIds)
    }
  }, [selectedIds, startIndex])

  const cleanUpHeaders = (stringToSplit, key) => {
    if (
      stringToSplit !== "selected" &&
      dontSortHeaders.includes(stringToSplit)
    ) {
      return
    }
    if (stringToSplit === "IR Vibrations" || stringToSplit === "irVibrations") {
      return "IR Vibrations"
    }
    const unformattedHeader = stringToSplit.split(/(?=[A-Z_])/)
    const header = unformattedHeader.map((word, index, unformattedHeader) => {
      word = word.charAt(0).toUpperCase() + word.slice(1)
      if (word.toLowerCase() === "selected") {
        return (
          <div
            style={{border: '1px solid'}}
            className={`checkbox checkbox-header ${
              renderedCheckIds.length ===
              tableData.slice(startIndex, endIndex).length
                ? "checked"
                : ""
            }`}
            key={key * 1020}
            onClick={handleSelectAll}
          >
            <Icon iconClass="Checkmark" size={"0.85rem"} />
          </div>
        )
      }
      if (capitalizeTheseHeaders.includes(word.toLowerCase())) {
        return word.toUpperCase()
      }
      if (word.includes("_")) {
        return word.replace("_", "").charAt(0).toUpperCase() + word.slice(2)
      }
      if (index !== unformattedHeader.length - 1) {
        if (
          /^[A-Z]/.test(unformattedHeader[index + 1]) &&
          unformattedHeader[index].length === 1 &&
          unformattedHeader[index + 1].length === 1
        ) {
          return word
        }
        return word + " "
      } else {
        return word
      }
    })
    return header
  }

  const navigate = (direction) => {
    if (direction === "next") {
      setStartIndex(startIndex + rowsPerPage)
      if (endIndex + rowsPerPage > tableData.length) {
        setLastPageReached(true)
        setEndIndex(endIndex + rowsPerPage)
      } else {
        setEndIndex(endIndex + rowsPerPage)
      }
    }
    if (startIndex >= 0 && direction === "previous") {
      if (lastPageReached) {
        setLastPageReached(false)
      }
      setStartIndex(startIndex - rowsPerPage)
      setEndIndex(endIndex - rowsPerPage)
    }
  }

  useEffect(() => {
    setEndIndexHelper()
  }, [rowsPerPage])

  const setRowsPerPageHelper = (rowCount) => {
    setLastPageReached(false)
    setRowsPerPage(rowCount)
    setStartIndex(0)
    setEndIndex(rowCount)
  }

  const setEndIndexHelper = () => {
    setEndIndex(rowsPerPage)
  }

  const renderLink = (link, type) => {
    switch (type) {
      case "preview":
        return (
          <a href={link} target="_blank" rel="noopener noreferrer">
            Preview
            <Icon iconClass={"Preview-link New-tab"} size={"0.8rem"} />
          </a>
        )
      case "edit":
        return (
          <div className="Simple-table-row-action-menu">
            <div
              className="action Edit"
              onClick={(e) => {
                routeToEdit(e, `${link}/edit`)
              }}
            >
              <Icon iconClass={"Edit-admin"} size={"1.1rem"} />
            </div>
            {deleteCallback && (
              <div
                className="action Delete"
                onClick={(e) => {
                  e.stopPropagation()
                  deleteCallback(link)
                }}
              >
                <Icon iconClass={"Delete-trash"} size={"1.1rem"} />
              </div>
            )}
          </div>
        )
      case "delete":
        return (
          <div className="Simple-table-row-action-menu">
            <div
              className="action Delete"
              onClick={(e) => {
                e.stopPropagation()
                deleteCallback(link)
              }}
            >
              <Icon iconClass={"Delete-trash"} size={"1.1rem"} />
            </div>
          </div>
        )
    }
  }

  const handleSelectAll = () => {
    const renderedRows = tableData.slice(startIndex, endIndex)
    const tempSelectedIds = renderedRows.map((k) => {
      return k.selected
    })
    const renderedCheckIdsCopy = JSON.parse(JSON.stringify(renderedCheckIds))

    if (
      renderedCheckIds.length === tableData.slice(startIndex, endIndex).length
    ) {
      const filteredSelectedIds = selectedIds.reduce((acc, v) => {
        if (!renderedCheckIdsCopy.includes(v)) {
          acc.push(v)
        }
        return acc
      }, [])
      const filteredSelectedArr = selectedArr.reduce((acc, k) => {
        if (!renderedCheckIdsCopy.includes(k.selected)) {
          acc.push(k)
        }
        return acc
      }, [])

      setSelectedIds(filteredSelectedIds)
      setSelectedArr(filteredSelectedArr)
    } else if (
      renderedCheckIds.length !== tableData.slice(startIndex, endIndex).length
    ) {
      if (selectedArr.length >= 0) {
        const tempSelectedArr = removeDuplicateObjectsFromArray(
          [...selectedArr, ...renderedRows],
          checkboxKey
        )
        const _tempSelectedIds = new Set([...selectedIds, ...tempSelectedIds])

        setSelectedArr(Array.from(tempSelectedArr))
        setSelectedIds(Array.from(_tempSelectedIds))
      }
    }
  }

  const handleCheckboxClick = (e) => {
    e.stopPropagation()

    const matchedRow = rowsCopy.filter((z) => {
      if (e.target.id === z.selected) {
        return z
      }
    })
    const tempSelectedArr = JSON.parse(JSON.stringify(selectedArr))
    const tempSelectedIds = JSON.parse(JSON.stringify(selectedIds))

    if (!e.target.children[0].classList.contains("checked")) {
      setSelectedArr([...selectedArr, matchedRow[0]])
      setSelectedIds([...selectedIds, e.target.id])
    } else {
      const ndx = selectedArr.indexOf(matchedRow[0])
      const idNdx = tempSelectedIds.indexOf(e.target.id)
      tempSelectedArr.splice(ndx, 1)
      tempSelectedIds.splice(idNdx, 1)
      setSelectedArr(tempSelectedArr)
      setSelectedIds(tempSelectedIds)
    }
  }

  const checkboxAction = (passedFunction) => {
    dispatch({
      type: "STORE_SELECTED_ITEMS",
      data: selectedArr,
    })
    if (checkboxActionConfig) {
      setTimeout(() => passedFunction(), 0)
    } else if (!checkboxActionConfig) {
      setTimeout(() => editCallback(), 0)
    }
  }

  const renderSort = (header, key) => {
    if (
      (dontSort && dontSort.includes(header)) ||
      dontSortHeaders.includes(header)
    ) {
      return
    }
    return (
      <div
        onClick={() => sortHelper(header)}
        className="simple-table-sort"
        key={key * 99.5}
      >
        <Icon
          iconClass={`Sort ${
            sortConfig?.header === header &&
            sortConfig?.sortDirection === "ascending"
              ? "Sort-asc"
              : sortConfig?.header === header &&
                sortConfig?.sortDirection === "descending"
              ? "Sort-desc"
              : "Sort-none"
          }`}
          size={"0.88rem"}
        />
      </div>
    )
  }

  const tableSort = () => {
    if (tableData) {
      let sortedData = [...tableData]
      sortedData = [...base]

      const sort_header = sortConfig.header.toLowerCase()

      sortedData.sort((a, b) => {
        if (
          a[translate[sort_header]].toString().toLowerCase() <
          b[translate[sort_header]].toString().toLowerCase()
        ) {
          return sortConfig.sortDirection === "ascending" ? -1 : 1
        }
        if (
          a[translate[sort_header]].toString().toLowerCase() >
          b[translate[sort_header]].toString().toLowerCase()
        ) {
          return sortConfig.sortDirection === "ascending" ? 1 : -1
        }
        return 0
      })
      setTableData(sortedData)
    }
  }

  const sortHelper = (header) => {
    let sortDirection = "ascending"
    if (
      sortConfig &&
      sortConfig.header === header &&
      sortConfig.sortDirection === "ascending"
    ) {
      sortDirection = "descending"
    } else if (
      sortConfig &&
      sortConfig.header === header &&
      sortConfig.sortDirection === "descending"
    ) {
      setSortConfig(null)
      return
    }
    setSortConfig({ header: header, sortDirection: sortDirection })
  }

  const clickableCallback = (e, row) => {
    e.stopPropagation()
    if (clickableCallbackFunction) {
      clickableCallbackFunction(row)
    } else {
      dispatch({
        type: "FROM_PATH",
        value: window.location.pathname,
      })
      history.push(row.clickableRow)
    }
  }

  const routeToEdit = (e, route) => {
    e.stopPropagation()
    dispatch({
      type: "FROM_PATH",
      value: window.location.pathname,
    })
    history.push(route)
  }

  function ordered_values(row) {
    if (!order || !row.hasOwnProperty("irVibrations")) return Object.values(row)

    let ordered = []

    row.selected && ordered.push(row.selected)

    for (let column of order) {
      ordered.push(row[column])
    }

    row.clickableRow && ordered.push(row.clickableRow)
    return ordered
  }

  const renderTable = () => {
    const headers = header_props || Object.keys(tableData[0])

    return (
      <>
        <div className="Simple-table-toolbar">
          {checkbox && !checkboxActionConfig && (
            <div
              className={`Checkbox-action-button button ${
                selectedArr.length > 0 ? "active" : "disabled"
              }`}
              onClick={checkboxAction}
            >
              {checkboxActionLabel} ({selectedArr.length})
            </div>
          )}
          {checkbox && checkboxActionConfig && (
            <div className="Simple-table-action-menu">
              <div
                className={`Checkbox-action-button button ${
                  selectedArr.length > 0 ? "active" : "disabled"
                } ${actionMenuOpen && selectedArr.length > 0 ? "Open" : ""}`}
                onClick={() => setActionMenuOpen(!actionMenuOpen)}
                ref={_checkboxActionButton}
              >
                {`Take Action on ${selectedIds.length} Item${
                  selectedIds.length === 1 ? "" : "s"
                }`}{" "}
                <Icon iconClass="Chevron-down" />
              </div>
              <div
                className={`Checkbox-action-items ${
                  actionMenuOpen && selectedArr.length > 0 ? "Open" : ""
                }`}
              >
                {checkboxActionConfig.map((z, key) => {
                  return (
                    <div
                      className={"Checkbox-action-item"}
                      key={key + 6}
                      onClick={() => {
                        checkboxAction(z.callback)
                      }}
                    >
                      {z.label}
                    </div>
                  )
                })}
              </div>
            </div>
          )}
        </div>
        <table className="Simple-table">
          <tbody>
            <tr className="Simple-table-headers">
              {headers.map((header, key) => {
                return (
                  <td
                    key={key * 10.3432}
                    className={`Simple-table-headers-data ${
                      header === "selected" ? "Select-header" : ""
                    }`}
                  >
                    <div className="simple-table-head-container">
                      <div>{cleanUpHeaders(header, (key = key))}</div>
                      {renderSort(header, (key = key))}
                    </div>
                  </td>
                )
              })}
            </tr>
            {tableData.slice(startIndex, endIndex).map((row, key) => {
              return (
                <tr
                  key={key + 40.203}
                  className={`Simple-table-row ${
                    selectedIds.includes(row.selected) ? "selected" : ""
                  } ${
                    row.clickableRow || clickableCallbackFunction
                      ? "clickable"
                      : ""
                  }`}
                >
                  {tableData &&
                  tableData.length === 1 &&
                  Object.keys(tableData[0])[0] === "noData" ? (
                    <td key={key * 3.423} className="Simple-table-data no-data">
                      {Object.values(tableData[0])[0]}
                    </td>
                  ) : (
                    ordered_values(row).map((data, key) => {
                      if (data === null) {
                        return (
                          <td
                            key={key * 9.3207}
                            className="Simple-table-data"
                            onClick={
                              row.clickableRow || clickableCallbackFunction
                                ? (e) => {
                                    clickableCallback(e, row)
                                  }
                                : () => {
                                    return null
                                  }
                            }
                          >
                            {" "}
                          </td>
                        )
                      } else if (
                        !data ||
                        data === "null" ||
                        data.toString().slice(0, 4).includes("http")
                      ) {
                        return (
                          <td
                            key={key * 9.3207}
                            className="Simple-table-data"
                            onClick={
                              row.clickableRow || clickableCallbackFunction
                                ? (e) => {
                                    clickableCallback(e, row)
                                  }
                                : () => {
                                    return null
                                  }
                            }
                          >
                            {" "}
                          </td>
                        )
                      } else if (typeof data === "number") {
                        return (
                          <td
                            key={key * 66.36}
                            className="Simple-table-data"
                            onClick={
                              row.clickableRow || clickableCallbackFunction
                                ? (e) => {
                                    clickableCallback(e, row)
                                  }
                                : () => {
                                    return null
                                  }
                            }
                          >
                            {" "}
                            {data}{" "}
                          </td>
                        )
                      } else if (data.toString().slice(0, 1) === "⬤") {
                        return (
                          <td
                            key={key * 9.3207}
                            className={`Simple-table-data ${data
                              .toString()
                              .slice(2, data.length)}`}
                            onClick={
                              row.clickableRow || clickableCallbackFunction
                                ? (e) => {
                                    clickableCallback(e, row)
                                  }
                                : () => {
                                    return null
                                  }
                            }
                          >
                            {data.toString().slice(0, 1)}
                          </td>
                        )
                      } else if (data.toString().includes("https://backend")) {
                        return (
                          <td
                            key={key * 5.56}
                            className="Simple-table-data preview"
                          >
                            {renderLink(data, "preview")}
                          </td>
                        )
                      } else if (
                        !data ||
                        data === "null" ||
                        data.toString().slice(0, 4).includes("http")
                      ) {
                        return (
                          <td
                            key={key * 9.3207}
                            className="Simple-table-data"
                            onClick={
                              row.clickableRow || clickableCallbackFunction
                                ? (e) => {
                                    clickableCallback(e, row)
                                  }
                                : () => {
                                    return null
                                  }
                            }
                          >
                            {" "}
                          </td>
                        )
                      } else if (
                        !!data &&
                        !Array.isArray(data) &&
                        !!data
                          .toString()
                          .match(
                            /[12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])/
                          ) &&
                        data.length >= 20 &&
                        data.charAt(data.length - 1) === "Z"
                      ) {
                        return (
                          <td
                            key={key * 5.993}
                            className="Simple-table-data"
                            onClick={
                              row.clickableRow || clickableCallbackFunction
                                ? (e) => {
                                    clickableCallback(e, row)
                                  }
                                : () => {
                                    return null
                                  }
                            }
                          >
                            {format(data).replace("ago", "")}
                          </td>
                        )
                      } else if (data === "0001-01-01T00:00:00Z") {
                        return (
                          <td
                            key={key * 35}
                            className="Simple-table-data"
                            onClick={
                              row.clickableRow || clickableCallbackFunction
                                ? (e) => {
                                    clickableCallback(e, row)
                                  }
                                : () => {
                                    return null
                                  }
                            }
                          >
                            Never
                          </td>
                        )
                      } else if (
                        data
                          .toString()
                          .match(
                            /^.*?\binventory\b.*?\blocation\b.*?\bdevices\b.*?$/m
                          ) ||
                        data.toString().includes("companies/") ||
                        data.toString().includes("locations/") ||
                        data.toString().includes("settings-packages/") ||
                        data.toString().includes("managers/")
                      ) {
                        if (editDisabled) return null
                        return (
                          <td
                            key={key * 4}
                            className="Simple-table-data edit link"
                          >
                            {renderLink(data, "edit")}
                          </td>
                        )
                      } else if (
                        data.toString() === "_delete-only" &&
                        deleteCallback
                      ) {
                        return (
                          <td
                            key={key * 12}
                            className="Simple-table-data edit link"
                          >
                            {renderLink(row, "delete")}
                          </td>
                        )
                      } else if (data.toString().includes("inventory/")) {
                        return (
                          <td
                            key={key * 12}
                            className="Simple-table-data"
                            onClick={
                              row.clickableRow || clickableCallbackFunction
                                ? (e) => {
                                    clickableCallback(e, row)
                                  }
                                : () => {
                                    return null
                                  }
                            }
                          ></td>
                        )
                      } else if (Array.isArray(data)) {
                        return (
                          <td
                            key={key * 11.439}
                            className="Simple-table-data"
                            onClick={
                              row.clickableRow || clickableCallbackFunction
                                ? (e) => {
                                    clickableCallback(e, row)
                                  }
                                : () => {
                                    return null
                                  }
                            }
                          >
                            {data
                              .filter((o) => {
                                return o !== ""
                              })
                              .join(", ")}
                          </td>
                        )
                      } else if (
                        checkbox &&
                        data.toString().includes("checkbox-")
                      ) {
                        return (
                          <td
                            key={key * 13.231}
                            className="Simple-table-checkbox"
                            id={data}
                            onClick={(e) => handleCheckboxClick(e)}
                          >
                            <div
                              key={key * 14.09342}
                              style={{border: '1px solid'}}
                              className={`checkbox ${
                                selectedIds.includes(data)
                                  ? "checked box-checked"
                                  : ""
                              }`}
                            >
                              <Icon iconClass="Checkmark" size={"0.9rem"} />
                            </div>
                          </td>
                        )
                      }
                      return (
                        <td
                          key={key * 2}
                          className="Simple-table-data"
                          onClick={
                            row.clickableRow || clickableCallbackFunction
                              ? (e) => {
                                  clickableCallback(e, row)
                                }
                              : () => {
                                  return null
                                }
                          }
                        >
                          {data}
                        </td>
                      )
                    })
                  )}
                </tr>
              )
            })}
          </tbody>
        </table>
      </>
    )
  }

  return (
    <div className="Simple-table-container">
      <div className={`Simple-table-upper-section rows-${rowsPerPage}`}>
        {render ? (
          renderTable()
        ) : (
          <Loading
            background={"none"}
            positionRelative={true}
            height={"600px"}
          />
        )}
      </div>
      {tableData &&
        tableData.length >= 1 &&
        Object.keys(tableData[0])[0] !== " " && (
          <div className="Simple-table-toolbar">
            {/* {
              checkbox && !checkboxActionConfig &&
              <div className={`Checkbox-action-button button ${selectedArr.length > 0 ? "active" : "disabled"}`} onClick={checkboxAction}>{checkboxActionLabel} ({selectedArr.length})</div>
            }
            {
              checkbox && checkboxActionConfig &&
                <div className="Simple-table-action-menu">
                  <div className={`Checkbox-action-button button ${selectedArr.length > 0 ? "active" : "disabled"} ${ actionMenuOpen && selectedArr.length > 0 ? "Open" : ""}`} onClick={() => setActionMenuOpen(!actionMenuOpen)} ref={_checkboxActionButton}>{`Take Action on ${selectedArr.length} Item${selectedArr.length === 1 ? "" : "s"}`} <Icon iconClass="Chevron-down"/></div>
                  <div className={`Checkbox-action-items ${ actionMenuOpen && selectedArr.length > 0 ? "Open" : ""}`}>
                    {
                      checkboxActionConfig.map( (z,key) => {
                        return (
                          <div className={`Checkbox-action-item`} key={key+6} onClick={ () => {checkboxAction(z.callback)}}>{z.label}</div>
                        )
                      })
                    }
                  </div>
                </div>
            } */}
            <p>Rows Per Page:</p>
            <div>
              {rows && (
                <DropDownSelect
                  options={
                    rows.length <= 200
                      ? [
                          { value: 50, display: "50" },
                          { value: rows.length, display: "Show All" },
                        ]
                      : rows.length <= 1000
                      ? [
                          { value: 50, display: "50" },
                          { value: 200, display: "200" },
                          { value: rows.length, display: "Show All" },
                        ]
                      : rows.length <= 2000
                      ? [
                          { value: 100, display: "50" },
                          { value: 200, display: "200" },
                          { value: 500, display: "500" },
                          { value: rows.length, display: "Show All" },
                        ]
                      : rows.length > 2000 && [
                          { value: 50, display: "50" },
                          { value: 200, display: "200" },
                          { value: 500, display: "500" },
                          { value: 1000, display: "1000" },
                          { value: 2000, display: "2000" },
                        ]
                  }
                  val={rowsPerPage}
                  onChange={(e) =>
                    setRowsPerPageHelper(parseInt(e.target.value))
                  }
                  nullOption={false}
                  disabled={rows.length <= 50}
                />
              )}
            </div>
            <p className="Display-range">
              {startIndex + 1} -{" "}
              {tableData?.length < rowsPerPage || lastPageReached
                ? tableData.length
                : endIndex}{" "}
              of {tableData?.length}
            </p>
            <div className="Navigation">
              <div
                className={`Navigation-button button ${
                  startIndex <= 0 ? "disabled" : ""
                }`}
                onClick={() => navigate("previous")}
              >
                <p>Previous</p>
              </div>
              <div
                className={`Navigation-button button ${
                  tableData && endIndex >= tableData.length ? "disabled" : ""
                }`}
                onClick={() => navigate("next")}
              >
                <p>Next</p>
              </div>
            </div>
          </div>
        )}
    </div>
  )
}
