// --------------------------- Action constants --------------------------
export const KEEN_FETCH_REQUEST = "keen/FETCH_REQUEST"
export const KEEN_HOURLY_FETCH_REQUEST = "keen/HOURLY_FETCH_REQUEST"
export const KEEN_FETCH_SUCCESS = "keen/FETCH_SUCCESS"
export const KEEN_FETCH_FAILURE = "keen/FETCH_FAILURE"
export const MATH_ENGINE_FETCH_SUCCESS = "keen/MATH_ENGINE_FETCH_SUCCESS"
export const MATH_ENGINE_FETCH_FAILURE = "keen/MATH_ENGINE_FETCH_FAILURE"
export const MATH_ENGINE_FETCH_HOURLY_SUCCESS =
  "keen/MATH_ENGINE_FETCH_HOURLY_SUCCESS"
export const MATH_ENGINE_FETCH_HOURLY_FAILURE =
  "keen/MATH_ENGINE_FETCH_HOURLY_FAILURE"

// --------------------------- Action functions --------------------------

export function keenRequest(
  min,
  max,
  deltaEmployees,
  deltaJobs,
  deltaDevices,
  selectedJobs,
  prevMin,
  prevMax,
  auto
) {
  /* We can only load this data if delta has fetched job types */
  if (!deltaEmployees || Object.keys(deltaEmployees).length === 0) {
    return false
  }

  return {
    type: KEEN_FETCH_REQUEST,
    payload: {},
  }
}
