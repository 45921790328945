import React, { useState, useEffect } from "react"
import { Icon } from "../index.js"

export function AssignmentLists({
  assigned,
  unassigned,
  title1,
  title2,
  firstKey,
  secondKey,
  addItems,
  removeItems,
  mode,
  placeholder,
  onChange,
}) {
  const [selectedUnassignedItems, setSelectedUnassignedItems] = useState([])
  const [selectedAssignedItems, setSelectedAssignedItems] = useState([])
  const [firstAssignedItemsChunk, setFirstAssignedItemsChunk] = useState([])
  const [secondAssignedItemsChunk, setSecondAssignedItemsChunk] = useState([])

  const add = () => {
    onChange()
    addItems(selectedUnassignedItems)
    setTimeout(() => setSelectedUnassignedItems([]), 100)
  }

  const remove = () => {
    onChange()
    removeItems(selectedAssignedItems)
    setTimeout(() => setSelectedAssignedItems([]), 100)
  }

  const selectedItem = (item, status) => {
    if (status === "assigned") {
      if (!selectedUnassignedItems.includes(item)) {
        setSelectedUnassignedItems([...selectedUnassignedItems, item])
      } else {
        const index = selectedUnassignedItems.indexOf(item)
        let arrCopy = selectedUnassignedItems
        if (index > -1) {
          arrCopy.splice(index, 1)
        }
        setSelectedUnassignedItems([...arrCopy])
      }
    } else if (status === "unassigned") {
      if (!selectedAssignedItems.includes(item)) {
        setSelectedAssignedItems([...selectedAssignedItems, item])
      } else {
        const index = selectedAssignedItems.indexOf(item)
        let arrCopy = selectedAssignedItems
        if (index > -1) {
          arrCopy.splice(index, 1)
        }
        setSelectedAssignedItems([...arrCopy])
      }
    }
  }

  useEffect(() => {
    if (mode === "View" && assigned.length > 1) {
      const halfPoint = assigned.length / 2
      setFirstAssignedItemsChunk(assigned.slice(0, halfPoint))
      setSecondAssignedItemsChunk(assigned.slice(halfPoint, assigned.length))
    } else if (mode === "View" && assigned.length === 1) {
      setFirstAssignedItemsChunk(assigned)
    }
  }, [assigned])

  return (
    <div
      className={`Assignment-list-box main-contain narrow ${
        mode === "View" ? "View" : ""
      }`}
    >
      {mode !== "View" ? (
        <>
          <div className="side">
            <h2 className="List-title">
              {title1}
              <p
                className={`Selection-count ${
                  selectedUnassignedItems.length > 0 ? "Visible" : ""
                }`}
              >{`(${selectedUnassignedItems.length}) Selected`}</p>
            </h2>
            <div className="List-box locations-list" style={{ 
              overflow: 'scroll',
              border: '1px solid #6747c7',
              padding: '10px'
            }}>
              {unassigned &&
                unassigned.map((l) => (
                  <div
                    className={
                      selectedUnassignedItems.includes(l[secondKey])
                        ? "highlight"
                        : ""
                    }
                    onClick={() => selectedItem(l[secondKey], "assigned")}
                    key={l[secondKey]}
                  >
                    {l[firstKey]}
                  </div>
                ))}
            </div>
          </div>
          <div className="Button-container">
            <div
              className={`${
                selectedUnassignedItems.length < 1 ? "disabled" : ""
              } button`}
              onClick={add}
            >
              ADD <Icon iconClass={"Arrow-right"} />
            </div>
            <div
              className={`${
                selectedAssignedItems.length < 1 ? "disabled" : ""
              } button delete`}
              onClick={remove}
            >
              <Icon iconClass={"Arrow-left"} /> REMOVE
            </div>
          </div>
          <div className="side">
            <h2 className="List-title">
              {title2}
              <p
                className={`Selection-count ${
                  selectedAssignedItems.length > 0 ? "Visible" : ""
                }`}
              >{`(${selectedAssignedItems.length}) Selected`}</p>
            </h2>
            <div className="List-box locations-list" style={{ 
              overflow: 'scroll',
              border: '1px solid #6747c7',
              padding: '10px'
            }}>
              {/* {placeholder && placeholder != "" && assigned.length == 0 && (
                <div className="assignment-placeholder">{placeholder}</div>
              )} */}
              {assigned.length > 0 &&
                assigned.map((l) => (
                  <div
                    className={
                      selectedAssignedItems.includes(l[secondKey])
                        ? "highlight"
                        : ""
                    }
                    onClick={() => selectedItem(l[secondKey], "unassigned")}
                    key={l[secondKey]}
                  >
                    {l[firstKey]}
                  </div>
                ))}
            </div>
            {assigned.length < 1 && (
              <div className="noAssignments">
                <p>There must be at least one location assigned to manager.</p>
              </div>
            )}
          </div>
        </>
      ) : (
        <div style={{ width: "100%" }}>
          <h2 className="List-title">
            {title2}
            <p
              className={`Selection-count ${
                selectedAssignedItems.length > 0 ? "Visible" : ""
              }`}
            >{`(${selectedAssignedItems.length}) Selected`}</p>
          </h2>
          <div className="Split-list-box-container">
            <div
              className={`List-box locations-list ${
                mode === "View" ? "View" : ""
              }`}
            >
              {firstAssignedItemsChunk.map((l) => (
                <div
                  className={
                    selectedAssignedItems.includes(l[secondKey])
                      ? "highlight"
                      : ""
                  }
                  onClick={() => selectedItem(l[secondKey], "unassigned")}
                  key={l[secondKey]}
                >
                  {l[firstKey]}
                </div>
              ))}
            </div>
            <div
              className={`List-box locations-list ${
                mode === "View" ? "View" : ""
              }`}
            >
              {secondAssignedItemsChunk.map((l) => (
                <div
                  className={
                    selectedAssignedItems.includes(l[secondKey])
                      ? "highlight"
                      : ""
                  }
                  onClick={() => selectedItem(l[secondKey], "unassigned")}
                  key={l[secondKey]}
                >
                  {l[firstKey]}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
