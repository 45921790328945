import React, { useState } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"

import "./styles.scss"
import { requestPasswordReset } from "../../../redux/actions/user"

const ResetPassword = ({ user, dispatch }) => {
  const [succeeded, setSucceeded] = useState(false)
  const [pending, setPending] = useState(false)
  const [errorMessage, setErrorMessage] = useState()

  if (succeeded) {
    return (
      <div className="forgot-password">
        <form>
          <p className="heading">Email sent!</p>
          <p className="success-msg">
            Check your inbox for instructions from us on how to reset your
            password.
          </p>

          <Link to="/login">Log in now</Link>
        </form>
      </div>
    )
  }

  const onSubmit = async (e) => {
    e.preventDefault()

    const form = e.target
    const email = form.querySelector("[name=email]").value

    setPending(true)

    try {
      await requestPasswordReset(email, dispatch)

      setSucceeded(true)
    } catch (err) {
      setErrorMessage(err.message)
    } finally {
      setPending(false)
    }
  }

  return (
    <div className="forgot-password">
      <form onSubmit={onSubmit}>
        <p className="heading">Reset your password</p>
        <p className="instructions">
          Enter the email associated with your account.
        </p>

        {errorMessage !== undefined && (
          <p className="error-msg">{errorMessage}</p>
        )}

        <input
          type="email"
          name="email"
          placeholder="you@organization.com"
          defaultValue={user.email}
        />

        <input type="submit" value="Reset" disabled={pending} />
      </form>
    </div>
  )
}

export default connect((state) => ({
  user: state.user,
}))(ResetPassword)
