import React, { useState, useEffect } from "react"
import { useParams, useHistory } from "react-router-dom"
import { useSelector, useDispatch, useStore } from "react-redux"
import { Helmet } from "react-helmet"

import { format } from "timeago.js"

import { setCurrentLocation } from "../../../redux/actions/admin"
import replaceUIDs from "../utils/replaceUIDs"

import {
  SimpleTable,
  SearchBar,
  Loading,
  Icon,
  SelectBox,
} from "../../../components/ui"
import { InventoryEditModal } from "../inventory-edit-modal"
import { CSVdownloadModal } from "../../../components/csv-download-modal"
import { PendingCommandsModal } from "../../../components/pending-commands-modal"
import { DeviceStatusUpdateModal } from "../../../components/device-status-update-modal"

import { useBreadCrumbsContext } from "../../../hooks"

export default function InventoryDeviceListingPage() {
  const {
    employees,
    companyUID,
    companyMap,
    selectedLocation,
    devices,
  } = useSelector((state) => ({
    employees: state.admin.locationData.employees,
    companyUID: state.admin.locationData.location?.company_uid,
    selectedLocation: state.admin.selectedLocation,
    companyMap: state.admin.companyMap,
    devices: state.admin.locationData.devices,
  }))
  const params = useParams()
  const [filteredRows, setFilteredRows] = useState([])
  const [deviceData, setDeviceData] = useState(null)
  const [connectivityData, setconnectivityData] = useState([])
  const [dockData, setdockData] = useState([])
  const [render, setRender] = useState(false)
  const dispatch = useDispatch()
  const [activeTab, setActiveTab] = useState("devices")
  const [editModalToggle, setEditModalToggle] = useState(false)
  const [downloadCSV, setDownloadCSV] = useState(false)
  const [sendCommandModalToggle, setSendCommandModalToggle] = useState(false)
  const [statusUpdateModalToggle, setStatusUpdateModalToggle] = useState(false)
  const history = useHistory()
  const [storeUpdate, setStoreUpdate] = useState()
  const store = useStore()
  const dataCategory = {
    devices: deviceData,
  }
  const passBreadCrumbs = useBreadCrumbsContext()

  const onFilterData = (results) => {
    setFilteredRows(results)
  }

  const clearFilterData = () => {
    setFilteredRows([])
  }

  const handleChangesToStore = () => {
    if (!storeUpdate && store.getState().admin.locationData?.location) {
      setStoreUpdate(true)
    }
  }

  useEffect(() => {
    store.subscribe(handleChangesToStore)
    setCurrentLocation(params.locationid, dispatch)
    updateActiveTab()
    const unsubscribe = store.subscribe(handleChangesToStore)

    return unsubscribe()
  }, [])

  useEffect(() => {
    devices && buildDeviceData()
  }, [devices, storeUpdate])

  const buildDeviceData = () => {
    const devicesArr = replaceUIDs(
      devices,
      employees,
      "employee",
      "uid",
      "name"
    )
    devicesArr.map((d) => {
      d["last_pinged"] = d["last_push_wu_time"]
      d[
        "clickableRow"
      ] = `/inventory/location/${selectedLocation}/devices/${d.uid}`
    })
    setDeviceData(devicesArr)
  }

  useEffect(() => {
    if (deviceData) {
      setRender(true)
    }
  }, [deviceData])



  const updateActiveTab = (tab) => {
    if (tab) {
      setActiveTab(tab)
    } else if (window.location.href.split("#")[1]) {
      setActiveTab(window.location.href.split("#")[1])
    } else {
      setActiveTab("devices")
    }
  }

  const editModalHelper = () => {
    setEditModalToggle(true)
  }

  const redirect = (locationUID) => {
    history.push(`/inventory/location/${locationUID}#devices`)
    setTimeout(() => {
      setCurrentLocation(locationUID, dispatch)
    }, 50)
    setTimeout(() => {
      setStoreUpdate(false)
    }, 1000)
  }

  const sendCommandModalHelper = () => {
    setSendCommandModalToggle(true)
  }

  const statusUpdateModalHelper = () => {
    setStatusUpdateModalToggle(true)
  }

  const table = () => {

    return (
      <div className="admin wrapper Inventory-device-listing-container">
        <main className="admin container">
          <Helmet>
            <meta charSet="utf-8" />
            <title>{` ${selectedLocation} ${activeTab.replace(/^\w/, (c) =>
              c.toUpperCase()
            )} • Kinetic `}</title>
          </Helmet>
          {passBreadCrumbs([
            { content: <Icon iconClass={"Device"} />, route: "/inventory" },
            {
              content: companyMap && companyMap[companyUID],
              route: `/inventory/${companyUID}`,
            },
            { content: selectedLocation && selectedLocation },
          ])}
          <div className="main-content">
            <h1 className="main-heading">Inventory Manager</h1>
            <h2 className="sub-heading">
              {activeTab.replace(/^\w/, (c) => c.toUpperCase())}
            </h2>
            <div className="main-heading-parent">
              <a
                className={`main-heading tab ${
                  activeTab === "devices" ? "active" : ""
                }`}
                href="#devices"
                onClick={() => updateActiveTab("devices")}
              >
                Devices
              </a>
              <a
                className={`main-heading tab ${
                  activeTab === "connectivity" ? "active" : ""
                }`}
                href="#connectivity"
                onClick={() => updateActiveTab("connectivity")}
              >
                Connectivity
              </a>
              <a
                className={`main-heading tab ${
                  activeTab === "docks" ? "active" : ""
                }`}
                href="#docks"
                onClick={() => updateActiveTab("docks")}
              >
                Docks
              </a>
              <div
                className={`main-heading-tab-slider active ${activeTab}`}
              ></div>
            </div>
            <div className="search-and-action-parent">
              <div className="search-and-action-parent-left">
                <SearchBar
                  placeholder={`Search ${activeTab}`}
                  handleSearch={onFilterData}
                  data={
                    activeTab === "devices"
                      ? deviceData
                      : activeTab === "connectivity"
                      ? connectivityData
                      : activeTab === "docks" && dockData
                  }
                  clearFilterData={clearFilterData}
                />
              </div>
              <div className="Action-menu-parent">
                <div className="Action-menu">
                  {
                    <>
                      <div className="Action-menu">
                        <div
                          className={`add-button button secondary ${
                            deviceData && deviceData.length === 0
                              ? "disabled"
                              : ""
                          }`}
                          onClick={() => setDownloadCSV(true)}
                        >
                          <Icon iconClass={"Download"} />
                          <p>DOWNLOAD CSV</p>
                        </div>
                      </div>
                    </>
                  }
                </div>
              </div>
            </div>
            <section className={"table-container"}>
              {activeTab === "connectivity" ? (
                <>
                  <SimpleTable
                    ignoreKeys={[]}
                    rows={
                      filteredRows.length > 0
                        ? filteredRows
                        : //: dockData.length > 0 ? dockData :
                          [{ noData: "This location has no connectivity" }]
                    }
                  />
                </>
              ) : activeTab === "devices" ? (
                <>
                  <SimpleTable
                    ignoreKeys={[
                      "location_uid",
                      "thing_uid",
                      "last_push_wu_time",
                      "battery",
                      //                        "version",
                      "last_ip",
                      "last_push_server_time",
                      "last_ssid",
                      "last_docked",
                      "linux_version",
                      "docked",
                      "locked",
                      "wifi_mac",
                      "bluetooth_mac",
                      "created_at",
                      "deleted_at",
                    ]}
                    rows={
                      filteredRows.length > 0
                        ? filteredRows
                        : deviceData.length > 0
                        ? deviceData
                        : [{ noData: "This location has no devices" }]
                    }
                    checkbox={true}
                    checkboxActionConfig={[
                      { callback: editModalHelper, label: "Move Devices" },
                      {
                        callback: sendCommandModalHelper,
                        label: "Pending Commands",
                      },
                      {
                        callback: statusUpdateModalHelper,
                        label: "Update Status",
                      },
                    ]}
                    category={"devices"}
                    checkboxKey={"uid"}
                  />
                </>
              ) : (
                activeTab === "docks" && (
                  <>
                    <SimpleTable
                      ignoreKeys={["key", "uid"]}
                      rows={
                        filteredRows.length > 0
                          ? filteredRows
                          : //connectivityData.length > 0 ? connectivityData :
                            [{ noData: "This location has no docks" }]
                      }
                    />
                  </>
                )
              )}
              {editModalToggle && (
                <InventoryEditModal
                  category={"device(s)"}
                  close={setEditModalToggle}
                  action={"Move"}
                  disableSetCompany={false}
                  redirect={(locationUID) => redirect(locationUID)}
                />
              )}
              {sendCommandModalToggle && (
                <PendingCommandsModal
                  placeholder="Type commands here..."
                  action="Send Commands"
                  header={"Send Pending Commands"}
                  closeModal={setSendCommandModalToggle}
                />
              )}
              {statusUpdateModalToggle && (
                <DeviceStatusUpdateModal
                  action="Update Status"
                  header={"Update Status"}
                  closeModal={setStatusUpdateModalToggle}
                />
              )}
            </section>
          </div>
        </main>
        {downloadCSV && (
          <CSVdownloadModal
            csvData={dataCategory[activeTab]}
            filename={`${activeTab} - ${companyMap[companyUID]}.csv`}
            close={() => setDownloadCSV(false)}
            category={activeTab}
            ignoreKeys={[
              "thing_uid",
              "battery",
              "last_push_wu_time",
              "last_ssid",
              "last_docked",
              "docked",
              "locked",
              "wifi_mac",
              "bluetooth_mac",
              "created_at",
              "deleted_at",
            ]}
          />
        )}
      </div>
    )
  }

  return <>{render ? table() : <Loading background={"none"} />}</>
}
