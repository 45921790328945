import React, { useState, useEffect, useRef } from "react"
import { useParams, useHistory } from "react-router-dom"
import { useSelector, useDispatch, useStore } from "react-redux"
import { Helmet } from "react-helmet"
import { Link } from "react-router-dom"
import moment from "moment"
import styled from "styled-components"

import { API } from "../../../utils/api"

import {
  getManagerList,
  setCurrentCompany,
  setCurrentLocation,
} from "../../../redux/actions/admin"
import replaceUIDs from "../utils/replaceUIDs"
import renameColumns from "../utils/renameColumns"
import orderColumns from "../utils/orderColumns"

import { dateStringFromServerIsNotNull } from "../../../utils"
import {
  SimpleTable,
  SearchBar,
  Loading,
  Icon,
  SelectBox,
  DropDownSelect,
} from "../../../components/ui"
import { InventoryEditModal } from "../inventory-edit-modal"
import { CSVdownloadModal } from "../../../components/csv-download-modal"
import { PendingCommandsModal } from "../../../components/pending-commands-modal"
import { DeviceStatusUpdateModal } from "../../../components/device-status-update-modal"

import DeviceDisplays from "./device-displays"

import { useBreadCrumbsContext, useSidePanelContext } from "../../../hooks"

import {
  Modal,
  ModalTitle,
  useModal,
  FormInputSelect,
  FormLabel,
  useDropdown,
  DropdownContainer,
  DropdownOption,
  Button,
  Theme,
} from "../../../components/next"

import SVGChevronDown from "../../../components/next/icons/icon_chevron_down.svg"
import SVGXMark from "../../../components/next/icons/icon_x_mark.svg"
import GIFLoading from "../../../components/next/icons/loading.gif"
import { SET_CURRENT_LOCATION } from "../../../redux/actions/user"

const ModalTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`
const ModalInputContainer = styled.div`
  position: relative;
`

const ModalRow = styled.div`
  margin-top: 1.5rem;
`

const IconChevronDown = styled.img`
  height: 1rem;
  width: 1rem;
  position: absolute;
  top: 0.75rem;
  right: 0.5rem;
`

const IconXMark = styled.img`
  height: 1.5rem;
  width: 1.5rem;
  margin-left: 5rem;
  cursor: pointer;
  transition: 200ms all ${Theme.easing.default};
  margin-bottom: 1rem;

  &:hover {
    opacity: 0.6;
  }
`

const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 4rem 0 0 1rem;
`

const ButtonMargin = styled.div`
  margin-left: 1rem;
`

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
`

function ModalErgoFeedback({ show, hide, props, dispatch }) {
  const { employees, selected_employees } = useSelector((state) => ({
    employees: state.admin.locationData.employees,
    selected_employees: state.admin.selected_employees,
  }))

  const [bulk_status, set_bulk_status] = useState(null)
  const [loading, set_loading] = useState(false)
  const [employees_map, set_employees_map] = useState({})

  const ref_input = useRef()
  const ref_dropdown = useRef()

  const dropdown = useDropdown(ref_input.current, ref_dropdown.current)

  useEffect(() => {
    set_employees_map(
      Object.values(employees || {}).reduce((coll, curr) => {
        coll[curr.uid] = curr
        return coll
      }, {})
    )
  }, [employees])

  async function update_ergo_status() {
    const updates = selected_employees.map((id) => {
      const match = employees_map[id]

      return {
        uid: match.uid,
        feedback: bulk_status,
        shift_end_time: match.shiftEndHour,
      }
    })

    set_loading(true)

    API.adminWorkersPut(updates)
      .then(async (res) => {
        const path = window.location.pathname.split("/")
        const location_id = path[path.length - 1]

        await setCurrentLocation(location_id, dispatch)

        dispatch({ type: "CLEAR_TABLE", data: true })
        set_loading(false)
        hide()
      })
      .catch((e) => {
        set_loading(false)
        alert(e)
        hide()
      })
  }

  return (
    <Modal {...props}>
      <ModalTitleContainer>
        <ModalTitle>
          Update IR Vibration Status for {(selected_employees || []).length}{" "}
          employee
          {(selected_employees || []).length > 1 ? "s" : ""}?
        </ModalTitle>
        <IconXMark src={SVGXMark} onClick={() => hide()} />
      </ModalTitleContainer>
      <ModalRow>
        {loading && (
          <LoadingContainer>
            <div className="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </LoadingContainer>
        )}
      </ModalRow>
      {!loading && (
        <ModalRow>
          <FormLabel>Ergo Feedback Status</FormLabel>
          <ModalInputContainer>
            <FormInputSelect
              onChange={() => {}}
              ref={ref_input}
              placeholder={"Select..."}
              onClick={() => dropdown.show()}
              value={bulk_status_display()}
            />
            <IconChevronDown src={SVGChevronDown} />
          </ModalInputContainer>
          <DropdownContainer {...dropdown.props} ref={ref_dropdown}>
            <DropdownOption
              onClick={() => {
                set_bulk_status(true)
                dropdown.hide()
              }}
            >
              On
            </DropdownOption>
            <DropdownOption
              onClick={() => {
                set_bulk_status(false)
                dropdown.hide()
              }}
            >
              Off
            </DropdownOption>
          </DropdownContainer>
        </ModalRow>
      )}
      {!loading && (
        <ModalRow>
          <ModalButtonContainer>
            <Button variant="secondary" onClick={() => hide()}>
              Cancel
            </Button>
            <ButtonMargin>
              <Button onClick={() => update_ergo_status()}>Confirm</Button>
            </ButtonMargin>
          </ModalButtonContainer>
        </ModalRow>
      )}
    </Modal>
  )

  function bulk_status_display() {
    if (bulk_status === true) return "On"
    if (bulk_status === false) return "Off"
    return ""
  }
}

export default function EmployeePageListing() {
  const {
    employees,
    companyUID,
    companyMap,
    selectedLocation,
    devices,
    jobs,
    managerList,
    location,
  } = useSelector((state) => ({
    employees: state.admin.locationData.employees,
    companyUID: state.admin.locationData.location?.company_uid,
    selectedLocation: state.admin.selectedLocation,
    companyMap: state.admin.companyMap,
    devices: state.admin.locationData.devices,
    jobs: state.admin.locationData.jobs,
    managerList: state.admin.managerList,
    location: state.admin.locationData,
  }))

  const params = useParams()
  const [filteredRows, setFilteredRows] = useState([])
  const [deviceData, setDeviceData] = useState([])
  const [deviceDataSet, setDeviceDataSet] = useState([])
  const [jobsData, setJobsData] = useState([])
  const [managerData, setManagerData] = useState([])
  const [render, setRender] = useState(false)
  const [employeesData, setEmployeesData] = useState([])
  const dispatch = useDispatch()
  const [activeTab, setActiveTab] = useState("employees")
  const searchbarBlacklist = ["device-displays"]
  const [editModalToggle, setEditModalToggle] = useState(false)
  const store = useStore()
  const [pingFilter, setPingFilter] = useState()
  const [downloadCSV, setDownloadCSV] = useState(false)
  const [sendCommandModalToggle, setSendCommandModalToggle] = useState(false)
  const [statusUpdateModalToggle, setStatusUpdateModalToggle] = useState(false)
  const [storeUpdate, setStoreUpdate] = useState()
  const [bulk_status, set_bulk_status] = useState()

  const history = useHistory()
  const dataCategory = {
    managers: managerData,
    devices: deviceDataSet,
  }
  const passBreadCrumbs = useBreadCrumbsContext()
  const renderSidePanel = useSidePanelContext()

  const onFilterData = (results) => {
    setFilteredRows(results)
  }

  const clearFilterData = () => {
    setFilteredRows([])
  }

  const handleChangesToStore = () => {
    if (!storeUpdate && store.getState().admin.locationData?.location)
      setStoreUpdate(true)
  }

  const filterByPing = (index) => {
    setPingFilter(parseInt(index))
  }

  useEffect(() => {
    const company = location?.location?.company_uid

    if (company) {
      setCurrentCompany(company, dispatch)
    }
  }, [location])

  useEffect(() => {
    store.subscribe(handleChangesToStore)
    setCurrentLocation(params.locationid, dispatch)
    updateActiveTab()
    const unsubscribe = store.subscribe(handleChangesToStore)

    return unsubscribe()
  }, [])

  useEffect(() => {
    if (employees && devices) {
      const employeesArr = replaceUIDs(employees, jobs, "job", "uid", "name")
      const employeesWithDevicesSkus = replaceUIDs(
        employeesArr,
        devices,
        "device",
        "uid",
        "serial_number"
      )

      const sortedEmployeesArr = employeesWithDevicesSkus.map((i) => {
        i["irVibrations"] = JSON.parse(i.vibrationStatus) ? "On" : "Off"
        i["clickableRow"] = `/locations/${selectedLocation}/employees/${i.uid}`
        return { name: i.name, device: i.device, job: i.job, ...i }
      })

      setEmployeesData(renameColumns(sortedEmployeesArr, { job: "group" }))
    }
  }, [employees])

  useEffect(() => {
    if (pingFilter > 0) {
      const fild = deviceData.filter((d) => {
        if (!d.timeSinceLastPing) {
          return true
        } else {
          // subtract the last push time from current time
          const timeDiff = new Date() - new Date(d.timeSinceLastPing)
          // timeDiff will be milliseconds, convert to hours
          const timeDiffHours = timeDiff / 3600000
          if (timeDiffHours > pingFilter) return d
        }
      })
      console.log(fild)
      setDeviceDataSet(fild)
    } else if (pingFilter === 0) {
      setDeviceDataSet(deviceData)
    }
  }, [pingFilter])

  useEffect(() => {
    if (devices) {
      const devicesArr = replaceUIDs(
        devices,
        employees,
        "worker_uid",
        "uid",
        "name"
      )
      devicesArr.map((d) => {
        const requiredHeaders = [
          "serial_number",
          "uid",
          "worker_uid",
          "version",
          "status",
          "last_push_wu_time",
        ]
        requiredHeaders.forEach((h) => {
          if (!(h in d)) {
            d[h] = ""
          }
        })
        d["clickableRow"] = `/locations/${d.location_uid}/devices/${d.uid}`
      })
      setDeviceData(
        renameColumns(devicesArr, {
          serial_number: "sku",
          worker_uid: "employee",
          version: "currentDeviceVersion",
          last_push_wu_time: "timeSinceLastPing",
        })
      )
    }
  }, [devices, storeUpdate])

  useEffect(() => {
    setDeviceDataSet(
      deviceData.map((d) => {
        d.linux_version = d.linux_version || ""
        return d
      })
    )
  }, [deviceData])

  useEffect(() => {
    if (managerList) {
      const mans = JSON.parse(JSON.stringify(managerList))
      let _filteredManagers = mans.reduce((acc, i) => {
        if (i.locations.includes(params.locationid)) {
          i[
            "clickableRow"
          ] = `/locations/${params.locationid}/managers/${i.uid}`
          acc.push(i)
        }
        return acc
      }, [])

      const filteredManagers = mans.filter((manager) => {
        console.log(manager)
        return manager.locations.includes(params.locationid)
      })

      filteredManagers.map((m) => {
        m["locations"] = m.locations.length
      })
      setManagerData(
        renameColumns(filteredManagers, { locations: "locationsManaged" })
      )
    }
  }, [managerList, storeUpdate])

  useEffect(() => {
    if (jobs) {
      const jobsCopy = JSON.parse(JSON.stringify(jobs))
      const jerbs = Object.values(jobsCopy).map((i) => {
        if (i.name.toLowerCase() != "none") {
          i["clickableRow"] = `/locations/${selectedLocation}/jobs/${i.uid}`
        }
        if (i.active) return { ...i, active: "Active" }
        else return { ...i, active: "Inactive" }
      })
      setJobsData(jerbs)
      setRender(true)
    }
  }, [jobs, storeUpdate])

  const updateActiveTab = (tab) => {
    if (tab) setActiveTab(tab)
    else if (window.location.href.split("#")[1])
      setActiveTab(window.location.href.split("#")[1])
    else setActiveTab("employees")
  }

  const editModalHelper = () => {
    setEditModalToggle(true)
  }

  const redirect = (locationUID) => {
    history.push(`/locations/${locationUID}#devices`)
    setTimeout(() => {
      setCurrentLocation(locationUID, dispatch)
    }, 50)
    setTimeout(() => {
      setStoreUpdate(false)
    }, 1000)
  }

  const sendCommandModalHelper = () => {
    setSendCommandModalToggle(true)
  }

  const statusUpdateModalHelper = () => {
    setStatusUpdateModalToggle(true)
  }

  const buildDeviceDataSet = (ds) => {
    return ds.map((d) => {
      const lastPing = dateStringFromServerIsNotNull(d["timeSinceLastPing"])
        ? moment(d["timeSinceLastPing"]).fromNow(true)
        : "Never"
      return { ...d, timeSinceLastPing: lastPing }
    })
  }

  const modal_ergo_feedback = useModal()

  const table = () => {
    const pingFilterOptions = [
      { value: 0, display: "Include All" },
      { value: 6, display: "6 Hours" },
      { value: 12, display: "12 Hours" },
      { value: 24, display: "1 Day" },
      { value: 48, display: "2 Days" },
      { value: 168, display: "1 Week" },
    ]

    return (
      <div className="admin wrapper Employee-listing-container">
        <main className="admin container">
          <Helmet>
            <meta charSet="utf-8" />
            <title>{` ${selectedLocation} ${activeTab.replace(/^\w/, (c) =>
              c.toUpperCase()
            )} • Kinetic `}</title>
            <link rel="canonical" href="http://mysite.com/example" />
          </Helmet>
          {renderSidePanel("LOCATION")}
          {passBreadCrumbs([
            {
              content: <Icon iconClass={"Skyline"} />,
              route: "/companies",
            },
            {
              content: companyMap && companyMap[companyUID],
              route: `/companies/${companyUID}`,
            },
            { content: selectedLocation && selectedLocation },
          ])}
          <div className="main-content">
            <div className="main-heading-parent">
              <a
                className={`main-heading tab ${
                  activeTab === "employees" ? "active" : ""
                }`}
                href="#employees"
                onClick={() => updateActiveTab("employees")}
              >
                Employees
              </a>
              <a
                className={`main-heading tab ${
                  activeTab === "devices" ? "active" : ""
                }`}
                href="#devices"
                onClick={() => updateActiveTab("devices")}
              >
                Devices
              </a>
              <a
                className={`main-heading tab ${
                  activeTab === "jobs" ? "active" : ""
                }`}
                href="#jobs"
                onClick={() => updateActiveTab("jobs")}
              >
                Groups
              </a>
              {/* <a className={`main-heading tab ${ activeTab === "device-displays" ? "active" : ""}`} href="#device-displays" onClick={ () => updateActiveTab("device-displays")}>Device Displays</a> */}
              <a
                className={`main-heading tab ${
                  activeTab === "managers" ? "active" : ""
                }`}
                href="#managers"
                onClick={() => updateActiveTab("managers")}
              >
                Managers
              </a>
              <div
                className={`main-heading-tab-slider ${activeTab} ${
                  render ? "active" : ""
                }`}
              ></div>
            </div>
            <div className="search-and-action-parent cm">
              <div className="Action-menu-parent left">
                {!searchbarBlacklist.includes(activeTab) && (
                  <div className="Search-parent">
                    <h5 className="field-label">
                      Search {activeTab === "jobs" ? "groups" : activeTab}:
                    </h5>
                    <SearchBar
                      placeholder={null}
                      handleSearch={onFilterData}
                      data={
                        activeTab === "employees"
                          ? employeesData
                          : activeTab === "devices"
                          ? deviceDataSet
                          : activeTab === "jobs"
                          ? jobsData
                          : activeTab === "managers" && managerData
                      }
                      clearFilterData={clearFilterData}
                    />
                  </div>
                )}
                {activeTab == "devices" && (
                  <div className="Select-box-parent">
                    <h5 className="field-label">Hasn't pinged in:</h5>
                    <DropDownSelect
                      options={pingFilterOptions}
                      val={0}
                      onChange={(e) => {
                        console.log(e.target.value)
                        filterByPing(e.target.value)
                      }}
                      nullOption={false}
                      className={"round"}
                    />
                  </div>
                )}
              </div>
              <div className="Action-menu-parent">
                <div className="Action-menu">
                  {render && activeTab === "employees" ? (
                    <>
                      <div className="Action-menu">
                        <Link
                          to={`/locations/${params.locationid}/employees/csv`}
                          className="add-button button secondary upload"
                        >
                          <Icon iconClass={"Upload"} />
                          <p>IMPORT FROM CSV</p>
                        </Link>
                        <Link
                          to={`/locations/${params.locationid}/employees/add`}
                          className="add-button button"
                        >
                          <Icon iconClass={"Circled-plus"} />
                          <p>ADD EMPLOYEE</p>
                        </Link>
                      </div>
                    </>
                  ) : activeTab === "devices" ? (
                    <>
                      <div className="Action-menu">
                        <div
                          className={`add-button button secondary ${
                            deviceDataSet && deviceDataSet.length === 0
                              ? "disabled"
                              : ""
                          }`}
                          onClick={() => setDownloadCSV(true)}
                        >
                          <Icon iconClass={"Download"} />
                          <p>DOWNLOAD CSV</p>
                        </div>
                        <Link
                          to={`/companies/${companyUID}/devices/csv`}
                          className="add-button button upload"
                        >
                          <Icon iconClass={"Upload"} />
                          <p>IMPORT FROM CSV</p>
                        </Link>
                      </div>
                    </>
                  ) : activeTab === "jobs" ? (
                    <>
                      <div className="Action-menu">
                        {/* <Link to={`/locations/${params.locationid}/jobs/csv`} className="add-button button secondary upload">
                        <Icon iconClass={"Upload"} />
                        <p>IMPORT FROM CSV</p>
                      </Link> */}
                        <Link
                          to={`/locations/${params.locationid}/jobs/add`}
                          className="add-button button"
                        >
                          <Icon iconClass={"Circled-plus"} />
                          <p>ADD GROUP</p>
                        </Link>
                      </div>
                    </>
                  ) : (
                    activeTab === "managers" && (
                      <>
                        <div
                          className={`add-button button secondary ${
                            managerData && managerData.length === 0
                              ? "disabled"
                              : ""
                          }`}
                          onClick={() => setDownloadCSV(true)}
                        >
                          <Icon iconClass={"Download"} />
                          <p>DOWNLOAD CSV</p>
                        </div>
                      </>
                    )
                  )}
                </div>
              </div>
            </div>
            <section
              className={`table-container ${
                activeTab === "jobs" ? "Jobs" : ""
              }`}
            >
              {activeTab === "employees" ? (
                <SimpleTable
                  checkbox
                  checkboxKey={"uid"}
                  checkboxActionConfig={[
                    {
                      label: "IR Vibration Status",
                      callback: () => modal_ergo_feedback.show(),
                    },
                  ]}
                  ignoreKeys={[
                    "key",
                    "watched",
                    "uid",
                    "feedbackDuration",
                    "baselineStart",
                    "baselineEnd",
                    "keenID",
                    "jobUID",
                    "groupUID",
                    "active",
                    "vibrationLimit",
                    "shiftEndHour",
                    "wuPosition",
                    "vibrationStatus",
                  ]}
                  order={["name", "device", "group", "irVibrations", "goal"]}
                  header_props={[
                    "selected",
                    "Name",
                    "Device",
                    "Group",
                    "IR Vibrations",
                    "Goal",
                  ]}
                  rows={
                    filteredRows.length > 0
                      ? filteredRows
                      : employeesData.length > 0
                      ? employeesData
                      : [{ noData: "This location has no employees" }]
                  }
                />
              ) : activeTab === "devices" ? (
                <SimpleTable
                  ignoreKeys={[
                    "location_uid",
                    "thing_uid",
                    "battery",
                    //                        "version",
                    "screen_label",
                    "last_ip",
                    "last_push_server_time",
                    "last_ssid",
                    "last_docked",
                    "docked",
                    "locked",
                    "wifi_mac",
                    "bluetooth_mac",
                    "created_at",
                    "deleted_at",
                    "notes",
                  ]}
                  rows={
                    filteredRows.length > 0
                      ? orderColumns(filteredRows, [
                          "sku",
                          "uid",
                          "employee",
                          "version",
                          "status",
                          "last_pinged",
                          "linux_version",
                        ])
                      : deviceDataSet.length > 0
                      ? orderColumns(deviceDataSet, [
                          "sku",
                          "uid",
                          "employee",
                          "version",
                          "status",
                          "last_pinged",
                          "linux_version",
                        ])
                      : [{ noData: "This location has no devices" }]
                  }
                  checkbox={true}
                  checkboxActionConfig={[
                    { callback: editModalHelper, label: "Move Devices" },
                    {
                      callback: sendCommandModalHelper,
                      label: "Send Command",
                    },
                    {
                      callback: statusUpdateModalHelper,
                      label: "Update Status",
                    },
                  ]}
                  category={"devices"}
                  checkboxKey={"uid"}
                />
              ) : activeTab === "jobs" ? (
                <SimpleTable
                  ignoreKeys={["key", "uid", "description", "active"]}
                  rows={
                    filteredRows.length > 0
                      ? filteredRows
                      : jobsData.length > 0
                      ? jobsData
                      : [{ noData: "This location has no jobs" }]
                  }
                />
              ) : activeTab === "managers" ? (
                <SimpleTable
                  ignoreKeys={[
                    "uid",
                    "hashedPassword",
                    "device",
                    "companyUID",
                    "groupUID",
                    "superuser",
                    "role",
                  ]}
                  rows={
                    filteredRows.length > 0
                      ? filteredRows
                      : managerData.length > 0
                      ? managerData
                      : [{ noData: "This location has no managers" }]
                  }
                  editDisabled
                />
              ) : (
                <DeviceDisplays
                  deviceCount={deviceDataSet ? deviceDataSet.length : 0}
                />
              )}
              {editModalToggle && (
                <InventoryEditModal
                  category={"device(s)"}
                  close={setEditModalToggle}
                  action={"Move"}
                  disableSetCompany={false}
                  redirect={(locationUID) => redirect(locationUID)}
                  selectedLocation={selectedLocation}
                />
              )}
              {sendCommandModalToggle && (
                <PendingCommandsModal
                  placeholder="Type commands here..."
                  action="Send Commands"
                  header={"Send Pending Commands"}
                  closeModal={setSendCommandModalToggle}
                />
              )}
              {statusUpdateModalToggle && (
                <DeviceStatusUpdateModal
                  action="Update Status"
                  header={"Update Status"}
                  closeModal={setStatusUpdateModalToggle}
                  locationid={selectedLocation}
                />
              )}
            </section>
          </div>
        </main>
        {downloadCSV && (
          <CSVdownloadModal
            csvData={
              activeTab == "devices"
                ? buildDeviceDataSet(deviceDataSet)
                : dataCategory[activeTab]
            }
            filename={`${activeTab} - ${companyMap[companyUID]}.csv`}
            close={() => setDownloadCSV(false)}
            category={activeTab}
            ignoreKeys={[
              "thing_uid",
              "battery",
              "last_push_wu_time",
              "last_ssid",
              "last_docked",
              "docked",
              "locked",
              "wifi_mac",
              "bluetooth_mac",
              "created_at",
              "deleted_at",
            ]}
          />
        )}
      </div>
    )
  }

  return (
    <>
      <ModalErgoFeedback {...modal_ergo_feedback} dispatch={dispatch} />
      {render ? (
        table()
      ) : (
        <Loading
          background={"var(--color-background)"}
          positionRelative={false}
          height={"100vh"}
        />
      )}
    </>
  )
}
