import React, { useEffect, useRef, useState } from "react"

import { Icon } from "../../ui"

export function ModalModern({
  closeModal,
  promptMessage,
  cancelLabel = "Cancel",
  actionLabel = "Add Action Here",
  header,
  subHeader,
  children,
  callback,
  id,
  validValueFlag = false,
}) {
  const _modalContainer = useRef()
  const _modal = useRef()
  const [responsePending, setResponsePending] = useState()

  const handleButtonClick = () => {
    callback()
    setTimeout(() => {
      closeModalHelper()
    }, 0)
  }

  useEffect(() => {
    setTimeout(() => {
      _modalContainer.current.classList.add("Active")
    }, 100)
    setTimeout(() => {
      _modal.current.classList.add("Active")
    }, 150)
    document.addEventListener("keydown", handleKeydown)
    document.querySelector("html").style.overflow = "hidden"

    return () => {
      document.removeEventListener("keydown", handleKeydown)
      document.querySelector("html").style.overflow = "auto"
    }
  }, [])

  const handleKeydown = (e) => {
    if (e.key === "Enter") {
      handleButtonClick()
    }
  }

  const closeModalHelper = () => {
    _modalContainer.current &&
      _modalContainer.current.classList.remove("Active")
    _modal.current && _modal.current.classList.remove("Active")
    setTimeout(() => {
      closeModal(false)
    }, 300)
  }

  return (
    <div id={id} className="Modal-container" ref={_modalContainer}>
      <div className="Modal" ref={_modal}>
        <div className={"Close-button"} onClick={() => closeModalHelper()}>
          <Icon iconClass={"X-mark"} size={"1.4rem"} />
        </div>
        {header && <h1 className="Modal-header">{header}</h1>}
        <h3 className="Modal-sub-header">{subHeader}</h3>
        <p className="prompt">{promptMessage}</p>
        {children}
        <div className="Button-container">
          <div className="button secondary" onClick={() => closeModalHelper()}>
            {cancelLabel}
          </div>
          <div
            className={`button ${validValueFlag ? "" : "disabled"}`}
            onClick={() => handleButtonClick()}
          >
            {actionLabel}
          </div>
        </div>
      </div>
    </div>
  )
}
