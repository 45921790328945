export const themes = [
  {
    name: "Salmon",
    color: "#fafbfd",
    darkModeColor: "#000",
    primaryColor: "#f6734b",
    secondaryColor: "#f6734b",
    class: "Salmon",
  },
  {
    name: "Torino",
    color: "#fafbfd",
    darkModeColor: "#000",
    primaryColor: "#eb3737",
    secondaryColor: "#eb3737",
    class: "Turino-red",
  },
  {
    name: "Submarine",
    class: "Submarine",
    color: "#000",
    darkModeColor: "#000",
    primaryColor: "#f6c94b",
    secondaryColor: "#f6c94b",
  },
  {
    name: "Tatooine",
    color: "#000",
    darkModeColor: "#000",
    primaryColor: "#ddceae",
    secondaryColor: "#ddceae",
    class: "Tatooine",
  },
  {
    name: "Winterfell",
    class: "Winterfell",
    color: "#000",
    darkModeColor: "#000",
    primaryColor: "#fff",
    secondaryColor: "#d2d2d2",
  },
  {
    name: "Cool Grey",
    class: "Cool-grey",
    color: "#fff",
    darkModeColor: "#fff",
    primaryColor: "#898989",
    secondaryColor: "#898989",
  },
  {
    name: "Vanta",
    color: "#e7e7e7",
    darkModeColor: "#e7e7e7",
    primaryColor: "#161616",
    secondaryColor: "#646464",
    class: "Vanta-black",
  },
  {
    name: "Endor",
    color: "#fafbfd",
    darkModeColor: "#f2f2f2",
    primaryColor: "#425f5f",
    secondaryColor: "#425f5f",
    class: "Endor",
  },
  {
    name: "Karayib",
    class: "Karayib",
    color: "#fff",
    darkModeColor: "#000",
    primaryColor: "#3dc4c4",
    secondaryColor: "#3dc4c4",
  },
  {
    name: "Kinetic Blue",
    color: "#e7e7e7",
    darkModeColor: "#e7e7e7",
    primaryColor: "#3254c3",
    secondaryColor: "#3254c3",
    class: "Kinetic-blue",
  },
  {
    name: "The Biz",
    color: "#fafbfd",
    darkModeColor: "#f2f2f2",
    primaryColor: "#233047",
    secondaryColor: "#304161",
    class: "Biz",
  },
  {
    name: "Taffy",
    class: "Taffy-pink",
    color: "#fff",
    darkModeColor: "#000",
    primaryColor: "#ff69ba",
    secondaryColor: "#ff69ba",
  },
  {
    name: "Purple City",
    color: "#f2f2f2",
    darkModeColor: "#f2f2f2",
    primaryColor: "#6747c7",
    secondaryColor: "#6747c7",
    class: "Purple-city",
  },
  {
    name: "Sweet Potato",
    color: "#fafbfd",
    darkModeColor: "#000",
    primaryColor: "#FF7D21",
    secondaryColor: "#FF7D21",
    class: "Sweet-potato",
  },
]
