// --------------------------- import --------------------------

import { API, TOKEN } from "../../utils/api"

import {
  put,
  takeLatest,
  takeEvery,
  call,
  all,
  select,
} from "redux-saga/effects"

import { LOCATION_CHANGE } from "react-router-redux"

import { DOMAIN } from "../../constants/company"
import { ONLY_BASELINE_DATA, SHOW_BASELINE } from "../../constants/flags"
import { URL_DATE_FORMAT } from "../../constants/time"

import * as Cookies from "js-cookie"

import moment from "moment-timezone"

import { SET_DATE_RANGE, SET_COMPARE_TO_DAYS } from "./filters"

import { omitBy } from "lodash"

import {
  KEEN_FETCH_REQUEST,
  KEEN_HOURLY_FETCH_REQUEST,
  MATH_ENGINE_FETCH_SUCCESS,
  MATH_ENGINE_FETCH_FAILURE,
  MATH_ENGINE_FETCH_HOURLY_SUCCESS,
} from "./keen/actions"

import queryString from "query-string"
import { GET_COMPANY_LIST } from "./admin"
import { getWFMVersion, isSelfServe } from "./user"

// --------------------------- Action constants --------------------------
export const REQUEST_NEW_PASSWORD = "app-state/REQUEST_NEW_PASSWORD"
export const RESET_PASSWORD = "app-state/REQUEST_NEW_PASSWORD"

export const GET_DATA_ATTEMPT = "app-state/GET_DATA_ATTEMPT"
export const GET_DATA_SUCCEEDED = "app-state/GET_DATA_SUCCEEDED"
export const GET_DATA_FAILED = "app-state/GET_DATA_FAILED"
export const GET_LOCATION_SETUP = "app-state/GET_LOCATION_SETUP"
export const GET_LOCATION_SETUP_SUCCESS = "app-state/GET_LOCATION_SETUP_SUCCESS"

export const GET_COMPARE_TO_ATTEMPT = "app-state/GET_COMPARE_TO_ATTEMPT"
export const GET_COMPARE_TO_SUCCEEDED = "app-state/GET_COMPARE_TO_SUCCEEDED"
export const GET_COMPARE_TO_FAILED = "app-state/GET_COMPARE_TO_FAILED"

export const SET_TAB = "app-state/SET_TAB"
export const IMPROVEMENT = "app-state/IMPROVEMENT"
export const VS_BASELINE = "app-state/VS_BASELINE"
export const BENDS_VS_TWISTS = "app-state/BENDS_VS_TWISTS"
export const STEPS = "app-state/STEPS"

// --------------------------- Reducer function --------------------------

let parsed = queryString.parse(window.location.search)

const initialState = {
  tab: VS_BASELINE,
  compare: parsed.compare,
  locationSetup: {},
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    // this state doesn't actually affect anything right now
    case SET_TAB:
      return {
        ...state,
        tab: action.payload.tab,
      }
    case GET_COMPARE_TO_SUCCEEDED:
      return {
        ...state,
        compare: action.compare,
        compareToDays: action.compareToDays,
      }
    case GET_LOCATION_SETUP_SUCCESS:
      return {
        ...state,
        locationSetup: action.data,
      }
    case SET_DATE_RANGE:
      if (action.data.compareType) {
        return {
          ...state,
          compare: action.data.compareType,
        }
      } else {
        return state
      }
    default:
      return state
  }
}

// --------------------------- Action functions --------------------------

export function setTab(tab) {
  return {
    type: SET_TAB,
    payload: {
      tab,
    },
  }
}

export function getCompareTo(company) {
  return {
    type: GET_COMPARE_TO_ATTEMPT,
    payload: {
      companyID: company,
    },
  }
}

/**
 * requestNewPassword dispatches the event to request a new password.
 * The provided email address is sent to the backend, which sends an email
 * to the user allowing them to change their password.
 *
 * @param {string} email - the email
 */
export function requestNewPassword(email) {
  API.requestNewPassword(email)
}

export function getData() {
  console.log("DISP")
  return {
    type: GET_DATA_ATTEMPT,
    payload: {},
  }
}

export function fetchLocationSetup() {
  return {
    type: GET_LOCATION_SETUP,
    payload: {},
  }
}

// --------------------------- Saga functions --------------------------

/**
 * Calls the API.getCompare function to get the time of comparison to make and the number of days to use.
 * Note: This function is called on page load to determine whether the user has an active session. It will fail, by design.
 *
 * @see API.getCompare
 * @public
 */
function* getCompareToWorker(action) {
  try {
    const state = yield select()

    const response = yield call(API.getCompare)

    let compareType = response.compare

    if (
      compareType === "baseline" &&
      (!SHOW_BASELINE || isSelfServe(state.user))
    ) {
      compareType = "none"
    }

    if (!state.appState.compare) {
      yield put({
        type: GET_COMPARE_TO_SUCCEEDED,
        compare: compareType,
        compareToDays: response.days,
      })
    }

    // if no filters were previously set, use the defaults basedon the /compare endpoint
    if (!state.filters.previous.set) {
      // if baseline is not the compare mode, use the 7 most recent days
      // otherwise, use the number of days indicated by math-engine
      const daysToUse = compareType === "baseline" ? response.days : 7
      yield put({
        type: SET_COMPARE_TO_DAYS,
        payload: { compareToDays: daysToUse, compareType: compareType },
      })
      yield put({ type: GET_DATA_ATTEMPT })
    } else {
      yield put({ type: GET_DATA_ATTEMPT })
    }
  } catch (e) {
    Cookies.remove(TOKEN, { domain: DOMAIN })
    yield put({ type: GET_DATA_FAILED, message: e.message })
    yield put({ type: GET_COMPARE_TO_FAILED, message: e.message })
  }
}

/**
 * When a compareTo attempt is made, start the worker
 *
 * @see getCompareToWorker
 * @public
 */
export function* getCompareToSaga() {
  yield takeLatest(GET_COMPARE_TO_ATTEMPT, getCompareToWorker)
}

function* getDataWorker(action) {
  try {
    const state = yield select()

    let parsed = queryString.parse(window.location.search)

    window.mixpanel.register({
      version: require("../../../package.json").version,
      company: state.user.company,
      email: state.appState.email,
      email_id: parsed.email_id,
      from_email: !!parsed.email_id,
    })

    if (state.appState.loggedIn && state.appState.email) {
      if (!window.sessioned) {
        window.mixpanel.track("Session")
        window.sessioned = true
      }
      window.mixpanel.identify(state.appState.email + "|" + state.user.company)
      window.mixpanel.people.set({
        $email: state.appState.email,
        $last_login: new Date(),
        company: state.user.company,
      })
      window.FS.identify(state.appState.email + state.user.company, {
        displayName: state.appState.email,
        company: state.user.company,
      })
    }

    let response = yield call(API.getData)
    response.jobs = omitBy(response.jobs, (j) => {
      return j.name.indexOf("_") === 0
    })

    yield put({ type: GET_DATA_SUCCEEDED, data: response })

    const { user } = yield select()

    if (getWFMVersion(user) > 1) {
      yield put({ type: GET_LOCATION_SETUP })
    }

    const tz = response.location.timezoneName

    const state2 = yield select()

    const minFixed = moment
      .tz(
        [
          state2.filters.min.year(),
          state2.filters.min.month(),
          state2.filters.min.date(),
        ],
        tz
      )
      .startOf("day")
    const maxFixed = moment
      .tz(
        [
          state2.filters.max.year(),
          state2.filters.max.month(),
          state2.filters.max.date(),
        ],
        tz
      )
      .endOf("day")

    yield put({
      type: SET_DATE_RANGE,
      data: {
        min: minFixed,
        max: maxFixed,
        backable: false,
        pMin: moment
          .tz(
            [
              state2.filters.previous.min.year(),
              state2.filters.previous.min.month(),
              state2.filters.previous.min.date(),
            ],
            tz
          )
          .startOf("day"),
        pMax: moment
          .tz(
            [
              state2.filters.previous.max.year(),
              state2.filters.previous.max.month(),
              state2.filters.previous.max.date(),
            ],
            tz
          )
          .endOf("day"),
        compareType: state2.appState.compare,
      },
    })
  } catch (e) {
    // removing the tokens here effectively logs you out
    Cookies.remove(TOKEN, { domain: DOMAIN })
    localStorage.removeItem(TOKEN)

    yield put({ type: GET_DATA_FAILED, message: e.message })
  }
}

export function* getDataSaga() {
  yield takeLatest(GET_DATA_ATTEMPT, getDataWorker)
}

export function* getLocationSetupSaga() {
  yield takeLatest(GET_LOCATION_SETUP, getLocationSetupWorker)
}

function* getLocationSetupWorker(action) {
  let locationSetup = null
  locationSetup = yield call(API.getLocationSetup)

  yield put({ type: GET_LOCATION_SETUP_SUCCESS, data: locationSetup })
}

function* fetchBackendData(action) {
  const state = yield select()

  const start = [
    state.filters.min.year(),
    state.filters.min.month() + 1,
    state.filters.min.date(),
  ].join("/")
  const end = [
    state.filters.max.year(),
    state.filters.max.month() + 1,
    state.filters.max.date(),
  ].join("/")

  const pstart = [
    state.filters.previous.min.year(),
    state.filters.previous.min.month() + 1,
    state.filters.previous.min.date(),
  ].join("/")
  const pend = [
    state.filters.previous.max.year(),
    state.filters.previous.max.month() + 1,
    state.filters.previous.max.date(),
  ].join("/")

  // if the flag to get only baseline data is not set, use the state
  let onlyFeedbackOn = ONLY_BASELINE_DATA
    ? false
    : state.filters.hideFeedbackData

  const [data, olddata] = yield [
    // Getting the selectedDates data
    call(
      API.getKeenData,
      state.delta.location.uid,
      state.delta.location.timezoneName,
      state.delta.location.shiftLengthHours,
      start,
      end,
      state.filters.selectedJobs,
      onlyFeedbackOn,
      ONLY_BASELINE_DATA
    ),
    // Getting the compareTo data
    call(
      API.getKeenData,
      state.delta.location.uid,
      state.delta.location.timezoneName,
      state.delta.location.shiftLengthHours,
      pstart,
      pend,
      state.filters.selectedJobs,
      onlyFeedbackOn,
      ONLY_BASELINE_DATA,
      state.appState.compare
    ),
  ]

  olddata.employees = olddata.employees || {}

  if (
    Object.keys(data.employees).length &&
    Object.keys(olddata.employees).length
  ) {
    yield put({
      type: MATH_ENGINE_FETCH_SUCCESS,
      payload: data,
      old: olddata,
      delta: state.delta,
    })
  } else if (Object.keys(data.employees).length) {
    yield put({
      type: MATH_ENGINE_FETCH_SUCCESS,
      payload: data,
      old: olddata,
      delta: state.delta,
    })
  } else if (Object.keys(olddata.employees).length) {
    yield put({
      type: MATH_ENGINE_FETCH_SUCCESS,
      payload: data,
      old: olddata,
      delta: state.delta,
    })
  } else {
    yield put({
      type: MATH_ENGINE_FETCH_FAILURE,
      payload: "No Data",
    })
  }
}

function* fetchHourlyBackendData(action) {
  const state = yield select()

  const start = [
    state.filters.min.year(),
    state.filters.min.month() + 1,
    state.filters.min.date(),
  ].join("/")
  const end = [
    state.filters.max.year(),
    state.filters.max.month() + 1,
    state.filters.max.date(),
  ].join("/")

  const pstart = [
    state.filters.previous.min.year(),
    state.filters.previous.min.month() + 1,
    state.filters.previous.min.date(),
  ].join("/")
  const pend = [
    state.filters.previous.max.year(),
    state.filters.previous.max.month() + 1,
    state.filters.previous.max.date(),
  ].join("/")

  const [data, olddata] = yield [
    call(
      API.getHourlyKeenData,
      state.delta.location.uid,
      state.delta.location.timezoneName,
      state.delta.location.shiftLengthHours,
      start,
      end
    ),
    call(
      API.getHourlyKeenData,
      state.delta.location.uid,
      state.delta.location.timezoneName,
      state.delta.location.shiftLengthHours,
      pstart,
      pend
    ),
  ]

  if (
    Object.keys(data.just_hourly).length &&
    Object.keys(olddata.just_hourly).length
  ) {
    yield put({
      type: MATH_ENGINE_FETCH_HOURLY_SUCCESS,
      payload: data,
      old: olddata,
      delta: state.delta,
    })
  } else if (Object.keys(data.just_hourly).length) {
    yield put({
      type: MATH_ENGINE_FETCH_HOURLY_SUCCESS,
      payload: data,
      old: olddata,
      delta: state.delta,
    })
  } else {
    yield put({
      type: MATH_ENGINE_FETCH_HOURLY_SUCCESS,
      payload: "No Data",
    })
  }
}

export function* keenFetchSaga() {
  yield takeLatest(KEEN_FETCH_REQUEST, fetchBackendData)
}

export function* keenHourlyFetchSaga() {
  yield takeLatest(KEEN_HOURLY_FETCH_REQUEST, fetchHourlyBackendData)
}

function* scrollOnKeen(action) {
  if (!window.noAutoScroll) {
    const state = yield select()

    const { hash } = state.routing.locationBeforeTransitions

    const $ = document.getElementById(hash.replace(/#/g, ""))

    if ($) {
      const y = $.offsetTop
      window.document.documentElement.scrollTop = y - 120
    }

    window.noAutoScroll = true
  }
}

export function* keenSaga() {
  yield takeLatest(MATH_ENGINE_FETCH_SUCCESS, scrollOnKeen)
}

function* updateUrlQueryFromStateString() {
  const state = yield select()
  const token = Cookies.get(TOKEN)

  // DO NOT update the url if the user is not logged in
  // we skip updating the URL so that the login flow will properly respect the getCompare API call
  // existing query string will still take precedence, however (i.e. this only works if the query string is not already set)
  if (!token) {
    return
  }

  updateUrl({
    data: {
      min: state.filters.min,
      max: state.filters.max,
      pMin: state.filters.previous.min,
      pMax: state.filters.previous.max,
      compareType: state.appState.compare,
    },
  })
}

function updateUrl(action) {
  // only update the URL on explore page
  if (window.location.href.indexOf("explore") < 0) {
    return
  }

  let parsed = queryString.parse(window.location.search)

  let compareType = action.data.compareType

  if (action.data.min && action.data.max) {
    const s = action.data.min.format(URL_DATE_FORMAT)
    const e = action.data.max.format(URL_DATE_FORMAT)

    parsed.display = `${s}-${e}`
  }

  // default the compare type to baseline if using a 1970 date and compare to none is not set
  if (
    compareType !== "none" &&
    compareType !== "baseline" &&
    (action.data.pMax.year() < 1971 || action.data.pMin.year() < 1971)
  ) {
    compareType = "baseline"
  }

  if (compareType && compareType !== "date-range") {
    parsed.compare = compareType || "none"
  } else if (action.data.pMax && action.data.pMin) {
    if (action.data.pMax.year() < 1971 || action.data.pMin.year() < 1971) {
      parsed.compare = "baseline"
    } else {
      const s = action.data.pMin.format(URL_DATE_FORMAT)
      const e = action.data.pMax.format(URL_DATE_FORMAT)
      parsed.compare = `${s}-${e}`
    }
  }

  window.history.replaceState(
    {},
    "",
    `${window.location.pathname}?${queryString
      .stringify(parsed)
      .replace(/%2F/g, "/")}${window.location.hash}`
  )
}

function* updateUrlQueryString(action) {
  yield updateUrl(action)
}

export function* dateChangeSaga() {
  yield takeLatest(SET_DATE_RANGE, updateUrlQueryString)
}

export function* urlChangeSaga() {
  yield takeLatest(LOCATION_CHANGE, updateUrlQueryFromStateString)
}
