import React, { createContext } from 'react'

const SidePanelContext = createContext()

export default SidePanelContext


export function SidePanelProvider({ children }) {

  return (
    <>
      {children}
    </>
  )
}