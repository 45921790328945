import React from "react"
import styled from "styled-components"

export const Table = styled.div`
  width: 100%;
  border-radius: 6px;
  background: white;
  overflow: hidden;
  max-width: rem;
`
export const TableInner = styled.table`
  width: 100%;
`
export const TableHead = styled.thead`
  border-bottom: 2px solid #dfe1ea;
`
export const TableBody = styled.tbody``
export const TableHeader = styled.th`
  font-size: 0.8rem;
  text-align: left;
  color: #7e818e;
  height: 40px;
  font-weight: 500;
  vertical-align: middle;
  display: ${(props) => {
    if (props.right) {
      return "flex"
    }
    return "table-cell"
  }};
  align-items: center;
  justify-content: flex-end;
`
export const TableCell = styled.td`
  font-weight: 500;
  height: 28px;
  font-size: 0.8rem;
  color: #32343e;
  vertical-align: middle;
  font-family: "MikroMedium";
  padding-left: ${(props) => {
    if (props["pl-l"]) return "5rem"
    if (props["pl-m"]) return "2.5rem"
    return 0
  }};
  text-align: ${(props) => {
    return props.right ? "right" : "left"
  }};
`
export const TableRow = styled.tr`
  border-bottom: 1px solid #dfe1ea;
  height: 3rem;
`

export const SortIcon = styled.img`
  margin-left: 0.5rem;
`

const TableHeaderContent = styled.div`
  display: flex;
  justify-content: flex;
  cursor: pointer;
`
