export const ignoreKeys = {
  device: [
    "id",
    "ExecutedCommands",
    "vibration",
    "thingUID",
    "battery",
    "key",
    "lastSSID",
    "docked",
    "lastDocked",
    "locked",
    "edison",
    "last_docked",
    "lastPushServerTime",
    "PendingCommands",
    "status",
    "lastIP",
    "lastPushWUTime",
    "locationUID",
  ],
}
