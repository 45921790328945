import React from "react"
import styled from "styled-components"
import { Theme } from "./theme"

const css = `
  height: 2.5rem;
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  border: 1px solid ${Theme.colors.gray6} !important;
  cursor: pointer;
  width: 100%;
  cursor: text;
  font-weight: 600;
  font-size: 0.875rem;
  color: ${Theme.colors.gray1};
  border-radius: 0;
  min-width: auto;
`

export const FormInput = styled.input`
  ${css};
  ${(props) => props.css};
  box-shadow: none;
`

export const FormInputSelect = styled.input`
  ${css}
  cursor: pointer;
  box-shadow: none;
`

export const FormInputDiv = styled.div`
  ${css}
  cursor:pointer;
  box-shadow: inset 0 1px 5px 1px #eaeaea;
  border: none;
  justify-content: flex-start;
  box-shadow: none;
  ${(props) => props.css}
`

export const MultiSelectValue = styled.div`
  background: ${Theme.colors.gray6};
  color: ${Theme.colors.gray1};
  padding: 4px 4px 4px 4px;
  margin: 0.25rem;
  font-size: 0.95rem;
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  position: relative;
  max-width: 20rem;
`

export const FormLabel = styled.p`
  color: #7e818e;
  font-size: 0.825rem;
  font-weight: 600;
  margin-bottom: 5px;
  font-family: "MikroMedium";
`

export const FormInputClear = styled.input`
  ${css}

  box-shadow: none;
  border: none !important;
`

export const FormBackground = styled.div`
  color: ${Theme.colors.gray4};
  background: ${Theme.colors.gray8};
  display: flex;
  padding: 0.5rem;
  align-items: center;
  min-height: 2.375rem;
`
