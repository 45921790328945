import styled, { css } from "styled-components"

export const Button2 = styled.div`
  white-space: nowrap;
  border-radius: 2px;
  text-decoration: none;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  cursor: ${(props) => (props.onClick ? "pointer" : "normal")};
  user-select: none;
  padding: 11px 20px 10px 20px;

  transition: 300ms background cubic-bezier(0.03, 0.7, 0.31, 1) 0s;

  margin-top: ${props => props.margin_top || "0px"};
  font-size: 14px;
  
  color: #ffffff;
  background-color: #6747c7;
  ${(props) => 
    props.variant == "danger" && 
    `background-color: #ee6b6b;`
  }

  border-radius: 6px;


  ${(props) =>
    props.disabled &&
    `
  cursor: not-allowed;
  opacity: 0.5;
  pointer-events: none;
  `}


  &:active {
    transform: translate3d(0, 2px, 0);
  }
`