/* eslint-disable */

import COMPANY from "./company"

// Flag infrastructure
// precedence order is query param -> cli env var -> hardcoded default
const getFlag = (name, defaultOn) => {
  let flagVal = !!defaultOn

  const flagRegex = new RegExp(`flag_${name.toLowerCase()}=([^&]+)`, "i")
  const falseRegex = /(false|0|FALSE)/
  const trueRegex = /(true|1|TRUE)/
  const cliFlagName = `REACT_APP_FLAG_${name.toUpperCase()}`
  const cliFlagValue = process.env[cliFlagName]

  if (!!cliFlagValue && falseRegex.test(cliFlagValue)) {
    flagVal = false
  } else if (!!cliFlagValue && trueRegex.test(cliFlagValue)) {
    flagVal = true
  }

  const matches = flagRegex.exec(window.location.href)

  if (matches && matches[1]) {
    if (falseRegex.exec(matches[1])) {
      flagVal = false
    } else if (trueRegex.exec(matches[1])) {
      flagVal = true
    }
  }

  return flagVal
}

export const HOURLY_CHART_ENABLED = getFlag("hourly_chart", true)
export const STEPS_ENABLED = getFlag("steps_enabled", true)
export const NEW_JOB_PERFORMANCE = getFlag("new_job_performance", false)
export const ONLY_BASELINE_DATA = getFlag("only_baseline_data", false)
export const NEW_CALENDAR_PICKER = getFlag(
  "new_calendar_picker",
  COMPANY.indexOf("amazonshakopee-") === 0 ||
    COMPANY.indexOf("amazondenver3_") === 0 ||
    COMPANY.indexOf("hd4") === 0
)
export const RS_MATH_ENGINE = getFlag("rs_math_engine", false)
export const IS_STAGING_OR_RS_MATH_ENGINE = getFlag("staging", true)
export const USE_ISO_WEEK = getFlag(
  "use_iso_week",
  IS_STAGING_OR_RS_MATH_ENGINE
)
export const SHOW_BASELINE = getFlag(
  "show_baseline",
  COMPANY.indexOf("pgwtrueblue") !== 0 &&
    COMPANY.indexOf("solarturbines2") !== 0
)
export const SHOW_NO_PARTICIPATION = getFlag("show_no_participation", true)
export const ALLOW_HIDE_FEEDBACK_DATA = getFlag(
  "allow_hide_feedback_data",
  COMPANY.indexOf("amazonmke1") > -1
)
export const ALLOW_EDIT_JOB = getFlag("allow_edit_job", false)
export const TABLEAU = getFlag("tableau", false)
