import { themes } from "./theme-preview/themes"

export const setTheme = () => {
  const currentTheme = localStorage.getItem("currentTheme")
  const theme = themes.filter((i) => {
    if (currentTheme === i.class) {
      return i
    }
  })
  if (localStorage.getItem("currentTheme")) {
    document.documentElement.style.setProperty(
      "--color-primary",
      theme[0].primaryColor
    )
    document.documentElement.style.setProperty(
      "--color-secondary",
      theme[0].secondaryColor
    )
  }
  if (localStorage.getItem("darkMode") === "false") {
    document.documentElement.style.setProperty(
      "--color-primary-text",
      theme[0].color
    )
  } else {
    document.querySelector("html").classList.add("dark")
    document.documentElement.style.setProperty(
      "--color-primary-text",
      theme[0].darkModeColor
    )
  }
}

export const setCursor = () => {
  if (localStorage.getItem("baby-yoda")) {
    if (localStorage.getItem("baby-yoda") === "true") {
      document.querySelector("*").classList.add("baby-yoda")
    }
  }
}
