import { Metric } from "./metric"
import { Rank } from "./rank"

class Store {
  constructor() {
    this.employees = new Map()
    this.timeframes = new Map()
    this.jobs = new Map()

    this.metric = new Metric()
    this.metricEmployees = new Map()
    this.metricTimeframes = new Map()
    this.metricJobs = new Map()
    this.metricJobTimeframes = new Map()
    this.metricEmployeeTimeframes = new Map()

    this.rank = new Rank()
    this.rankTimeframes = new Map()
    this.rankJobs = new Map()
    this.rankJobTimeframes = new Map()
  }

  // Rank employees for every timeframe
  computeRankTimeframe() {
    // This map is created to store, for each timeframe (key), an unsorted Set of active employees (value)
    const tmpRankPerTimeframe = new Map()
    this.metricTimeframes.forEach((metricTimeframe) => {
      const { timeframeKey } = metricTimeframe
      if (metricTimeframe.activeTimeSeconds > 0) {
        tmpRankPerTimeframe.set(timeframeKey, new Set())
      }
    })

    // Now for each timeframe of the previous map, add the active employees
    this.metricEmployeeTimeframes.forEach((metricEmployeeTimeframe) => {
      const { timeframeKey } = metricEmployeeTimeframe
      if (metricEmployeeTimeframe.activeTimeSeconds > 0) {
        tmpRankPerTimeframe.get(timeframeKey).add(metricEmployeeTimeframe)
      }
    })

    // And finally for each timeframe, rank the employees
    tmpRankPerTimeframe.forEach((employees, key) => {
      this.rankTimeframes.set(key, new Rank(employees))
    })
  }

  // Rank employees over the whole period
  computeRankGlobal() {
    this.rank = new Rank(this.metricEmployees.values())
  }

  // Compute all the ranks
  computeRank() {
    this.computeRankGlobal()
    this.computeRankTimeframe()
  }
}

export { Store }
