const splits = document.domain.split(".")

//const company = splits[0] === 'dashboard' ? 'dashboard' : splits[0]
const company = "dashboard"

export const DOMAIN = splits.slice(1).join(".")

export const WORKFORCE_MANAGER_ON = true

export const IS_COACH = splits[0] === "coach"

export const LANG =
  company === "amazonshakopee-newhires" ? "En/US.AMZN" : "En/US"

export default company
