export function removeDuplicateObjectsFromArray(arr, keyOfUniqueVals) {
  const seenVals = new Set()
  const uniqueObjs = []

  arr.filter((l) => {
    if (seenVals.has(l[keyOfUniqueVals])) {return}
    else {
      seenVals.add(l[keyOfUniqueVals])
      uniqueObjs.push(l)
    }
  })
  return uniqueObjs
}
