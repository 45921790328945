import "../styles.css"

import React from "react"

/**
 * A Heading
 */
export class Header extends React.Component {
  render() {
    const { heading } = this.props

    return <div className="header">{heading}</div>
  }
}
