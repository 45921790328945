import React from "react"
import { useParams } from "react-router-dom"
import Iframe from "react-iframe"

function DeviceDisplays({ deviceCount }) {
  const params = useParams()

  return (
    <>
      {deviceCount > 0 ? (
        <Iframe
          url={`http://backend.wearkinetic.com/v2/screens/worker-rewards?location=${params.locationid}`}
          id="Device-Displays-Iframe"
          className="myClassname"
          display="initial"
          position="relative"
        />
      ) : (
        <div id="Device-Displays-Iframe">
          <p className="myClassname">No Devices</p>
        </div>
      )}
    </>
  )
}

export default DeviceDisplays
