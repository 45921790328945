import moment from "moment-timezone"
import queryString from "query-string"

import { DefaultTimezone } from "../../constants/timezone"
import { URL_DATE_FORMAT } from "../../constants/time"

import { ALLOW_HIDE_FEEDBACK_DATA } from "../../constants/flags"

import { KEEN_FETCH_SUCCESS } from "./keen/actions"

import { GET_DATA_SUCCEEDED } from "./app-state"

import { API } from "./../../utils/api"

// --------------------------- Action constants --------------------------
export const SET_DATE_RANGE = "filters/SET_DATE_RANGE"
export const TOGGLE_JOB = "filters/SET_JOB"
export const TOGGLE_FEEDBACK_DATA = "filters/TOGGLE_FEEDBACK_DATA"
export const START_PRINTING = "filters/START_PRINTING"
export const END_PRINTING = "filters/END_PRINTING"
export const SET_SHOWN_WORKER = "filters/SET_SHOWN_WORKER"
export const SET_WORKER_SELECTION = "filters/SET_WORKER_SELECTION"
export const START_WILL_PRINT = "filters/START_WILL_PRINT"
export const END_WILL_PRINT = "filters/END_WILL_PRINT"
export const SET_COMPARE_TO_DAYS = "filters/SET_COMPARE_TO_DAYS"

export const SET_WFM_JOB_SORT = "filters/SET_WFM_JOB_SORT"
export const SET_WFM_EMPLOYEE_SORT = "filters/SET_WFM_EMPLOYEE_SORT"

// Get initial state from url if it exists
let parsed = queryString.parse(window.location.search)
let compareType = parsed.compare || "none"

// Default values, these are overidden if there are values from the url
let min = moment().tz(DefaultTimezone).startOf("day").add(-7, "days")
let max = moment().tz(DefaultTimezone).endOf("day").add(-1, "days")
let pMin = moment("1970-01-01")
let pMax = moment("1970-01-01")

// Is there a "display" date in the url, if so use it
if (parsed.display) {
  const [start, end] = parsed.display.split("-")

  min = moment(start, URL_DATE_FORMAT)
  max = moment(end, URL_DATE_FORMAT)
}

// Is there a "compare" date in the url, if so use it
// If it is only `baseline` or `none` just skip this step and use 1970
if (!(compareType === "baseline" || compareType === "none")) {
  const [start, end] = compareType.split("-")

  pMin = moment(start, URL_DATE_FORMAT)
  pMax = moment(end, URL_DATE_FORMAT)
}

// --------------------------- Reducer function --------------------------
const initialState = {
  prevMin: null,
  prevMax: null,
  printing: false,
  willPrint: false,
  min: min,
  max: max,
  previous: {
    set: parsed.display || parsed.compare,
    min: pMin,
    max: pMax,
  },
  duration:
    moment().tz(DefaultTimezone).endOf("week").startOf("day") -
    moment().tz(DefaultTimezone).startOf("week"),
  selectedJobs: [],
  selectedWorkers: [],
  prevPeriods: [],
  shownWorker: undefined,
  hideFeedbackData: ALLOW_HIDE_FEEDBACK_DATA || false,
  compareToDays: 0,
  compareType: compareType,
  wfmJobSortDir: "a-z",
  wfmEmployeeSortDir: "a-z",
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_SHOWN_WORKER:
      return {
        ...state,
        shownWorker: action.worker,
      }
    case SET_WORKER_SELECTION:
      return {
        ...state,
        selectedWorkers: action.workers,
      }
    case KEEN_FETCH_SUCCESS:
      if (!action.result) {
        return state
      }

      const minFixed = moment.tz(action.result[4].min, action.payload.tz)
      const maxFixed = moment.tz(action.result[4].max, action.payload.tz)

      let { previous } = state

      return {
        ...state,
        min: minFixed,
        max: maxFixed,
        previous,
      }

    case GET_DATA_SUCCEEDED:
      return {
        ...state,
        selectedJobs: Object.keys(action.data.jobs),
      }

    case SET_DATE_RANGE:
      let { min, max, backable, pMin, pMax, compareType } = action.data

      if (min === null) {
        min = state.min
      }

      if (max === null) {
        max = state.max
      }

      let duration = max.valueOf() - min.valueOf()
      let prevDuration = 0
      let prevPeriods = [...state.prevPeriods]
      if (state.prevMin && state.prevMax)
        {prevDuration = state.prevMax.valueOf() - state.prevMin.valueOf()}

      if (duration < 0) {return state}

      if (duration === 0) {
        max = max.endOf("day")
        min = min.startOf("day")
        duration = max.valueOf() - min.valueOf()
      }

      if (backable) {
        if (
          prevPeriods.length > 0 &&
          min === prevPeriods[0].min &&
          max === prevPeriods[0].max
        ) {
          prevPeriods.shift()
        } else {
          if (
            (prevDuration > 0 && duration < prevDuration) ||
            prevDuration === 0
          ) {
            prevPeriods = [{ min: state.min, max: state.max }, ...prevPeriods]
          }
        }
      } else {
        prevPeriods = []
      }

      let prevMin = pMin || state.previous.min
      let prevMax = pMax || state.previous.max

      return {
        ...state,
        prevMin: backable && prevPeriods.length > 0 ? prevPeriods[0].min : null,
        prevMax: backable && prevPeriods.length > 0 ? prevPeriods[0].max : null,
        prevPeriods,
        compareType: compareType,
        min,
        max,
        duration,
        previous: {
          set: !!(prevMin && prevMax) || state.previous.set,
          min: prevMin,
          max: prevMax,
        },
      }
    case TOGGLE_JOB:
      const index = state.selectedJobs.indexOf(action.data.jobId)
      if (index === -1) {
        return {
          ...state,
          selectedJobs: [...state.selectedJobs, action.data.jobId],
        }
      }
      return {
        ...state,
        selectedJobs: [
          ...state.selectedJobs.slice(0, index),
          ...state.selectedJobs.slice(index + 1),
        ],
      }
    case TOGGLE_FEEDBACK_DATA:
      const { toggle } = action.data

      return {
        ...state,
        hideFeedbackData: toggle,
      }

    case START_PRINTING:
      return {
        ...state,
        printing: true,
      }

    case END_PRINTING:
      return {
        ...state,
        printing: false,
      }

    case START_WILL_PRINT:
      return {
        ...state,
        willPrint: true,
      }

    case END_WILL_PRINT:
      return {
        ...state,
        willPrint: false,
      }
    case SET_COMPARE_TO_DAYS:
      return {
        ...state,
        min: moment()
          .tz(DefaultTimezone)
          .startOf("day")
          .add(-action.payload.compareToDays, "days"),
        max: moment().tz(DefaultTimezone).endOf("day").add(-1, "days"),
      }
    case SET_WFM_JOB_SORT:
      const { sort } = action.data
      return {
        ...state,
        wfmJobSortDir: sort,
      }
    default:
      return state
  }
}

// --------------------------- Action functions --------------------------

export function setDateRange(
  min,
  max,
  backable = false,
  pMin,
  pMax,
  compareType
) {
  if (!compareType) {
    console.trace()
    console.warn("compareType not set")
  }

  return {
    type: SET_DATE_RANGE,
    data: {
      min,
      max,
      backable,
      pMin,
      pMax,
      compareType,
    },
  }
}

export function toggleJob(jobId, selected) {
  return {
    type: TOGGLE_JOB,
    data: {
      jobId,
    },
  }
}

export function toggleFeedbackData(toggle) {
  return {
    type: TOGGLE_FEEDBACK_DATA,
    data: {
      toggle,
    },
  }
}

export function setShownWorker(worker) {
  return {
    type: SET_SHOWN_WORKER,
    worker,
  }
}

export function setWorkerSelection(workers) {
  return {
    type: SET_WORKER_SELECTION,
    workers,
  }
}

export function startPrinting() {
  return {
    type: START_PRINTING,
  }
}

export function endPrinting() {
  return {
    type: END_PRINTING,
  }
}

export function startWillPrint() {
  return {
    type: START_WILL_PRINT,
  }
}

export function endWillPrint() {
  return {
    type: END_WILL_PRINT,
  }
}

export function setWFMSortDir(sort) {
  return {
    type: SET_WFM_JOB_SORT,
    data: {
      sort: sort,
    },
  }
}

export function onMixPanelEventTrigger(eventName, user) {
  let currentEmail = user.email
  let d = new Date()
  let currentDate = d.toISOString()
  let mixpanel = window.mixpanel
  mixpanel.people.set({ ID: currentEmail })
  mixpanel.identify(currentEmail)
  mixpanel.track(eventName, {
    name: eventName,
    timestamp: currentDate,
    email: currentEmail,
    location: API.locationUID,
  })
}
