import React, { useEffect, useState, useRef } from "react"
import "./styles.scss"

import { Icon } from "../"

import { copyToClipboard } from "../../../pages/utils"

export function DropDownSelect({
  label,
  name,
  onChange,
  options,
  disabled,
  mode,
  val,
  nullOption = true,
  nullOptionLabel,
  className,
}) {
  let style = {}
  const [value, setValue] = useState(null)
  const [render, setRender] = useState(false)
  const _copyButton = useRef()
  const [copied, setCopied] = useState(false)
  const [valueDisplay, setValueDisplay] = useState()

  useEffect(() => {
    if (val) {
      setValue(val)
    } else if (!val) {
      if (val === null || val === undefined) {
        setValue("null")
      } else {
        setValue("")
      }
    }
  }, [val])

  useEffect(() => {
    if (value && options) {
      setValueDisplay(
        options.filter((i) => {
          return i.value === value
        })[0]?.display
      )
    }
    setRender(true)
  }, [value, options])

  const renderOptions = () => {
    let optionsArr = options.map((o, index) => {
      return (
        <option value={o.value} key={index + 1}>
          {o.display}
        </option>
      )
    })
    nullOption &&
      optionsArr.unshift(
        <option value={"null"} key="null-option">
          {nullOptionLabel ? nullOptionLabel : ""}
        </option>
      )
    return optionsArr
  }

  const onChagneHelper = (e) => {
    onChange(e)
  }

  const rednerSelect = () => {
    let select = (
      <select
        value={value ? value : undefined}
        style={style}
        disabled={disabled}
        className="dropdown-select"
        name={name}
        onChange={(e) => onChagneHelper(e)}
      >
        {options && renderOptions()}
      </select>
    )
    return select
  }

  if (mode === "View") {
    disabled = true
    style = {
      border: "none",
      fontSize: "1.3rem",
      cursor: "default",
    }
  }

  const handleCopy = () => {
    copyToClipboard(valueDisplay)
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 6000)
  }

  return (
    <div
      className={`input-container ${
        mode === "View" ? "View" : ""
      } Dropdown-select-container ${className ? className : ""}`}
    >
      {label && <label>{label}</label>}
      {mode === "View" ? (
        <>
          <input
            disabled={true}
            defaultValue={valueDisplay}
            className={`${disabled ? "disabled-readonly" : ""} ${
              mode === "View" ? "view" : ""
            }`}
          />
          {value !== undefined && value !== null && value !== "" && (
            <div
              ref={_copyButton}
              className={`Input-copy-button button ${
                copied ? "Copied" : "Copy"
              }`}
              onClick={() => handleCopy()}
            >
              <p>
                {copied ? (
                  <Icon iconClass="Circled-checkmark" />
                ) : (
                  <Icon iconClass="Copy" />
                )}
              </p>
            </div>
          )}
        </>
      ) : (
        <div className={`input-inner ${disabled ? "disabled" : ""}`}>
          {render && rednerSelect()}
          {!disabled && <Icon iconClass={"Chevron-down"} />}
        </div>
      )}
    </div>
  )
}
