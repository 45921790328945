/*
Implements the default metrics we care about for now
we'll aggregate them in different ways in the following classes
*/
class DefaultMetric {
  constructor() {
    this.nLifts = 0
    this.steps = 0
    this.participation = 0
    this.nTwists = 0
    this.nBends = 0
    this.activeTimeSeconds = 0
    this.inactiveTimeSeconds = 0
    this._metric = 0
  }

  addLifts(n) {
    this.nLifts += n
  }

  addSteps(n) {
    this.steps += n
  }

  addParticipation(n) {
    this.participation += n
  }

  addTwists(n) {
    this.nTwists += n
  }

  addBends(n) {
    this.nBends += n
  }

  addActiveTime(t) {
    this.activeTimeSeconds += t
  }

  addInactiveTime(t) {
    this.inactiveTimeSeconds += t
  }

  add(metricType, value) {
    switch (metricType) {
      case "nTwists":
        this.addTwists(value)
        this.addLifts(value)
        break
      case "nBends":
        this.addBends(value)
        this.addLifts(value)
        break
      case "nLifts":
        this.addLifts(value)
        break
      case "activeTime":
        this.addActiveTime(value)
        break
      case "inactiveTime":
        this.addInactiveTime(value)
        break
      default:
        console.warn(
          `Improper metric type ${metricType} provided to add() method.`
        )
    }
  }

  static transformActiveTimeSeconds(t) {
    if (t === 0) {
      return 0.0
    } else if (t <= 600) {
      return 0.0
    } else if (t <= 3600) {
      return 3600
    } else {
      return t
    }
  }

  activeDuringPeriod() {
    return this.activeTimeSeconds > 600
  }

  /* Gets the number of high risk lifts per hour */
  getMetric() {
    if (this._overwriteValue) {
      return this._overwriteValue
    }

    const at = DefaultMetric.transformActiveTimeSeconds(this.activeTimeSeconds)
    // No active time, then 0 hourly rate by default
    if (at === 0) {
      return 0.0
      // If the person has not worked an hour, we don't want to inflate the #lifts, so we'll just take the raw number, not divided by active time
    } else {return this.nLifts / (at / 3600)}
  }

  metric() {
    this._metric = this.getMetric()
    return this._metric
  }

  // Computes the percent change between this and a previous Metric
  getPercentChange(prevMetric) {
    if (
      !prevMetric ||
      (this.activeTimeSeconds === 0) | (prevMetric.activeTimeSeconds === 0)
    ) {
      return false
    }

    const prevMetricValue = prevMetric.metric()

    if (prevMetricValue === 0) {
      return false
    }

    return ((this.metric() - prevMetricValue) / prevMetricValue) * 100
  }

  isActive() {
    return this.activeTimeSeconds > 0
  }
}

/*
Metric aggregated for the entire dataset
*/
class Metric extends DefaultMetric {
  constructor() {
    super()
    this.key = Metric.buildKey()
  }

  static buildKey() {
    return "metric"
  }
}

/*
MetricEmployee stores the aggregated metrics for an employee over the entire period
*/
class MetricEmployee extends DefaultMetric {
  /* Uses the employee's database id in the key */
  constructor(employeeDBId) {
    super()
    this.employeeDBId = employeeDBId
    this.key = MetricEmployee.buildKey(employeeDBId)
  }

  /* Build a unique key if stored in a graph (or any key-value iterable) */
  static buildKey(employeeDBId) {
    return `metricemployee.${employeeDBId}`
  }

  augment(deltaEmployees, deltaJobs, deltaDevices, prevStoreMetricEmployees) {
    const liftsRate = this.metric()
    this.liftsRate = liftsRate

    // Get the percent change
    const prevMetricEmployee = prevStoreMetricEmployees.get(this.key)
    const percentChange = this.getPercentChange(prevMetricEmployee)
    this.percentChange = percentChange

    // Add job and employe's extra info from Delta
    if (deltaEmployees.hasOwnProperty(this.employeeDBId)) {
      const deltaEmployee = deltaEmployees[this.employeeDBId]
      const {
        name: employeeName,
        job: jobDBId,
        watched,
        device,
      } = deltaEmployee
      this.employeeName = employeeName
      this.watched = watched

      if (deltaDevices[device]) {
        this.device = deltaDevices[device].label
      }

      if (deltaJobs.hasOwnProperty(jobDBId)) {
        const { name: jobName } = deltaJobs[jobDBId]
        this.jobDBId = jobDBId
        this.jobName = jobName
      }
    }

    return this
  }
}

/*
MetricTimeframe stores the aggregated metrics for all employees for a given timeframe
*/
class MetricTimeframe extends DefaultMetric {
  /* Uses the timeframe's start and end time in the key */
  constructor(timeframeStart, timeframeEnd, interval) {
    super()
    this.interval = interval
    this.timeframeKey = `timeframe.${timeframeStart}.${timeframeEnd}`
    this.key = MetricTimeframe.buildKey(timeframeStart, timeframeEnd)
  }

  /* Build a unique key if stored in a graph (or any key-value iterable) */
  static buildKey(timeframeStart, timeframeEnd) {
    return `metrictimeframe.${timeframeStart}.${timeframeEnd}`
  }
}

/*
MetricJob stores the aggregated metrics for all employees of a specific job
*/
class MetricJob extends DefaultMetric {
  /* Uses the job's database id in the key */
  constructor(jobDBId, jobName) {
    super()
    this.jobDBId = jobDBId
    this.jobName = jobName
    this.key = MetricJob.buildKey(jobDBId)
  }

  /* Build a unique key if stored in a graph (or any key-value iterable) */
  static buildKey(jobDBId) {
    return `metricjob.${jobDBId}`
  }
}

/*
MetricJobTimeframe stores the aggregated metrics
for all employees of a specific job, given a specific timeframe
*/
class MetricJobTimeframe extends DefaultMetric {
  /* Uses the job's database id in the key */
  constructor(jobDBId, timeframeStart, timeframeEnd, interval) {
    super()
    this.interval = interval
    this.key = MetricJobTimeframe.buildKey(
      jobDBId,
      timeframeStart,
      timeframeEnd
    )
  }

  /* Build a unique key if stored in a graph (or any key-value iterable) */
  static buildKey(jobDBId, timeframeStart, timeframeEnd) {
    return `metricjobtimeframe.${jobDBId}.${timeframeStart}.${timeframeEnd}`
  }
}

/*
MetricEmployeeTimeframe stores all the relevant metric
for a given employee, and a given timeframe
*/
class MetricEmployeeTimeframe extends DefaultMetric {
  /*
  employeeDBId: the database ID of the employee
  timeframeStart: the timeframe's start time
  timeframeEnd: the timeframe's end time
  */
  constructor(employeeDBId, timeframeStart, timeframeEnd, interval) {
    super()
    this.interval = interval
    this.employeeDBId = employeeDBId
    this.timeframeKey = `timeframe.${timeframeStart}.${timeframeEnd}`
    this.key = MetricEmployeeTimeframe.buildKey(
      employeeDBId,
      timeframeStart,
      timeframeEnd
    )
  }

  augment(deltaEmployees, deltaJobs) {
    // Add job and employee's extra info from Delta
    if (deltaEmployees.hasOwnProperty(this.employeeDBId)) {
      const deltaEmployee = deltaEmployees[this.employeeDBId]
      const { name: employeeName, job: jobDBId, watched } = deltaEmployee
      this.employeeName = employeeName
      this.watched = watched

      if (deltaJobs.hasOwnProperty(jobDBId)) {
        const { name: jobName } = deltaJobs[jobDBId]
        this.jobDBId = jobDBId
        this.jobName = jobName
      }
    }
  }

  /* Build a unique key if stored in a graph (or any key-value iterable) */
  static buildKey(employeeDBId, timeframeStart, timeframeEnd) {
    return `metricemployeetimeframe.${employeeDBId}.${timeframeStart}.${timeframeEnd}`
  }
}

/*
Export all the metric combinations
*/
export {
  Metric,
  MetricEmployee,
  MetricTimeframe,
  MetricJob,
  MetricJobTimeframe,
  MetricEmployeeTimeframe,
}
