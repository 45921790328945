import {
  Store,
  MetricEmployee,
  MetricJob,
  MetricEmployeeTimeframe,
  MetricTimeframe,
} from "./dataModel"
import {
  MATH_ENGINE_FETCH_SUCCESS,
  MATH_ENGINE_FETCH_FAILURE,
  MATH_ENGINE_FETCH_HOURLY_SUCCESS,
} from "./actions"

import { GET_DATA_SUCCEEDED } from "../app-state"

import { map, reduce } from "lodash"

import { SET_DATE_RANGE, TOGGLE_JOB } from "../filters"
import { keenRequest } from "./actions"

function getStore(current, delta) {
  const nStore = new Store()

  const overallKey = Object.keys(current.overallNoTimeframes)[0]

  nStore.metric._overwriteValue = current.overallNoTimeframes[overallKey]
    ? current.overallNoTimeframes[overallKey].metrics.RISKY_LIFT_RATE_PER_SHIFT
    : undefined

  if (current.overallNoTimeframes[overallKey]) {
    nStore.metric.activeTimeSeconds =
      current.overallNoTimeframes[overallKey].raw.time_active
  } else {
    nStore.metric.activeTimeSeconds = 0
  }

  nStore.rank._overwriteValueRiskiest = current.overallNoTimeframes[overallKey]
    ? current.highestworker[overallKey].metrics.RISKY_LIFT_RATE_PER_SHIFT
    : undefined

  map(current.employees_overall, (v, k) => {
    const newMetric = new MetricEmployee(k)

    if (delta.employees[v.id]) {
      newMetric.employeeName = delta.employees[v.id].name
    } else {
      newMetric.employeeName = ""
    }

    newMetric._overwriteValue =
      v.timeframes[overallKey].metrics.RISKY_LIFT_RATE_PER_SHIFT
    newMetric.addLifts(v.timeframes[overallKey].raw.lifts)
    newMetric.addBends(v.timeframes[overallKey].raw.bends)
    newMetric.addTwists(v.timeframes[overallKey].raw.twists)
    newMetric.addSteps(v.timeframes[overallKey].raw.steps)
    newMetric.addActiveTime(v.timeframes[overallKey].metrics.ACTIVE_TIME)
    newMetric.addInactiveTime(v.timeframes[overallKey].raw.dailyinactive)
    newMetric.addParticipation(v.timeframes[overallKey].metrics.PARTICIPATION)

    newMetric.participation = v.timeframes[overallKey].raw.participation

    nStore.metricEmployees.set(MetricEmployee.buildKey(k), newMetric)
  })

  map(current.jobs_overall, (v, k) => {
    const newMetric = new MetricJob(k)

    if (!delta.jobs[v.id]) {
      console.log("Missing Job", v.id, v, delta.jobs)
    }

    const newJobName =
      delta.jobs[v.id] || Object.values(delta.jobs).find((x) => x.name === v.id)
    newMetric.jobName = newJobName ? newJobName.name : "undefined"

    newMetric._overwriteValue =
      v.timeframes[overallKey].metrics.RISKY_LIFT_RATE_PER_SHIFT
    newMetric.addLifts(v.timeframes[overallKey].raw.lifts)
    newMetric.addBends(v.timeframes[overallKey].raw.bends)
    newMetric.addTwists(v.timeframes[overallKey].raw.twists)
    newMetric.addSteps(v.timeframes[overallKey].raw.steps)
    newMetric.addActiveTime(v.timeframes[overallKey].metrics.ACTIVE_TIME)
    newMetric.addInactiveTime(v.timeframes[overallKey].raw.dailyinactive)
    newMetric.addParticipation(v.timeframes[overallKey].metrics.PARTICIPATION)

    newMetric.participation = v.timeframes[overallKey].raw.participation

    if (newMetric.jobName.indexOf("_") !== 0 && !!newMetric.jobName) {
      nStore.metricJobs.set(MetricJob.buildKey(k), newMetric)
    }
  })

  return nStore
}

// --------------------------- Reducer function --------------------------
const initialState = {
  inited: false,
  fetching: false,
  loaded: false,
  store: new Store(),
  prevStore: new Store(),
  trendData: [],
  jobLineData: [],
  leaderboardData: [],
  jobLeaderboardData: [],
  hourlyTrendDataLoaded: false,
  hourlyTrendData: [],
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_DATA_SUCCEEDED:
      return {
        ...state,
        fetching: true,
      }
    case SET_DATE_RANGE:
    case TOGGLE_JOB:
      return {
        ...state,
        fetching: false,
        loaded: false,
      }
    case MATH_ENGINE_FETCH_FAILURE:
      return Object.assign({}, initialState, {
        loaded: true,
        fetching: false,
        inited: true,
      })
    case MATH_ENGINE_FETCH_HOURLY_SUCCESS:
      const hourlyTrendData = map(
        action.payload.just_hourly.timeframes,
        (v, k) => {
          return {
            activeTimeSeconds: v.metrics.ACTIVE_TIME,
            avg:
              v.metrics.RISKY_LIFTS_PER_WORKER ||
              v.metrics.RISKY_LIFT_RATE_PER_SHIFT,
            participation: v.metrics.PARTICIPATION,
            best: 0,
            bestEmployees: [],
            nLifts: v.metrics.LIFT_COUNT,
            rankedEmployees: {
              metricEmployees: new Array(v.metrics.PARTICIPATION),
            },
            timeframe: undefined,
            worst: 0,
            worstEmployees: [],
            xAxisLabel: new Date(v.raw.timeframe.start),
          }
        }
      )

      Object.keys(action.old.just_hourly.timeframes).map((v, k) => {
        hourlyTrendData[k].oldAvg =
          action.old.just_hourly.timeframes[v].metrics.RISKY_LIFTS_PER_WORKER
        hourlyTrendData[k].oldParticipation =
          action.old.just_hourly.timeframes[v].metrics.PARTICIPATION
        return true
      })

      return Object.assign({}, state, {
        hourlyTrendData,
        hourlyTrendDataLoaded: true,
      })

    case MATH_ENGINE_FETCH_SUCCESS:
      const curStore = getStore(action.payload, action.delta)
      const pStore = getStore(action.old, action.delta)

      const overallKey = Object.keys(action.payload.overallNoTimeframes)[0]
      const overallKeyPrev = Object.keys(action.old.overallNoTimeframes)[0]

      const newLeaderboardData = map(
        action.payload.employees_overall,
        (v, k) => {
          curStore.employees.set(v.id, {
            dbid: v.id,
            ...action.delta.employees[v.id],
          })

          const prevData = !!(
            action.old.employees_overall[k] &&
            action.old.employees_overall[k].timeframes[overallKeyPrev]
          )

          const lifts =
            v.timeframes[overallKey].metrics.RISKY_LIFT_RATE_PER_SHIFT
          const liftsPrev = prevData
            ? action.old.employees_overall[k].timeframes[overallKeyPrev].metrics
                .RISKY_LIFT_RATE_PER_SHIFT
            : undefined

          return {
            difference: "-3.4",
            differenceRate:
              prevData &&
              `${(((lifts - liftsPrev) / liftsPrev) * 100).toFixed(0)}`,
            key: v.id,
            label: "",
            lifts: lifts,
            liftsPrev: liftsPrev,
            twists:
              v.timeframes[overallKey].raw.twists /
              v.timeframes[overallKey].metrics.PARTICIPATION,
            bends:
              v.timeframes[overallKey].raw.bends /
              v.timeframes[overallKey].metrics.PARTICIPATION,
            raw: v.timeframes[overallKey].raw.lifts,
            rawPrev: 0,
            name: (action.delta.employees[v.id] || {}).name,
            employeeName: (action.delta.employees[v.id] || {}).name,
            watched: false,
            active: v.timeframes[overallKey].metrics.ACTIVE_TIME > 0,
            rank: -1,
            steps: v.timeframes[overallKey].raw.steps,
            participation: v.timeframes[overallKey].metrics.PARTICIPATION,
          }
        }
      ).filter((x) => !!x.name)

      const newTrendData = map(action.payload.overall, (v, k) => {
        return {
          activeTimeSeconds: v.metrics.ACTIVE_TIME,
          avg:
            v.metrics.RISKY_LIFTS_PER_WORKER ||
            v.metrics.RISKY_LIFT_RATE_PER_SHIFT,
          best: 0,
          bestEmployees: [],
          nLifts: v.metrics.LIFT_COUNT,
          rankedEmployees: {
            metricEmployees: new Array(v.metrics.PARTICIPATION),
          },
          timeframe: undefined,
          worst: 0,
          worstEmployees: [],
          xAxisLabel: new Date(v.raw.timeframe.start),
        }
      })

      const newJobLeaderboardData = map(action.payload.jobs_overall, (v, k) => {
        curStore.jobs.set(v.id, { dbid: v.id, ...action.delta.jobs[v.id] })

        const prevData = !!(
          action.old.jobs_overall[k] &&
          action.old.jobs_overall[k].timeframes[overallKeyPrev]
        )

        const lifts = v.timeframes[overallKey].metrics.RISKY_LIFT_RATE_PER_SHIFT
        const liftsPrev = prevData
          ? action.old.jobs_overall[k].timeframes[overallKeyPrev].metrics
              .RISKY_LIFT_RATE_PER_SHIFT
          : 0

        const jobObj =
          action.delta.jobs[v.id] ||
          Object.values(action.delta.jobs).find((x) => x.name === v.id)
        const jobName = jobObj ? jobObj.name : "_missing"

        return {
          difference: "-3.4",
          differenceRate:
            prevData &&
            `${(((lifts - liftsPrev) / liftsPrev) * 100).toFixed(0)}`,
          key: v.id,
          label: "",
          lifts: lifts,
          liftsPrev: liftsPrev,
          twists: v.timeframes[overallKey].raw.twists,
          bends: v.timeframes[overallKey].raw.bends,
          raw: v.timeframes[overallKey].raw.lifts,
          rawPrev: 0,
          name: jobName,
          employeeName: jobName,
          watched: false,
          active: v.timeframes[overallKey].metrics.ACTIVE_TIME > 0,
          rank: -1,
          steps: v.timeframes[overallKey].raw.steps,
          participation: reduce(
            action.delta.employees,
            (carry, emp) => {
              if (
                emp.job === v.id &&
                action.payload.employees_overall[emp.key]
              ) {
                return (
                  carry +
                  action.payload.employees_overall[emp.key].timeframes[
                    overallKey
                  ].metrics.PARTICIPATION
                )
              } else {
                return carry
              }
            },
            0
          ),
        }
      }).filter((x) => x.name.indexOf("_") !== 0)

      map(action.payload.employees, (v, k) => {
        map(v.timeframes, (v, k) => {
          const newMetric = new MetricEmployeeTimeframe(
            v.raw.id,
            v.raw.timeframe.start,
            v.raw.timeframe.end
          )
          const newTimeframe = new MetricTimeframe(
            v.raw.timeframe.start,
            v.raw.timeframe.end
          )

          newMetric._overwriteValue = v.metrics.RISKY_LIFT_RATE_PER_SHIFT

          newMetric.feedback = v.raw.feedback

          newMetric.addLifts(v.raw.lifts)
          newMetric.addBends(v.raw.bends)
          newMetric.addTwists(v.raw.twists)
          newMetric.addActiveTime(v.metrics.ACTIVE_TIME)

          curStore.metricEmployeeTimeframes.set(
            MetricEmployeeTimeframe.buildKey(
              v.raw.id,
              v.raw.timeframe.start,
              v.raw.timeframe.end
            ),
            newMetric
          )
          curStore.metricTimeframes.set(
            MetricTimeframe.buildKey(
              v.raw.timeframe.start,
              v.raw.timeframe.end
            ),
            newTimeframe
          )

          curStore.timeframes.set(
            MetricTimeframe.buildKey(
              v.raw.timeframe.start,
              v.raw.timeframe.end
            ),
            v.raw.timeframe
          )
        })
      })

      return Object.assign({}, state, {
        loaded: true,
        fetching: false,
        inited: true,
        store: curStore,
        prevStore: pStore,
        trendData: newTrendData,
        leaderboardData: newLeaderboardData,
        jobLeaderboardData: newJobLeaderboardData,
      })
    default:
      return state
  }
}

export { keenRequest }
