import React, { useEffect, useRef } from "react"

export function DialogueBox({
  setDialogue,
  setDialogueResponse,
  promptMessage,
  maxWidth = "500px",
  action = "Yes",
  header,
  warning = false,
  warningMessage = "",
  deleteFlag,
  cancelMessage,
  disabled,
}) {
  const _dialogueContainer = useRef()
  const _dialogueBox = useRef()

  const handleClick = (bool) => {
    if (bool) {
      setDialogueResponse(true)
    }
    _dialogueBox.current.classList.remove("Active")
    setTimeout(() => {
      if (_dialogueContainer?.current) {
        _dialogueContainer.current.classList.remove("Active")
      }
    }, 50)
    setTimeout(() => {
      setDialogue(false)
    }, 200)
  }

  useEffect(() => {
    setTimeout(() => {
      _dialogueContainer.current.classList.add("Active")
    }, 100)
    setTimeout(() => {
      _dialogueBox.current.classList.add("Active")
    }, 150)
    document.addEventListener("keydown", handleKeydown)
    document.querySelector("html").style.overflow = "hidden"
    document.addEventListener("mousedown", handleClickOutside)

    return () => {
      document.removeEventListener("keydown", handleKeydown)
      document.querySelector("html").style.overflow = "auto"
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  const handleClickOutside = (e) => {
    if (_dialogueBox.current && !_dialogueBox.current.contains(e.target)) {
      handleClick(false)
    }
  }

  const handleKeydown = (e) => {
    if (e.key === "Enter") {
      handleClick(false)
    }
  }

  return (
    <div className="Dialogue-container" ref={_dialogueContainer}>
      <div
        className="Dialogue-box"
        ref={_dialogueBox}
        style={{ maxWidth: maxWidth }}
      >
        {header && <h3 className="Dialogue-header">{header}</h3>}
        <p className="prompt">
          {Array.isArray(promptMessage) &&
            promptMessage.map((message) => {
              return (
                <>
                  {message}
                  <br />
                  <br />
                </>
              )
            })}
          {!Array.isArray(promptMessage) && <div>{promptMessage}</div>}
        </p>

        {warning && (
          <p className="warning">
            <i className="fas fa-exclamation-triangle"></i>
            {warningMessage}
          </p>
        )}
        <div className="Button-container">
          <div className="button" onClick={() => handleClick(false)}>
            {cancelMessage ? cancelMessage : "Cancel"}
          </div>
          {!disabled && (
            <div
              className={`button ${deleteFlag ? "delete" : ""}`}
              onClick={() => handleClick(true)}
            >
              {action}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
