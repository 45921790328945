import React, { useState } from "react"
import { Button } from "./../index.js"
import { SearchBar } from "../searchbar/index.js"

export function ListBox({
  options,
  title,
  firstKey,
  secondKey,
  callback,
  label = "Continue",
  category = "list",
}) {
  const [selectedItem, setSelectedItem] = useState()
  const [selectedName, setSelectedName] = useState()
  const [filteredRows, setFilteredRows] = useState()

  const handleClick = () => {
    callback(selectedItem, selectedName)
  }

  const onFilterData = (results) => {
    setFilteredRows(results)
  }

  const clearFilterData = () => {
    setFilteredRows()
  }

  const renderOptions = (arr) => {
    return arr.map((l) => (
      <div
        className={l[secondKey] === selectedItem ? "highlight" : ""}
        onClick={() => {
          l[secondKey] === selectedItem
            ? setSelectedItem()
            : setSelectedItem(l[secondKey])
          setSelectedName(l[firstKey])
        }}
        key={l[secondKey]}
      >
        {l[firstKey]}
      </div>
    ))
  }

  return (
    <div className="List-box main-contain narrow">
      <h2 className="List-box-title">{title}</h2>
      <div className="List-box locations-list">
        <SearchBar
          placeholder={`Search ${category}`}
          handleSearch={onFilterData}
          data={options}
          clearFilterData={clearFilterData}
        />
        {filteredRows ? renderOptions(filteredRows) : renderOptions(options)}
      </div>
      <Button
        className={`button ${selectedItem ? "" : "disabled"}`}
        onClick={handleClick}
        disabled={!selectedItem}
      >
        {label}
      </Button>
    </div>
  )
}
