import React, { useState, useEffect, useRef } from "react"
import { useHistory } from "react-router-dom"
import { useSelector } from "react-redux"

import { Loading, BackButton, Icon } from "../../../components/ui"
import { Button, DialogueBox } from "./../index.js"

import { useToastContext } from "../../../hooks"

export function WizardBar({
  redirectRoute,
  apiCall,
  apiArgs,
  disabled,
  promptMessage,
  header,
  category = "Add Category HERE",
  mode,
  setMode,
  backToRoute,
  toastMessage,
  miniMode = false,
  buttons,
  saveActions = [],
}) {
  const { fromPath } = useSelector((state) => ({
    fromPath: state.admin.fromPath,
  }))
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState("")
  const [errorState, setErrorState] = useState(false)
  const [dialogue, setDialogue] = useState(false)
  const [dialogueResponse, setDialogueResponse] = useState()
  let history = useHistory()
  const noView = ["companies", "locations", "managers"]
  const addToastMessage = useToastContext()
  const _miniWizardBar = useRef()

  const handleRequest = () => {
    let apiResponse = apiCall(apiArgs)
    setLoading(true)
    apiResponse
      .then(() => {
        setTimeout(() => {
          setLoading(false)
          if (toastMessage) {
            addToastMessage({
              message: toastMessage,
              status: "success",
              iconClass: "Circled-checkmark",
            })
            history.push(redirectRoute)
          } else {
            history.push(redirectRoute)
          }
        }, 1000)
        for (let action of saveActions) {
          action()
        }
      })
      .catch((e) => {
        console.log(`Saving Error: ${e}`)
        setErrorState(true)
        setMessage("There was an error saving your changes")
      })
  }

  useEffect(() => {
    setMessage("Saving changes")
    if (miniMode) {
      setTimeout(() => {
        _miniWizardBar.current.classList.add("active")
      }, 50)
    }
  }, [])

  const presentPrompt = () => {
    setDialogue(true)
  }

  const closeMiniWizardBar = () => {
    _miniWizardBar.current.classList.remove("active")
    setTimeout(() => {
      setMode("View")
    }, 150)
  }

  useEffect(() => {
    if (dialogue) {
      if (dialogueResponse) {
        handleRequest()
      }
    }
  }, [dialogue, dialogueResponse])

  return (
    <div
      className={`wizard-bar ${miniMode ? "mini-mode" : ""}`}
      ref={_miniWizardBar}
    >
      {miniMode ? (
        <div className="Action-buttons">
          <div
            className="button secondary"
            onClick={() => closeMiniWizardBar()}
          >
            Cancel Changes
          </div>
          <Button onClick={presentPrompt} disabled={disabled}>
            Save Changes
          </Button>
        </div>
      ) : (
        <div className="container">
          <div className="Back-button-parent">
            {!buttons && (
              <BackButton
                label={fromPath === "/" ? "Search" : category}
                backToRoute={fromPath === "/" ? "/" : backToRoute}
              />
            )}
          </div>
          <div className="Action-buttons">
            {buttons && (
              <div className={"Cancel-button"} onClick={() => buttons()}>
                Cancel
              </div>
            )}
            {mode && mode.toLowerCase() === "view" ? (
              <Button onClick={() => setMode("Edit")} disabled={disabled}>
                  Edit
                </Button>
            ) : mode && mode.toLowerCase() === "edit" ? (
              <>
                {category && !noView.includes(category.toLocaleLowerCase()) && (
                  <div
                    className={"Cancel-button"}
                    onClick={() => setMode("View")}
                  >
                    Cancel
                  </div>
                )}
                <Button onClick={presentPrompt} disabled={disabled}>
                  Save Changes
                </Button>
              </>
            ) : (
              mode &&
              mode.toLowerCase() === "add" && (
                <Button onClick={presentPrompt} disabled={disabled}>
                  Save Changes
                </Button>
              )
            )}
          </div>
        </div>
      )}
      {loading && (
        <Loading
          errorState={errorState}
          message={message}
          setLoading={setLoading}
          backdropFilter={"blur(4px)"}
        />
      )}
      {dialogue && (
        <DialogueBox
          setDialogue={setDialogue}
          setDialogueResponse={setDialogueResponse}
          header={header}
          promptMessage={promptMessage}
        />
      )}
    </div>
  )
}
