import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { Helmet } from "react-helmet"

import { setCurrentCompany } from "../../../redux/actions/admin"

import { SimpleTable, SearchBar, Icon, SelectBox } from "../../../components/ui"

import { useBreadCrumbsContext } from "../../../hooks"

export default function InventoryLocationListingPage() {
  const { locations, companyUID, companyMap } = useSelector((state) => ({
    locations: state.admin.locationsList,
    companyUID: state.admin.selectedCompany,
    companyMap: state.admin.companyMap,
  }))
  const [filteredSP, setFilteredSP] = useState([])
  const [filteredRows, setFilteredRows] = useState([])
  const [locationsCopy, setLocationsCopy] = useState([])
  const [activeTab, setActiveTab] = useState()
  const params = useParams()
  const dispatch = useDispatch()
  const [render, setRender] = useState(false)
  const passBreadCrumbs = useBreadCrumbsContext()

  const onFilterData = (results) => {
    setFilteredRows(results)
  }

  const clearFilterData = () => {
    setFilteredRows([])
  }

  useEffect(() => {
    setCurrentCompany(params.companyid, dispatch)
    updateActiveTab()
  }, [])

  useEffect(() => {
    if (locations) {
      const tempLocationsCopy = JSON.parse(JSON.stringify(locations)).map(
        (o) => {
          return {
            name: o.name,
            UID: o.uid,
            settings_package: o.settingsPackage,
            created: o.created_at,
            clickableRow: `/inventory/location/${o.uid}`,
          }
        }
      )
      setLocationsCopy(tempLocationsCopy)
      setRender(true)
    }
  }, [locations])

  const updateActiveTab = (tab) => {
    if (tab) {setActiveTab(tab)}
    else if (window.location.href.split("#")[1])
      {setActiveTab(window.location.href.split("#")[1])}
    else {setActiveTab("locations")}
  }

  useEffect(() => {
    let filtSP = ["All Product Configurations", "None"]
    locations.forEach((l) => {
      if (!filtSP.includes(l.settingsPackage)) {
        if (l.settingsPackage != "") {
          filtSP.push(l.settingsPackage)
        }
      }
    })
    setFilteredSP(filtSP.sort())
  }, [locations])

  const filterSettingsPackages = (e) => {
    if (e === "0") {
      clearFilterData()
    } else if (e === "1") {
      onFilterData(
        locationsCopy.filter((l) => {
          return l.settings_package == undefined || l.settings_package == ""
        })
      )
    } else {
      onFilterData(
        locationsCopy.filter((l) => {
          return l.settings_package == filteredSP[e]
        })
      )
    }
  }

  return (
    <div className="admin wrapper Location-listing-container">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${companyMap ? companyMap[companyUID] : ""}`} • Kinetic</title>
      </Helmet>
      <main className="admin container">
        {passBreadCrumbs([
          { content: <Icon iconClass={"Device"} />, route: "/inventory" },
          { content: companyMap && companyMap[companyUID], route: "" },
        ])}
        <div className="main-content">
          <h1 className="main-heading">Inventory Manager</h1>
          <h2 className="sub-heading">Locations</h2>
          <div className="search-and-action-parent inventory-action">
            <SearchBar
              clearFilterData={clearFilterData}
              placeholder={`Search ${activeTab}`}
              handleSearch={onFilterData}
              data={locationsCopy}
            />
            {activeTab === "locations" && filteredSP.length > 0 && (
              <div className="settings-package-select">
                <SelectBox
                  full
                  initialValue="0"
                  options={filteredSP}
                  onChange={(e) => filterSettingsPackages(e)}
                />
              </div>
            )}
          </div>
          <section className="table-container">
            {render && (
              <>
                <SimpleTable
                  ignoreKeys={[
                    "id",
                    "shiftLengthHours",
                    "address1",
                    "address2",
                    "postal_code",
                    "companyUID",
                    "featureFlags",
                    "proxyServers",
                    "wifiESSID",
                    "wifiPassword",
                    "staticIPs",
                    "dnsServers",
                    "ntpServers",
                    "timezoneName",
                    "settingsPackageUID",
                    "requiredVersion",
                    "province",
                    "country",
                    "city",
                  ]}
                  rows={
                    filteredRows.length > 0
                      ? filteredRows
                      : locationsCopy?.length > 0
                      ? locationsCopy
                      : [{ noData: "This company has no locations" }]
                  }
                />
              </>
            )}
          </section>
        </div>
      </main>
    </div>
  )
}
