import React from "react"
import moment from "moment-timezone"
import { DefaultTimezone } from "../constants/timezone"
import { STEPS } from "../constants/setup-steps-rev2"
import { parseTimeFilters } from "../redux/reducers/keen/timeFilters"

import { API } from "./api"

export function xAxisTickInterval(minDate, maxDate) {
  const diff = daysDiff(minDate, maxDate)
  switch (true) {
    case diff > 180:
      return "monthly"
    case diff > 31 && diff <= 180:
      return "weekly"
    case diff > 2 && diff <= 31:
      return "daily"
    default:
      return "hourly"
  }
}

export async function onMixPanelEventTrigger(
  eventName,
  currentEmail,
  eventType,
  eventValues
) {
  let d = new Date()
  let currentDate = d.toISOString()
  let mixpanel = window.mixpanel
  mixpanel.people.set({ ID: currentEmail })
  mixpanel.identify(currentEmail)

  switch (eventType) {
    case "button":
      mixpanel.track(eventName, {
        name: eventName,
        timestamp: currentDate,
        email: currentEmail,
        location: API.locationUID,
        "page url": window.location.href,
        worker: eventValues.worker,
        "end date": eventValues.endDate,
        "report length": eventValues.reportLength,
        duration: eventValues.duration,
      })
      break
    case "field":
      mixpanel.track(eventName, {
        name: eventName,
        timestamp: currentDate,
        email: currentEmail,
        location: API.locationUID,
        "page url": window.location.href,
        "field name": eventValues.fieldName,
        "field value": eventValues.fieldValue,
      })
      break
    case "filter":
      mixpanel.track(eventName, {
        name: eventName,
        timestamp: currentDate,
        email: currentEmail,
        location: API.locationUID,
        "page url": window.location.href,
        "filter name": eventValues.filterName,
        "filter value": eventValues.filterValue,
      })
      break
    case "table row":
      mixpanel.track(eventName, {
        name: eventName,
        timestamp: currentDate,
        email: currentEmail,
        location: API.locationUID,
        "page url": window.location.href,
        "contact with": eventValues.contactWith,
        "total contacts": eventValues.totalContacts,
        "total duration": eventValues.totalDuration,
        "average duration": eventValues.averageDuration,
        "group title": eventValues.groupTitle,
      })
      break
    case "table cell":
      mixpanel.track(eventName, {
        name: eventName,
        timestamp: currentDate,
        email: currentEmail,
        location: API.locationUID,
        "page url": window.location.href,
        "cell header": eventValues.cellHeader,
        "cell value": eventValues.cellValue,
      })
      break
    default:
      mixpanel.track(eventName, {
        name: eventName,
        timestamp: currentDate,
        email: currentEmail,
        location: API.locationUID,
        "page url": window.location.href,
      })
      break
  }
}

export function daysDiff(minDate, maxDate) {
  const oneDay = 1000 * 60 * 60 * 24
  return Math.round(Math.abs((maxDate - minDate) / oneDay))
}

export function unitFromCadence(cadence) {
  switch (cadence) {
    case "hourly":
      return "hour"
    case "daily":
      return "day"
    case "weekly":
      return "week"
    case "monthly":
      return "month"
  }
}

//A null date from the the backend comes in as 0001-01-01T00:00:00Z (not null)
//This checks to see if the date passed in is after 01-01-2010 giving a high probablility that its an actual date
export function dateStringFromServerIsNotNull(dateString) {
  if (
    moment
      .tz(dateString, "YYYY-MM-DDTHH:mm:ssZ", "America/New_York")
      .isAfter("2010-01-01")
  ) {
    return true
  } else {
    return false
  }
}

export class BaseSmartTable extends React.Component {
  exportData() {
    this.table.handleExportCSV()
  }
}

export function getPreviousPeriod(min, max) {
  let end = moment(min).startOf("date").add(-1, "day")
  let start = moment(end - (max - min)).startOf("date")

  if (end.diff(start, "days") < 5) {
    switch (end.isoWeekday()) {
      case 6:
        end.add(-1, "day")
        start.add(-1, "day")
        break
      case 7:
        end.add(-2, "day")
        start.add(-2, "day")
        break
      default:
    }
    switch (start.isoWeekday()) {
      case 6:
        start.add(-1, "day")
        break
      case 7:
        start.add(-2, "day")
        break
      default:
    }
  }

  return { end: end, start: start }
}

export function getCurrentPeriod(min, max) {
  const start = moment(min).startOf("date")
  const end = moment(max).endOf("date")
  const duration = moment.duration(end.diff(start))
  const difference = duration.asHours()
  return {
    start: start.format(),
    end: end.format(),
    difference,
  }
}

export const isSameDay = (day1, day2) => {
  const m1 = moment(day1)
  const m2 = moment(day2)

  return day1.isSame(day2, "day")
}

export const isDayInRange = (tz) => (day, from, to) => {
  const mday = moment.tz(
    [day.getFullYear(), day.getMonth(), day.getDate(), 12],
    tz
  )

  const mfrom = moment.tz(from, tz).startOf("day")
  const mto = moment.tz(to, tz).endOf("day")

  return mday.isBefore(mto) && mday.isAfter(mfrom)
}

export const literatePrevPeriod = (min, max, previous) => {
  const {
    currentPeriod: keenCurrentPeriod,
    prevPeriod: keenPrevPeriod,
  } = parseTimeFilters(previous.min, previous.max)
  // const prevPeriod = getPreviousPeriod(min, max)
  const prevPeriod = {
    start: moment(keenCurrentPeriod.start).tz(DefaultTimezone),
    end: moment(keenCurrentPeriod.end).tz(DefaultTimezone),
  }

  if (prevPeriod.start.format("YY") === prevPeriod.end.format("YY")) {
    return (
      prevPeriod.start.format("MMM Do") +
      " - " +
      prevPeriod.end.format("MMM Do, YYYY")
    )
  }

  const comparedToPeriod =
    prevPeriod.start.format("MMM Do YY") +
    " - " +
    prevPeriod.end.format("MMM Do YY")

  return comparedToPeriod
}

export const getLabel = (baselineDates) => {
  if (!baselineDates.set) {
    return ""
  }

  return _label(baselineDates.min, baselineDates.max)
}

export function _label(min, max) {
  let selectedPeriodLabel = ""
  let selectedPeriodEndPart = ""

  if (
    moment(min).startOf("day").valueOf() !==
    moment(max).startOf("day").valueOf()
  ) {
    selectedPeriodEndPart = " - " + max.format("MMM DD, YYYY")
  }

  if (min.get("year") === max.get("year")) {
    if (selectedPeriodEndPart === "") {
      selectedPeriodLabel = min.format("dddd DD MMM")
    } else {
      selectedPeriodLabel = min.format("MMM DD") + selectedPeriodEndPart
    }
  } else {
    selectedPeriodLabel = min.format("MMM DD, YYYY") + selectedPeriodEndPart
  }

  if (min.get("year") < 1975) {
    selectedPeriodLabel = "Baseline"
  }

  return selectedPeriodLabel
}

/**
 * A helper function that returns an object with three important program dates:
 * start date, end date, and first email report date.
 *
 * @param {string} date - the first day of the program
 * @param {int} programLength - the number of days the program will last
 * @param {int} reportLag - the number of days before the first report should be sent
 * @param {string} format - the format pattern for the date (default = 'MM/DD/YYYY')
 */
export function calculateProgramDates(
  date,
  programLength = 90,
  reportLag = 7,
  format = "MM/DD/YYYY"
) {
  let dates = {
    startDate: null,
    endDate: null,
    reportDate: null,
  }

  if (!date) {
    return dates
  }

  let dateObj = moment(date)
  let endDateObj = moment(date)

  dates["startDate"] = dateObj.format(format)
  dates["endDate"] = endDateObj.add(programLength, "days").format(format)
  dates["reportDate"] = dateObj.add(reportLag, "days").format(format)

  return dates
}

/**
 * Helper function that returns the URL a user should be redirected to when logging into a
 * self-serve location that has not been fully set up
 *
 * @param {object} locationSetup - the location setup object from the redux store
 */
export function getResumeSetupURL(locationSetup) {
  const v2Steps = STEPS["step"].steps.length

  let step = 1
  let i = 0

  if (
    locationSetup.completed_steps.step !== undefined &&
    locationSetup.completed_steps.step.length < v2Steps
  ) {
    for (i = 0; i < v2Steps; i++) {
      if (locationSetup.completed_steps.step.indexOf(i) < 0) {
        step -= 1
        return "/setup/step/" + step
      }
    }
  }

  return "/setup/step/0"
}

export function secondsToHuman(time) {
  var sec_num = parseInt(time, 10) // don't forget the second param
  let hours = Math.floor(sec_num / 3600)
  var minutes = Math.floor((sec_num - hours * 3600) / 60)
  var seconds = sec_num - hours * 3600 - minutes * 60

  if (hours > 0) {
    if (hours < 10) {
      hours = "0" + hours + "h"
    } else {
      hours = hours + "h"
    }
  } else {
    hours = ""
  }

  if (minutes < 10) {
    minutes = "0" + minutes
  }
  if (seconds < 10) {
    seconds = "0" + seconds
  }
  return hours + " " + minutes + "m " + seconds + "s"
}
