import React, { useEffect, useReducer, useState } from "react"
import { useParams, useHistory } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"

import {
  Form,
  Input,
  Loading,
  DropDownSelect,
  Icon,
  WizardBar,
} from "../../../components/ui"

import { setCurrentLocation, saveEmployee } from "../../../redux/actions/admin"

import { setMode } from "../../utils"

import { useBreadCrumbsContext } from "../../../hooks"

const reducer = (state, action) => {
  switch (action.type) {
    case "MODE":
      return {
        ...state,
        mode: action.value,
      }
    case "POPULATE":
      return {
        ...state,
        employee: {
          ...state.employee,
          [action.key]: action.value,
        },
      }
    case "OPTIONS":
      return {
        ...state,
        options: action.value,
      }
    default:
      break
  }
  return state
}

const initialState = {
  mode: "",
}

export default function EmployeeDetailPage(props) {
  const {
    employees,
    companyUID,
    selectedLocation,
    companyMap,
    jobs,
    devices,
    locationData,
  } = useSelector((state) => ({
    employees: state.admin?.locationData.employees,
    companyUID: state.admin.locationData.location?.company_uid,
    jobs: state.admin.locationData.jobs,
    selectedLocation: state.admin.selectedLocation,
    companyMap: state.admin.companyMap,
    devices: state.admin.locationData.devices,
    locationData: state.admin.locationData,
  }))
  const params = useParams()
  const dispatch = useDispatch()
  const [state, localDispatch] = useReducer(reducer, initialState)
  const [render, setRender] = useState(false)
  const [employee, setEmployee] = useState(null)
  const [jobOptions, setJobOptions] = useState()
  const [deviceOptions, setDeviceOptions] = useState()
  const passBreadCrumbs = useBreadCrumbsContext()

  useEffect(() => {
    if (!selectedLocation || selectedLocation !== params.locationid) {
      setCurrentLocation(params.locationid, dispatch)
    }
    setMode(localDispatch)
  }, [])

  useEffect(() => {
    if (jobs) {
      const jobOptionsArr = Object.values(jobs).reduce((acc, cval) => {
        let newObj = { value: cval.uid, display: cval.name }
        acc.push(newObj)
        return acc
      }, [])
      setJobOptions(jobOptionsArr)
      setRender(true)
    }
  }, [jobs])

  useEffect(() => {
    if (devices && employee) {
      const deviceOptionsArr = Object.values(devices).reduce((acc, cval) => {
        if (!cval.worker_uid || cval.worker_uid === params.employeeid) {
          let newObj = { value: cval.uid, display: cval.serial_number }
          acc.push(newObj)
        }
        return acc
      }, []).sort((a,b) => parseInt(a.display.split("-")[1]) - parseInt(b.display.split("-")[1]))

      setDeviceOptions(deviceOptionsArr)
    }
  }, [devices, employee])

  useEffect(() => {
    if (selectedLocation && selectedLocation === params.locationid) {
      if (params.employeeid) {
        setEmployee(employees[params.employeeid])
      } else {
        setEmployee({
          vibrationStatus: null,
          wuPosition: null,
        })
      }
    } else {
      setCurrentLocation(params.locationid, dispatch)
    }
  }, [selectedLocation])

  useEffect(() => {
    if (employee) {
      populateEmployeeState()
    }
  }, [employee])

  useEffect(() => {
    if (employee) {
      if (state.mode === "Edit") {
        populateEmployeeState()
      } else if (state.mode === "View") {
        populateEmployeeState()
      }
    }
  }, [state.mode])

  const populateEmployeeState = () => {
    for (let [key, value] of Object.entries(employee)) {
      if (key === "job") {
        key = "jobUID"
      }
      localDispatch({ type: "POPULATE", key: key, value: value })
    }
  }

  const handleInput = (e, field) => {
    const value =
      field === "shiftLengthHours" || field === "vibrationLimit"
        ? parseInt(e.target.value)
        : field === "wuPosition" || field === "jobUID"
        ? e.target.value === null
          ? ""
          : e.target.value
        : field === "device" && e.target.value === "null"
        ? ""
        : e.target.value
    //if (field === "jobUID" && !state?.employee?.jobUID) delete state.employee.job
    console.log(`${field}: ${value}`)
    localDispatch({ type: "POPULATE", key: field, value: value })
  }

  const form = () => {
    return (
      <div className="admin wrapper">
        <main className="container">
          {passBreadCrumbs([
            {
              content: <Icon iconClass={"Skyline"} />,
              route: "/companies",
            },
            {
              content: companyMap && companyMap[companyUID],
              route: `/companies/${companyUID}/`,
            },
            {
              content: selectedLocation && selectedLocation,
              route: `/locations/${selectedLocation}/`,
            },
            {
              content:
                state.mode !== "View"
                  ? state.mode + " Employee"
                  : state.employee?.name,
              route: "",
            },
          ])}
          <div className="main-content detail-page">
            <h1 className="main-heading">
              {`${state.mode} Employee `}
              {state.mode === "Edit" && `- ${state.employee?.name}`}
            </h1>
            <Form>
              {state.mode !== "Add" && (
                <Input
                  mode={state.mode}
                  icons
                  disabled
                  label="Employee ID"
                  value={state.employee?.uid}
                />
              )}
              <Input
                mode={state.mode}
                label="Name"
                value={state.employee?.name}
                onChange={(e) => handleInput(e, "name")}
              />
              <Input
                mode={state.mode}
                label="Vibration Limit"
                value={state.employee?.vibrationLimit}
                onChange={(e) => handleInput(e, "vibrationLimit")}
              />
              <DropDownSelect
                mode={state.mode}
                label="IR Vibrations"
                options={[
                  { value: "true", display: "On" },
                  { value: "false", display: "Off" },
                ]}
                val={state?.employee?.vibrationStatus}
                name="Vibration"
                onChange={(e) => handleInput(e, "vibrationStatus")}
              />
              <DropDownSelect
                mode={state.mode}
                options={jobOptions}
                val={state?.employee?.jobUID}
                label="Group"
                onChange={(e) => handleInput(e, "jobUID")}
              />
              <Input
                mode={state.mode}
                label="Shift End Hour"
                value={state.employee?.shiftEndHour}
                onChange={(e) => handleInput(e, "shiftEndHour")}
              />
              <DropDownSelect
                mode={state.mode}
                options={deviceOptions}
                label="Device"
                val={state?.employee?.device != "" ? state?.employee?.device : null}
                onChange={(e) => handleInput(e, "device")}
                nullOptionLabel={"No Device"}
              />
            </Form>
            <WizardBar
              backToRoute={`/locations/${selectedLocation}/#employees`}
              mode={state.mode}
              setMode={(mode) => {
                populateEmployeeState()
                localDispatch({ type: "MODE", value: mode })
              }}
              apiCall={saveEmployee}
              apiArgs={{
                employee: state.employee,
                mode: state.mode,
                dispatch,
                location: locationData.location.uid,
              }}
              redirectRoute={`/locations/${selectedLocation}/`}
              disabled={
                state.mode === "Add" &&
                state.employee?.name &&
                state.employee?.name.length < 1
                  ? true
                  : false
              }
              header={"Confirm"}
              promptMessage={
                state.mode === "Add"
                  ? "Do you want to add a new empolyee?"
                  : "Do you want to save your changes?"
              }
              category={"Employees"}
            />
          </div>
        </main>
      </div>
    )
  }

  return (
    <>
      {render && form()}
      {!render && (
        <Loading background={"none"} positionRelative={true} height="100vh" />
      )}
    </>
  )
}
