const colors = {
  // white
  white: "#ffffff",

  // gray
  gray1: "#32343e",
  gray2: "#4e515f",
  gray3: "#666874",
  gray4: "#7e818e",
  gray5: "#bbbdc9",
  gray6: "#dfe1ea",
  gray7: "#f1f3fd",
  gray8: "#f9f9fe",
  gray9: "#fcfcff",

  // slate
  slate1: "#979cbc",
  slate2: "#aeb3d6",

  // blue
  blue1: "#4d66fc",
  blue2: "#8c9af0",
  blue3: "#d7dcff",

  // purple
  purple1: "#9b51e0",
  purple2: "#bb6bd9",

  // red
  red1: "#ec5638",
  red2: "#faa291",
  red3: "#e8cfcb",
  red4: "#fff8f8",

  // yellow
  yellow1: "#f1b600",
  yellow2: "#f5d46d",
  yellow3: "#f8eed0",
  yellow4: "#fffaeb",

  // lime
  lime: "#c1dc75",

  // green
  green1: "#00ab78",
  green2: "#75db9f",
  green4: "#f3faf6",

  // overlay
  overlay: "rgba(50, 52, 62, 0.67)",
}

const text = {
  text1: "0.75rem",
  //  14px
  text2: "0.875rem",
}

const easing = {
  easing: {
    default: "cubic-bezier(0.25, 0.1, 0.25, 0.1)",
  },
}

const font = {
  //
}

export const Theme = {
  colors,
  text,
  easing,
  size: (input) => {
    return input * (1 / 16) + "rem"
  },
}
