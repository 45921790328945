import React, { useEffect, useState, useRef } from "react"
import { useSelector } from "react-redux"

import { Loading, ListBox, Icon, DialogueBox } from "../../../../components/ui"

function ChangeLocation({
  companyUID,
  companyList,
  setCompany,
  setcompanyUID,
  companyMap,
  locationsList,
  setLocationUID,
  locationUID,
  apiCallHelper,
  disableSetCompany,
  setLocationName,
  selectedDevices,
}) {
  const [dialogue, setDialogue] = useState(false)
  const [dialogueResponse, setDialogueResponse] = useState()
  const [targetLocation, setTargetLocation] = useState("")
  const [excludeLocation, setExcludeLocation] = useState()

  const movableDevices = selectedDevices.filter((device) => !device.worker_uid)
  const nonMovableDevices = selectedDevices.length - movableDevices.length

  function createPrompt() {
    if (movableDevices.length && nonMovableDevices) {
      return [movePrompt(), stayPrompt()]
    }
    if (movableDevices.length) {
      return movePrompt()
    }
    return stayPrompt()
  }

  function movePrompt() {
    return `${movableDevices.length} ${plural(
      "device",
      movableDevices.length
    )} will be moved to ${targetLocation}.`
  }

  function stayPrompt() {
    return `${nonMovableDevices} ${plural(
      "device",
      nonMovableDevices.length
    )} are assigned to Employees and cannot be moved.`
  }

  useEffect(() => {
    setExcludeLocation(selectedDevices[0].location_uid)
  }, [selectedDevices])

  const goBack = () => {
    setcompanyUID()
  }

  useEffect(() => {
    if (dialogueResponse) {
      apiCallHelper(movableDevices.length)
    } else {
      setDialogue(false)
    }
  }, [dialogueResponse])

  useEffect(() => {
    if (locationUID) {
      setDialogue(true)
    }
  }, [locationUID])

  const setLocationHelper = (uid, name) => {
    setLocationUID(uid)
    setLocationName(name)
    setTargetLocation(name)
    setDialogue(true)
  }

  function plural(word, count) {
    if (count !== 1) {
      return word + "s"
    } else {
      return word
    }
  }

  return (
    <>
      {!companyUID && !dialogue ? (
        <ListBox
          options={companyList}
          title={"Select a Company"}
          firstKey={"name"}
          secondKey={"uid"}
          callback={setCompany}
          category={"companies"}
        />
      ) : (
        companyUID && (
          <>
            {!disableSetCompany && (
              <div className={"mini-nav"}>
                <p className={"Back-button"} onClick={goBack}>
                  <Icon iconClass={"Arrow-left"} /> Select a Company
                </p>
                <p>Company: {companyMap && companyMap[companyUID]}</p>
              </div>
            )}
            <ListBox
              options={locationsList.filter((l) => l.uid !== excludeLocation)}
              title={"Select a location"}
              firstKey={"name"}
              secondKey={"uid"}
              callback={setLocationHelper}
              category={"locations"}
              label={"Move to New Location"}
            />
          </>
        )
      )}
      {dialogue && (
        <DialogueBox
          setDialogue={setDialogue}
          setDialogueResponse={setDialogueResponse}
          header={"Confirm Changes"}
          promptMessage={createPrompt()}
          cancelMessage="Dismiss"
          disabled={movableDevices.length < 1}
        />
      )}
    </>
  )
}

export default ChangeLocation
