import { createStore, applyMiddleware, compose } from "redux"
import reducer from "./reducers"
import thunk from "redux-thunk"
import promiseMiddleware from "./middlewares/promiseMiddleware"
import createSagaMiddleware from "redux-saga"

import { UPDATE_DELTA_STATE } from "./reducers/delta"

import createLogger from "redux-logger"
import {
  getDataSaga,
  keenSaga,
  dateChangeSaga,
  keenFetchSaga,
  keenHourlyFetchSaga,
  getCompareToSaga,
  urlChangeSaga,
  getLocationSetupSaga,
} from "./reducers/app-state"
import { goalsSaga } from "./reducers/goals"
import {
  updateWorkerSaga,
  createWorkerSaga,
  updateWorkerJobsSaga,
  deleteWorkerSaga,
  updateWorkerDevicesSaga,
} from "./reducers/worker"
import { createJobSaga, deleteJobSaga } from "./reducers/jobs"
import { routerMiddleware } from "react-router-redux"
import { history } from "../index"

const logger = createLogger({
  predicate: (getState, { type }) => type !== UPDATE_DELTA_STATE, // USE_LOGGING && R.not(R.contains(type, SAGA_LOGGING_BLACKLIST))
})

const sagaMiddleware = createSagaMiddleware()

const middlewares = [
  applyMiddleware(
    thunk,
    promiseMiddleware,
    logger,
    sagaMiddleware
    //routerMiddleware(history)
  ),
]

export default function configureStore(initialState) {
  const store = createStore(
    reducer,
    initialState,
    compose(
      ...middlewares,
      window.devToolsExtension ? window.devToolsExtension() : (f) => f
      //window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    )
  )

  sagaMiddleware.run(getCompareToSaga)
  sagaMiddleware.run(getDataSaga)
  sagaMiddleware.run(getLocationSetupSaga)
  sagaMiddleware.run(goalsSaga)
  sagaMiddleware.run(updateWorkerSaga)
  sagaMiddleware.run(updateWorkerDevicesSaga)
  sagaMiddleware.run(updateWorkerJobsSaga)
  sagaMiddleware.run(createWorkerSaga)
  sagaMiddleware.run(deleteWorkerSaga)
  sagaMiddleware.run(createJobSaga)
  sagaMiddleware.run(deleteJobSaga)
  // sagaMiddleware.run(keenSaga)
  sagaMiddleware.run(dateChangeSaga)
  sagaMiddleware.run(keenFetchSaga)
  sagaMiddleware.run(keenHourlyFetchSaga)
  sagaMiddleware.run(urlChangeSaga)

  return store
}
