import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { useDispatch, useStore } from "react-redux"
import { useHistory } from "react-router-dom"

import moment from "moment"

import { getSettingsPackages } from "../../../redux/actions/admin"
import { API } from "../../../utils/api"

import { useToastContext } from "../../../hooks"

import AngelScriptDropZone from "./angel-script-drop-zone"
import renameColumns from "../utils/renameColumns"
import { DialogueBox, SearchBar, SimpleTable } from "../../../components/ui"

export default function UtilitiesPage() {
  const [filteredRows, setFilteredRows] = useState([])
  const [tableData, setTableData] = useState()
  const [options, setOptions] = useState([])
  const dispatch = useDispatch()
  const store = useStore()
  const [dialogueResponse, setDialogueResponse] = useState()
  const [settingsPackage, setSettingsPackage] = useState()
  const [dialogueConfig, setDialogueConfig] = useState()
  const [defaultPackageUID, setDefaultPackageUID] = useState()
  const addToastMessage = useToastContext()
  const history = useHistory()
  const [activeTab, setActiveTab] = useState("settings-packages")

  const [base_configs, base_configs_set] = useState([])
  const [filtered_configs, filtered_configs_set] = useState([])

  function handle_filter(results) {
    filtered_configs_set(results)
  }

  useEffect(() => {
    async function get_configs() {
      const configs_res = await API.getProductConfigs()
      const configs = configs_res?.data?.data

      if (configs) {
        const flattened = configs.map((item) => {
          const { product_config, location_count } = item

          const created_at = moment(product_config.created_at).format(
            "MMM M, YYYY",
          )
          const deleted_at = product_config.deleted_at
            ? moment(product_config.deleted_at).format("MMM M, YYYY")
            : "Never"

          if (product_config.default_package) {
            setDefaultPackageUID(product_config.uid)
          }

          return {
            name: product_config.name,
            description: product_config.description || "",
            default_package: product_config.default_package
              ? "⬤ true"
              : "⬤ false",
            created_at,
            deleted_at,
            location_count,
            uid: product_config.uid,
          }
        })
        base_configs_set(flattened)
        filtered_configs_set(flattened)
      }
    }

    get_configs()
  }, [])

  async function getSettingsPackagesHelper() {
    const _tempSettingsPackages = []
    const _tempOtions = []

    await getSettingsPackages(dispatch)

    store.getState().admin.settingsPackages.map((i) => {
      let _tempPackage = {
        name: i.name,
        ...i,
        default: i.default_package ? "⬤ true" : "⬤ false",
        activeLocationsUsed: i.count,
        clickableRow: `utilities/settings-packages/${i.uid}`,
      }
      let _tempOtion = {
        value: i.uid,
        display: i.name,
      }

      _tempOtions.push(_tempOtion)
      _tempSettingsPackages.push(_tempPackage)
    })
    _tempOtions.unshift({ value: "no-default", display: "--No Default--" })
    setTableData(
      renameColumns(_tempSettingsPackages, { name: "Configuration Name" }),
    )
  }

  useEffect(() => {
    getSettingsPackagesHelper()
    updateActiveTab()
  }, [])

  useEffect(() => {
    const runEffect = async () => {
      await API.deleteSettingsPackages([settingsPackage.uid])
    }

    settingsPackage &&
      dialogueResponse &&
      dialogueConfig.action === "Delete" &&
      runEffect()
        .then(() => {
          addToastMessage({
            message: `Product Configuration "${settingsPackage?.name}" was successfully deleted!`,
            status: "success",
            iconClass: "Circled-checkmark",
          })
          const _tempTableData = tableData.filter((o) => {
            return o.uid !== settingsPackage.uid
          })
          const _tempOptions = options.filter((o) => {
            return o.value !== settingsPackage.uid
          })

          setTimeout(() => {
            setTableData(_tempTableData)
          }, 500)
        })
        .catch(() => {
          addToastMessage({
            message: `ERROR: Deleting Product Configuration "${settingsPackage?.name}" failed!`,
            status: "failure",
            iconClass: "Circled-plus",
          })
        })
        .finally(() => {
          setDialogueConfig()
          setTimeout(() => {
            setSettingsPackage()
            setDialogueResponse()
          }, 6500)
        })
  }, [dialogueResponse])

  useEffect(() => {
    const runEffect = async () => {
      await API.updateSettingsPackage({ ...settingsPackage })
    }

    settingsPackage &&
      dialogueResponse &&
      dialogueConfig?.action.includes("Default") &&
      runEffect()
        .then(() => {
          if (dialogueConfig.action === "Remove Default") {
            setDefaultPackageUID(undefined)
          }
          addToastMessage({
            message: settingsPackage?.default_package
              ? `Product Configuration "${settingsPackage?.name}" was successfully set as the default!`
              : `Product Configuration "${settingsPackage?.name}" was successfully removed as the default!`,
            status: "success",
            iconClass: "Circled-checkmark",
          })
        })
        .catch(() => {
          addToastMessage({
            message: `ERROR: Setting "${
              settingsPackage?.name ? settingsPackage?.name : "No Default"
            }" as the default caonfiguration failed!`,
            status: "failure",
            iconClass: "Circled-plus",
          })
        })
        .finally(() => {
          setDialogueConfig()
          getSettingsPackagesHelper()
          setSettingsPackage()
          setDialogueResponse()
        })
  }, [dialogueResponse])

  useEffect(() => {
    if (!dialogueResponse) {
      setSettingsPackage(undefined)
    }
  }, [dialogueResponse])

  const deleteCallback = (link) => {
    const uidToDelete = link.split("/")[2]
    const matchedSettingsPackage = store
      .getState()
      .admin.settingsPackages.filter((i) => {
        if (i.uid === uidToDelete) {
          return i
        }
      })

    setSettingsPackage(matchedSettingsPackage[0])
    setTimeout(() => {
      setDialogueConfig({
        header: "Delete this Product Configuration?",
        promptMessage: `Product Configuration "${matchedSettingsPackage[0].name}"`,
        deleteFlag: true,
        action: "Delete",
      })
    }, 50)
  }

  const setDefaultSettingsPackage = (e) => {
    if (store.getState().admin.settingsPackages) {
      let packageName = store.getState().admin.settingsPackages.map((o) => {
        if (o.uid === e.target.value) {
          const _tempPackage = {
            ...o,
            default_package: true,
          }
          setSettingsPackage({ ..._tempPackage })
          return _tempPackage
        } else if (e.target.value === "no-default") {
          const _matchedSettingsPackage = store
            .getState()
            .admin.settingsPackages.filter((g) => {
              return g.default_package === true
            })
          const _tempPackage = {
            uid: _matchedSettingsPackage[0].uid,
            feature_flags: _matchedSettingsPackage[0].feature_flags,
            name: _matchedSettingsPackage[0].name,
            description: _matchedSettingsPackage[0].description,
            default_package: false,
          }
          setSettingsPackage({ ..._tempPackage })
          return _tempPackage
        }
      })
      const _packageName = packageName.filter((x) => {
        return x !== undefined
      })
      setTimeout(() => {
        setDialogueConfig({
          header: _packageName[0]?.default_package
            ? "Update Default Product Configuration"
            : "Remove Default Product Configuration",
          promptMessage: _packageName[0]?.default_package
            ? `Set Product Configuration "${_packageName[0]?.name}" as default?`
            : `Remove "${_packageName[0]?.name}" as Default Product Configuration?`,
          deleteFlag: _packageName[0]?.default_package ? false : true,
          action: _packageName[0]?.default_package
            ? "Update Default"
            : "Remove Default",
        })
      }, 50)
    }
  }

  const updateActiveTab = (tab) => {
    if (tab) setActiveTab(tab)
    else if (window.location.href.split("#")[1])
      setActiveTab(window.location.href.split("#")[1])
    else setActiveTab("settings-packages")
  }

  return (
    <div className="admin wrapper Utilities">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Utilities • Kinetic</title>
      </Helmet>
      <main className="admin container">
        <div className="main-content">
          <div className="main-heading-parent">
            <a
              className={`main-heading tab ${
                activeTab === "settings-packages" ? "active" : ""
              }`}
              href="#settings-packages"
              onClick={() => updateActiveTab("settings-packages")}
            >
              Product Configurations
            </a>
            <a
              className={`main-heading tab ${
                activeTab === "add-devices" ? "active" : ""
              }`}
              href="#add-devices"
              onClick={() => updateActiveTab("add-devices")}
            >
              Add Devices
            </a>
            <div
              className={`main-heading-tab-slider active ${activeTab}`}
            ></div>
          </div>
          {activeTab === "settings-packages" && (
            <>
              <div className="Setting-packages-search-category-section">
                <div className="Setting-packages-search-category-section-left">
                  <h4 className="field-label">Search Product Configurations</h4>
                  <SearchBar
                    clearFilterData={() => filtered_configs_set(base_configs)}
                    placeholder={"Start typing..."}
                    handleSearch={(res) => handle_filter(res)}
                    data={base_configs.length ? base_configs : []}
                  />
                </div>
                <div className="Setting-packages-search-category-section-right"></div>
              </div>
              <div>
                <SimpleTable
                  rows={
                    filtered_configs.length
                      ? filtered_configs
                      : [{ noData: "No Product Configurations" }]
                  }
                  deleteCallback={deleteCallback}
                />
              </div>
            </>
          )}
          {activeTab === "add-devices" && <AngelScriptDropZone />}
        </div>
      </main>
      {dialogueConfig && (
        <DialogueBox
          setDialogue={setDialogueConfig}
          setDialogueResponse={setDialogueResponse}
          header={dialogueConfig.header}
          promptMessage={dialogueConfig.promptMessage}
          deleteFlag={dialogueConfig.deleteFlag}
          action={dialogueConfig.action}
        />
      )}
    </div>
  )
}
