import { batchAddEmployees } from "../../../../redux/actions/admin"

import { API } from "../../../../utils/api"
import Papa from "papaparse"

export function csvUpload(
  setLoading,
  setErrorState,
  setMessage,
  history,
  category,
  location,
  params,
  stagedFile,
  autoAssign,
  companyUID,
  addToastMessage
) {
  if (category === "Managers") {
    let res = API.batchAddManagers(stagedFile, companyUID)
    setLoading(true)
    res
      .then(() => {
        setLoading(false)
        addToastMessage({
          message: "New managers successfully added",
          status: "success",
          iconClass: "Circled-checkmark",
        })
        history.push(`/companies/${params.companyid}#managers`)
      })
      .catch((e) => {
        console.log(`Saving Error: ${e}`)
        setErrorState(true)
        setMessage("There was an error uploading the csv")
      })
  }
  if (category === "Employees") {
    let res = batchAddEmployees(stagedFile, autoAssign)
    setLoading(true)
    res
      .then(() => {
        setLoading(false)
        addToastMessage({
          message: "New employees successfully added",
          status: "success",
          iconClass: "Circled-checkmark",
        })
        history.push(`/locations/${params.locationid}`)
      })
      .catch((e) => {
        console.log(`Saving Error: ${e}`)
        setErrorState(true)
        setMessage("There was an error uploading the csv")
      })
  } else if (category === "Devices") {
    let res = API.moveWus(stagedFile, location, "csv")
    setLoading(true)
    res
      .then(() => {
        setLoading(false)
        addToastMessage({
          message: "Devices successfully moved",
          status: "success",
          iconClass: "Circled-checkmark",
        })
        history.push(`/locations/${location}/#devices`)
      })
      .catch((e) => {
        console.log(`Saving Error: ${e}`)
        setErrorState(true)
        setMessage("There was an error uploading the csv")
      })
  } else if (category === "Locations") {
    setLoading(true)
    const reader = new FileReader(stagedFile)
    reader.onload = function (e) {
      const parsed = Papa.parse(e.target.result, { header: true })
      console.log(parsed)
      const locations = parsed.data
        .filter((item) => item.name)
        .map((item) => ({
          name: item.name,
          company_uid: companyUID,
          timezone: item.timezone,
        }))
      if (locations.length === 0) {
        setErrorState(true)
        setMessage("There was an error uploading the csv")
        return
      }
      API.createLocations(locations)
        .then(() => {
          setLoading(false)
          addToastMessage({
            message: "New locations successfully created",
            status: "success",
            iconClass: "Circled-checkmark",
          })
          history.push(`/companies/${companyUID}/`)
        })
        .catch((e) => {
          console.log(`Saving Error: ${e}`)
          setErrorState(true)
          setMessage("There was an error uploading the csv")
        })
    }
    reader.readAsText(stagedFile)
  }
}
