import * as d3 from "d3v4"

import { reduce } from "lodash"

import { GET_DATA_ATTEMPT } from "./app-state"

import { SET_DATE_RANGE, TOGGLE_JOB } from "./filters"
import { API } from "../../utils/api"

import { put, takeLatest, call, select } from "redux-saga/effects"

// --------------------------- Action constants --------------------------
const TREND_REQUEST = "worker/TREND_REQUEST"
const TREND_SUCCESS = "worker/TREND_SUCCESS"
const RESET_WORKER = "worker/RESET_WORKER"
const UPDATE_WORKER = "worker/UPDATE_WORKER"
const CREATE_WORKER = "worker/CREATE_WORKER"
const DELETE_WORKERS = "worker/DELETE_WORKERS"
const UPDATE_WORKER_JOBS = "worker/UPDATE_WORKER_JOBS"
const UPDATE_WORKER_DEVICES = "worker/UPDATE_WORKER_DEVICES"

// --------------------------- Reducer function --------------------------
const initialState = {
  fetching: false,
  loaded: false,
  data: [],
  rawTotal: 0,
  prevRawTotal: 0,
}

function getAverage(x, activeTime, i, interval) {
  if (interval === "hourly") {
    return x.value / 1
  }
  return (x.value / activeTime[i].value) * 3600
}

function getPrevious(prevSum, i, prevActiveTime, interval) {
  if (interval === "hourly") {
    return !!prevSum[i] && !!prevActiveTime[i] ? prevSum[i].value / 1 : null
  }
  return !!prevSum[i] && !!prevActiveTime[i]
    ? (prevSum[i].value / prevActiveTime[i].value) * 3600
    : null
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case RESET_WORKER:
      return initialState
    case SET_DATE_RANGE:
    case TOGGLE_JOB:
      return {
        ...state,
        loaded: false,
      }
    case TREND_REQUEST:
      return {
        ...state,
        fetching: true,
        loaded: false,
        data: [],
      }

    case TREND_SUCCESS:
      const { interval } = action.payload
      const sum = action.result[0].result
      const prevSum = action.result[1].result
      const activeTime = action.result[2].result
      const prevActiveTime = action.result[3].result

      const data = sum.map((x, i) => {
        return {
          xAxisLabel: new Date(x.timeframe.start),
          avg: getAverage(x, activeTime, i, interval),
          prev: getPrevious(prevSum, i, prevActiveTime, interval),
          timeframe: x.timeframe,
        }
      })

      return {
        ...state,
        loaded: true,
        fetching: false,
        data,
        rawTotal:
          (d3.sum(sum, (x) => x.value) / d3.sum(activeTime, (x) => x.value)) *
          3600,
        prevRawTotal:
          (d3.sum(prevSum, (x) => x.value) /
            d3.sum(prevActiveTime, (x) => x.value)) *
          3600,
        activeTime: d3.sum(activeTime, (x) => x.value),
      }

    default:
      return state
  }
}

function _getMean(state, key) {
  if (!state.workerData.loaded) {return null}
  const result = state.workerData[key]
  return isFinite(result) ? result.toFixed(1) : 0.0
}

export function getMean(state) {
  return _getMean(state, "rawTotal")
}

export function getPrevMean(state) {
  return _getMean(state, "prevRawTotal")
}

export function getPrevMeanRate(state) {
  if (!state.workerData.loaded) {return null}
  const current = getMean(state)
  const prev = getPrevMean(state)
  return ((current - prev) / prev) * 100
}

// --------------------------- Action functions --------------------------

export function updateWorker(worker, old) {
  return {
    type: UPDATE_WORKER,
    payload: { worker, old },
  }
}

export function createWorker(worker) {
  return {
    type: CREATE_WORKER,
    payload: { worker },
  }
}

export function deleteWorkers(workers) {
  return {
    type: DELETE_WORKERS,
    payload: { workers },
  }
}

export function updateWorkerJobs(workers, job) {
  return {
    type: UPDATE_WORKER_JOBS,
    payload: { workers, job },
  }
}

export function updateWorkerDevices(workers, mode) {
  return {
    type: UPDATE_WORKER_DEVICES,
    payload: { workers, mode },
  }
}

export function resetWorker() {
  return {
    type: RESET_WORKER,
  }
}

function* updateWorkerWorker(action) {
  try {
    yield call(API.updateWorker, action.payload.worker)
    yield put({ type: GET_DATA_ATTEMPT, payload: { noLoading: true } })
  } catch (e) {
    // yield put({type: LOGIN_FAILED, message: (e.message || 'Invalid email / password')});
  }
}

export function* updateWorkerSaga() {
  yield takeLatest(UPDATE_WORKER, updateWorkerWorker)
}

function* updateWorkerJobsWorker(action) {
  try {
    yield call(API.updateWorkerJobs, action.payload.workers, action.payload.job)
    yield put({ type: GET_DATA_ATTEMPT })
  } catch (e) {}
}

export function* updateWorkerJobsSaga() {
  yield takeLatest(UPDATE_WORKER_JOBS, updateWorkerJobsWorker)
}

function* updateWorkerDevicesWorker(action) {
  try {
    if (action.payload.mode === "assign") {
      yield call(API.assignWorkerDevices, action.payload.workers)
    } else if (action.payload.mode === "unassign") {
      yield call(API.unassignWorkerDevices, action.payload.workers)
    }
    yield put({ type: GET_DATA_ATTEMPT })
  } catch (e) {}
}

export function* updateWorkerDevicesSaga() {
  yield takeLatest(UPDATE_WORKER_DEVICES, updateWorkerDevicesWorker)
}

function* createWorkerWorker(action) {
  try {
    if (action.payload.worker.shiftEndHour === "false") {
      delete action.payload.worker.shiftEndHour
    }

    const response = yield call(API.createWorker, action.payload.worker)

    if (action.payload.worker.job) {
      yield call(API.addJobToWorker, response, {
        uid: action.payload.worker.job,
      })
    }

    if (action.payload.worker.device) {
      yield call(API.addWUToWorker, response, {
        uid: action.payload.worker.device,
      })
    }

    yield put({ type: GET_DATA_ATTEMPT })
  } catch (e) {}
}

export function* createWorkerSaga() {
  yield takeLatest(CREATE_WORKER, createWorkerWorker)
}

function* deleteWorkerWorker(action) {
  try {
    yield call(API.deleteWorkers, action.payload.workers)
    yield put({ type: GET_DATA_ATTEMPT })
  } catch (e) {}
}

export function* deleteWorkerSaga() {
  yield takeLatest(DELETE_WORKERS, deleteWorkerWorker)
}
