import React, { useState, useEffect, useRef } from "react"
import { CSVLink } from "react-csv"

import { Loading, Icon } from "../ui"

export function CSVdownloadModal({
  csvData,
  filename = "expored data - Admin Dashboard.csv",
  close,
  category,
  ignoreKeys,
}) {
  const [render, setRender] = useState()
  const [csvDataCopy, setCsvDataCopy] = useState()
  const [selectedIds, setSelectedIds] = useState([])
  const dontRender = [
    "\" \"",
    "selected",
    "clickableRow",
    "previewLink",
    "noData",
    "hashedPassword",
  ]
  const capitalizeTheseHeaders = ["uid", "sku"]
  const [headers, setHeaders] = useState()
  const _modal = useRef()
  const _modalContainer = useRef()

  useEffect(() => {
    setTimeout(() => {
      _modalContainer.current.classList.add("Active")
    }, 100)
    setTimeout(() => {
      _modal.current.classList.add("Active")
    }, 150)
  }, [])

  useEffect(() => {
    if (csvData) {
      setCsvDataCopy(
        csvData.map((i, key) => {
          let _tempObj = { selected: `checkbox-${key}`, ...i }
          dontRender.map((i) => delete _tempObj[i])
          ignoreKeys && ignoreKeys.map((i) => delete _tempObj[i])
          return _tempObj
        })
      )
      setRender(true)
    }
  }, [csvData])

  const cleanUpHeaders = (stringToSplit) => {
    const unformattedHeader = stringToSplit.split(/(?=[A-Z_])/)
    const header = unformattedHeader.map((word, index, unformattedHeader) => {
      word = word.charAt(0).toUpperCase() + word.slice(1)
      if (capitalizeTheseHeaders.includes(word.toLowerCase()))
        {return word.toUpperCase()}
      if (word.includes("_"))
        {return word.replace("_", "").charAt(0).toUpperCase() + word.slice(2)}
      if (index !== unformattedHeader.length - 1) {
        if (
          /^[A-Z]/.test(unformattedHeader[index + 1]) &&
          unformattedHeader[index].length === 1 &&
          unformattedHeader[index + 1].length === 1
        ) {
          return word
        }
        return word + " "
      } else {return word}
    })
    return header
  }

  const handleCheckboxClick = (e) => {
    e.stopPropagation()
    const tempSelectedIds = JSON.parse(JSON.stringify(selectedIds))

    if (!e.target.classList.contains("checked")) {
      setSelectedIds([...selectedIds, e.target.id])
    } else {
      const idNdx = tempSelectedIds.indexOf(e.target.id)
      tempSelectedIds.splice(idNdx, 1)
      setSelectedIds(tempSelectedIds)
    }
  }

  useEffect(() => {
    if (selectedIds) {
      const _tempHeaders = selectedIds.reduce((acc, i) => {
        acc.push({ label: i, key: i })
        return acc
      }, [])
      setHeaders(_tempHeaders)
    } else {setHeaders()}
  }, [selectedIds])

  const closeModal = () => {
    _modal.current.classList.remove("Active")
    setTimeout(() => {
      close()
    }, 300)
  }

  const table = () => {
    return (
      <table className="Simple-table-container">
        <tbody>
          {csvDataCopy &&
            Object.keys(csvDataCopy[0]).map((data, key) => {
              return (
                <tr key={key * 30.2} className="Simple-table-row">
                  <td key={key + 13.231} className="Simple-table-checkbox">
                    <div
                      key={key + 14.09342}
                      className={`checkbox ${
                        selectedIds.includes(data) ? "checked box-checked" : ""
                      }`}
                      id={data}
                      onClick={(e) => handleCheckboxClick(e)}
                    >
                      <Icon iconClass="Checkmark" size={"0.9rem"} />
                    </div>
                  </td>
                  <td key={key + 2} className="Simple-table-data">
                    {cleanUpHeaders(data)}
                  </td>
                </tr>
              )
            })}
        </tbody>
      </table>
    )
  }

  return (
    <div
      className="Csv-downloader-container Modal-container"
      ref={_modalContainer}
    >
      <div className="Csv-downloader-modal Modal" ref={_modal}>
        <div className={"Close-button"} onClick={closeModal}>
          <Icon iconClass={"X-mark"} size={"1.4rem"} />
        </div>
        <h2>Download CSV - {category}</h2>
        <h3>Please select value(s) to include in the CSV</h3>
        {render ? (
          table()
        ) : (
          <div style={{ marginBottom: "20px", transform: "scale(0.5)" }}>
            <Loading
              background={"none"}
              positionRelative={true}
              height={"50px"}
            />
          </div>
        )}
        <CSVLink
          filename={filename}
          data={csvData}
          headers={headers}
          className={`button ${selectedIds.length === 0 ? "disabled" : ""} `}
          onClick={() => {
            setTimeout(() => {
              closeModal()
            }, 10)
          }}
        >
          <Icon iconClass="Download" size={"1rem"} />
          Download CSV
        </CSVLink>
      </div>
    </div>
  )
}
