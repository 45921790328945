// --------------------------- Action constants --------------------------
export const SELECT_DEVICE = "device/SELECT_DEVICE"
export const DESELECT_DEVICE = "device/DESELECT_DEVICE"
export const SELECT_ALL_DEVICES = "device/SELECT_ALL_DEVICES"
export const DESELECT_ALL_DEVICES = "device/DESELECT_ALL_DEVICES"

// --------------------------- Reducer function --------------------------
const initialState = {
  selectedDevices: [],
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SELECT_DEVICE:
      return {
        ...state,
        selectedDevices: [...state.selectedDevices, action.data.deviceId],
      }
    case SELECT_ALL_DEVICES:
      return {
        ...state,
        selectedDevices: action.data.devices,
      }
    case DESELECT_DEVICE:
      const index = state.selectedDevices.indexOf(action.data.deviceId)
      return {
        ...state,
        selectedDevices: [
          ...state.selectedDevices.slice(0, index),
          ...state.selectedDevices.slice(index + 1),
        ],
      }
    case DESELECT_ALL_DEVICES:
      return {
        ...state,
        selectedDevices: [],
      }
    default:
      return state
  }
}

// --------------------------- Action functions --------------------------

export function selectDevice(deviceId) {
  return {
    type: SELECT_DEVICE,
    data: {
      deviceId,
    },
  }
}

export function selectAllDevices(devices) {
  return {
    type: SELECT_ALL_DEVICES,
    data: {
      devices,
    },
  }
}

export function deselectDevice(deviceId) {
  return {
    type: DESELECT_DEVICE,
    data: {
      deviceId,
    },
  }
}

export function deselectAllDevices() {
  return {
    type: DESELECT_ALL_DEVICES,
  }
}

/* ------------------------------------------SELECTIONS -----------------------------------------------*/

export function getAssignedDevices(devices) {
  return Object.values(devices)
    .filter((d) => d.employee && d.employee !== "")
    .reduce((prev, curr) => ({ ...prev, [curr.key]: curr }), {})
}

export function getUnassignedDevices(devices) {
  return Object.values(devices)
    .filter((d) => !d.employee || d.employee === "")
    .reduce((prev, curr) => ({ ...prev, [curr.key]: curr }), {})
}
