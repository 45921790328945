import React, { useEffect, useRef, useState } from "react"
import { useSelector, useDispatch } from "react-redux"

import { DropDownSelect, Icon, Loading, DialogueBox } from "../ui"

import { setCurrentLocation } from "../../redux/actions/admin"
import { API } from "../../utils/api"

import { deviceStatusOptions } from "../../constants/deviceStatusOptions"

export function DeviceStatusUpdateModal({
  closeModal,
  promptMessage,
  action,
  header,
  locationid,
}) {
  const { devices } = useSelector((state) => ({
    devices: state.admin.selectedCheckboxItems,
  }))
  const _modalContainer = useRef()
  const _modal = useRef()
  const [payload, setPayload] = useState()
  const [deviceUIDs, setDeviceUIDs] = useState()
  const [sendCommandsFlag, setSendCommandsFlag] = useState()
  const [dilalogueResponse, setDialogueResponse] = useState()
  const [responsePending, setResponsePending] = useState()
  const [loadingConfig, setLoadingConfig] = useState()
  const [status, setStatus] = useState()
  const dispatch = useDispatch()

  const handleButtonClick = (res) => {
    if (res) {
      setSendCommandsFlag(res)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      _modalContainer.current.classList.add("Active")
    }, 100)
    setTimeout(() => {
      _modal.current.classList.add("Active")
    }, 150)
    document.addEventListener("keydown", handleKeydown)
    document.querySelector("html").style.overflow = "hidden"

    return () => {
      document.removeEventListener("keydown", handleKeydown)
      document.querySelector("html").style.overflow = "auto"
    }
  }, [])

  useEffect(() => {
    if (devices) {
      setDeviceUIDs(
        devices.map((o) => {
          return o.uid
        })
      )
    }
  }, [devices])

  useEffect(() => {
    if (status) {
      buildPayload()
    }
  }, [status])

  const buildPayload = () => {
    const _tempPayload = deviceUIDs.reduce((arr, o) => {
      arr.push({ uid: o, status: status })
      return arr
    }, [])
    setPayload(_tempPayload)
  }

  function sendCommands() {
    let res = API.batchUpdateWus(payload)

    setResponsePending(true)
    res
      .then(() => {
        setCurrentLocation(locationid, dispatch)
        setTimeout(() => {
          setResponsePending(false)
          closeModalHelper()
        }, 1000)
      })
      .catch((e) => {
        console.log(`Saving Error: ${e}`)
        setLoadingConfig({
          message: "There was an error",
          errorState: true,
        })
      })
  }

  useEffect(() => {
    dilalogueResponse && sendCommands()
  }, [dilalogueResponse])

  const handleKeydown = (e) => {
    if (e.key === "Enter") {
      handleButtonClick(true)
    }
  }

  const closeModalHelper = () => {
    _modalContainer.current.classList.remove("Active")
    _modal.current.classList.remove("Active")
    setTimeout(() => {
      closeModal(false)
    }, 300)
  }

  return (
    <div
      className="Device-status-update-modal Modal-container"
      ref={_modalContainer}
    >
      <div className="Modal" ref={_modal}>
        <div className={"Close-button"} onClick={() => closeModalHelper()}>
          <Icon iconClass={"X-mark"} size={"1.4rem"} />
        </div>
        {header && <h1 className="Modal-header">{header}</h1>}
        <h3 className="Modal-sub-header">{`Update Status of ${
          devices && devices.length
        } device(s)`}</h3>
        <p className="prompt">{promptMessage}</p>
        <DropDownSelect
          label={null}
          options={deviceStatusOptions}
          val={status}
          name="Choose Status"
          nullOption={true}
          onChange={(e) => setStatus(e.target.value)}
          className={"round"}
        />
        <div className="Button-container">
          <div className="button secondary" onClick={() => closeModalHelper()}>
            Nevermind
          </div>
          <div
            className={`button ${status ? "" : "disabled"}`}
            onClick={() => handleButtonClick(true)}
          >
            {action}
          </div>
        </div>
      </div>
      {sendCommandsFlag && (
        <DialogueBox
          setDialogue={setSendCommandsFlag}
          setDialogueResponse={setDialogueResponse}
          header={"Confirm Status Update"}
          promptMessage={"Are you sure you want to Update Device Status?"}
        />
      )}
      {responsePending && (
        <Loading
          errorState={loadingConfig?.errorState}
          message={loadingConfig?.message}
          setLoading={setResponsePending}
          backdropFilter={"blur(4px)"}
        />
      )}
    </div>
  )
}
