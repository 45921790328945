import React, { useState, useEffect } from "react"
import { useParams, useHistory } from "react-router-dom"
import { useSelector, useDispatch, useStore } from "react-redux"
import { Helmet } from "react-helmet"
import { Link } from "react-router-dom"

import moment from "moment"

import {
  setCurrentCompany,
  getManagerList,
  clearCurrentLocation,
} from "../../../redux/actions/admin"

import {
  SimpleTable,
  SearchBar,
  Loading,
  Icon,
  SimpleTableNext,
  Modal,
  SelectBox,
  DropDownSelect,
  ModalModern,
} from "../../../components/ui"
import { CSVdownloadModal } from "../../../components/csv-download-modal"

import { API } from "../../../utils/api"

import {
  useBreadCrumbsContext,
  useToastContext,
  useSidePanelContext,
} from "../../../hooks"
import { dateStringFromServerIsNotNull } from "../../../utils"
import renameColumns from "../utils/renameColumns"

export default function LocationListingPage() {
  const { settingsPackages, locations, companyUID, companyMap } = useSelector(
    (state) => ({
      locations: state.admin.locationsList,
      companyUID: state.admin.selectedCompany,
      companyMap: state.admin.companyMap,
      settingsPackages: state.admin.settingsPackages,
    })
  )

  const history = useHistory()

  const [rows, setRows] = useState([])
  const [sort_header, set_sort_header] = useState("")
  const [sort_direction, set_sort_direction] = useState()
  const [returnedFromAdd, setReturnedFromAdd] = useState(false)
  const [select_all, set_select_all] = useState(false)
  const [selected_count, set_selected_count] = useState(0)

  const [settings_package, set_settings_package] = useState()
  const [settings_modal_active, set_settings_modal_active] = useState(false)

  const [status, setStatus] = useState()
  const [statusModalActive, setStatusModalActive] = useState(false)

  const [deployment_type, setDeploymentType] = useState()
  const [deploymentTypeModalActive, setDeploymentTypeModalActive] = useState(
    false
  )

  const [search_text, set_search_text] = useState("")

  const [filteredSP, setFilteredSP] = useState([])
  const [filteredRows, setFilteredRows] = useState([])
  // const [locationsCopy, setLocationsCopy] = useState([])
  const [activeTab, setActiveTab] = useState("")
  const params = useParams()
  const dispatch = useDispatch()
  const [managers, setManagers] = useState()
  const store = useStore()
  const [render, setRender] = useState(false)
  const [downloadCSV, setDownloadCSV] = useState(false)
  const passBreadCrumbs = useBreadCrumbsContext()
  const addToastMessage = useToastContext()
  const renderSidePanel = useSidePanelContext()

  const [settingsPackagesMap, setSettingsPackagesMap] = useState()
  const [settingsPackagesOptions, setSettingsPackagesOptions] = useState()

  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState("")
  const [errorState, setErrorState] = useState(false)

  useEffect(() => {
    if (settingsPackages) {
      setSettingsPackagesMap(
        settingsPackages.reduce((coll, curr) => {
          coll[curr.uid] = curr
          return coll
        }, {})
      )
      setSettingsPackagesOptions(
        settingsPackages.reduce(
          (coll, p) => {
            let newObj = {}
            newObj.value = p.uid
            newObj.display = p.name
            coll.push(newObj)
            return coll
          },
          [{ value: "null", display: "None" }]
        )
      )
    }
  }, [settingsPackages])

  useEffect(() => {
    setRows(construct_rows(locations))
  }, [settingsPackagesMap])

  const onFilterData = (results) => {
    if (activeTab === "locations") setFilteredRows()
    if (results) setFilteredRows(results)
    else setFilteredRows([])
  }

  useEffect(() => {
    console.log("Location listing component mounted")
  }, [])

  useEffect(() => {
    if (managers) {
      setRender(true)
    }
  }, [managers])

  useEffect(() => {
    if (companyUID) {
      const runEffect = async () => {
        await getManagerList(companyUID, dispatch)
        const mans = JSON.parse(
          JSON.stringify(store.getState().admin.managerList)
        )
        mans.map((i) => {
          i["locations"] = i.locations.length
          i["clickableRow"] = `/companies/${companyUID}/managers/${i.uid}`
        })
        setManagers(renameColumns(mans, { locations: "locationsManaged" }))
      }
      runEffect()
    }
  }, [companyUID])

  useEffect(() => {
    setCurrentCompany(params.companyid, dispatch)
    if (window.location.href.split("#")[1] == "location-added") {
      setReturnedFromAdd(true)
      updateActiveTab("locations")
    } else {
      updateActiveTab()
    }
    clearCurrentLocation(dispatch)
  }, [])

  // useEffect(() => {
  //   if (locations) {
  //     const tempLocationsCopy = JSON.parse(JSON.stringify(locations)).map((o) => {
  //       return {
  //         name: o.name,
  //         ...o,
  //         clickableRow: `/locations/${o.uid}`,
  //       }
  //     })
  //     setLocationsCopy(tempLocationsCopy)
  //   }
  // }, [locations])

  useEffect(() => {
    let staticFiltSP = ["All Product Configurations", "None"]
    let filtSP = []
    locations.forEach((l) => {
      if (!filtSP.includes(l.settingsPackageName)) {
        if (l.settingsPackageName && l.settingsPackageName != "") {
          filtSP.push(l.settingsPackageName)
        }
      }
    })
    setFilteredSP(staticFiltSP.concat(filtSP.sort()))
  }, [locations])

  useEffect(() => {
    locations && settingsPackagesMap && setRows(construct_rows())
  }, [locations, settingsPackagesMap])

  function construct_rows() {
    return locations
      .map((location) => {
        let row = {}
        row["Product Configuration"] = location.settingsPackageName || ""
        row["Required Device Version"] = location.required_version || ""
        row["Name"] = location.name
        row["Devices pinged in last 24 hrs"] =
          location.deviceCount > 0
            ? `${Math.round(
                (location.healthyDeviceCount / location.deviceCount) * 100
              )}% (${location.healthyDeviceCount}/${location.deviceCount})`
            : `0% (0/0)`
        row["UID"] = location.uid
        row["Employees with HRP Vib."] =
          location.feedback_on_count > 0 ? location.feedback_on_count : 0
        // row["Deployment Type"] =
        //   location.deployment_type.charAt(0).toUpperCase() +
        //   location.deployment_type.slice(1)
        row["link"] = "/locations/" + location.uid
        row["IR Reporting Start Date"] = dateStringFromServerIsNotNull(
          location.reporting_ergo_start
        )
          ? moment(location.reporting_ergo_start).format("ddd MMM D, YYYY")
          : ""
        // row["SD Report Start"] = dateStringFromServerIsNotNull(
        //   location.reporting_ct_start
        // )
        //   ? moment(location.reporting_ct_start).format("ddd MMM D, YYYY")
        //   : ""
        row["Status"] =
          location.status.charAt(0).toUpperCase() + location.status.slice(1)
        row["Created"] = moment(
          location.created_at,
          "YYYY-MM-DDTHH:mm:ssZ"
        ).format("MM/DD/YYYY")
        return row
      })
      .sort((a, b) => {
        if (returnedFromAdd) {
          set_sort_header("Created")
          set_sort_direction("desc")
          return moment(b["Created"], "MM/DD/YYYY").diff(
            moment(a["Created"], "MM/DD/YYYY")
          )
        }
      })
  }

  useEffect(() => {
    if (search_text === "") {
      setRows(construct_rows(locations))
    } else {
      if (search_text !== "") {
        setRows(
          construct_rows(locations).filter((row) => {
            return (
              [row["Name"], row["UID"], row["Product Configuration"]]
                .filter((val) => val && val !== "")
                .map((val) => val.toLowerCase())
                .filter((val) => val.includes(search_text.toLowerCase()))
                .length > 0
            )
          })
        )
      }
    }
  }, [search_text])

  useEffect(() => {
    set_selected_count(rows_selected().length)
  }, [rows])

  function handle_sort_direction(header) {
    if (header == sort_header) return sort_direction === "asc" ? "desc" : "asc"
    return "asc"
  }

  function handle_sort(header) {
    const sort_dir = handle_sort_direction(header)

    set_sort_header(header)
    set_sort_direction(sort_dir)
    setReturnedFromAdd(false)

    setRows(
      rows.sort((a, b) => {
        let left = a[header]
        let right = b[header]
        if (typeof a == "string") {
          left = a[header].toLowerCase()
          right = b[header].toLowerCase()
        }

        if (header == "Required Device Version") {
          let leftArray = a[header].split("-")
          let leftText = leftArray[1]
          let leftDigits = leftArray[0].replace("v", "").split(".")
          let leftMajor = parseInt(leftDigits[0])
          let leftMinor = parseInt(leftDigits[1])
          let leftPatch = parseInt(leftDigits[2])

          let rightArray = b[header].split("-")
          let rightDigits = rightArray[0].replace("v", "").split(".")
          let rightMajor = parseInt(rightDigits[0])
          let rightMinor = parseInt(rightDigits[1])
          let rightPatch = parseInt(rightDigits[2])

          if (sort_dir === "asc") {
            if (leftMajor < rightMajor) return -1
            if (leftMajor > rightMajor) return 1
          } else if (sort_dir === "desc") {
            if (leftMajor < rightMajor) return 1
            if (leftMajor > rightMajor) return -1
          }

          if (sort_dir === "asc") {
            if (leftMinor < rightMinor) return -1
            if (leftMinor > rightMinor) return 1
          } else if (sort_dir === "desc") {
            if (leftMinor < rightMinor) return 1
            if (leftMinor > rightMinor) return -1
          }

          if (sort_dir === "asc") {
            if (leftPatch < rightPatch) return -1
            if (leftPatch > rightPatch) return 1
          } else if (sort_dir === "desc") {
            if (leftPatch < rightPatch) return 1
            if (leftPatch > rightPatch) return -1
          }
        }

        if (header == "Devices pinged in last 24 hrs") {
          let leftArray = a[header]
            .replace(" ", "")
            .split(/\%|\(|\)|\//)
            .filter((x) => x)
          let leftPer = leftArray[0]
          let leftPinged = leftArray[1]
          let leftTotal = leftArray[2]

          let rightArray = b[header]
            .replace(" ", "")
            .split(/\%|\(|\)|\//)
            .filter((x) => x)
          let rightPer = parseInt(rightArray[0])
          let rightPinged = parseInt(rightArray[1])
          let rightTotal = parseInt(rightArray[2])

          if (sort_dir === "asc") {
            if (leftPer < rightPer) return -1
            if (leftPer > rightPer) return 1
          } else if (sort_dir === "desc") {
            if (leftPer < rightPer) return 1
            if (leftPer > rightPer) return -1
          }

          if (leftTotal < rightTotal) return 1
          if (leftTotal > rightTotal) return -1

          if (leftPinged < rightPinged) return 1
          if (leftPinged > rightPinged) return -1
        } else {
          if (left < right) {
            if (sort_dir === "asc") return -1
            if (sort_dir === "desc") return 1
          } else {
            if (sort_dir === "asc") return 1
            if (sort_dir === "desc") return -1
          }
        }

        return 0
      })
    )
  }

  function handle_select(uid) {
    setRows(
      rows.map((row) => {
        if (row["UID"] !== uid) return row
        row["checked"] = row["checked"] ? false : true
        return row
      })
    )
  }

  function toggle_select_all(rows_per_page, start_idx) {
    const max = rows.slice(start_idx, start_idx + rows_per_page).length

    const all_toggled =
      rows
        .slice(start_idx, start_idx + rows_per_page)
        .filter((row) => row.checked).length == max

    for (let i = start_idx; i < start_idx + max; i++) {
      rows[i].checked = all_toggled ? false : true
    }

    setRows([...rows])
  }

  function rows_unselect_all() {
    setRows(
      rows.map((row) => {
        row["checked"] = false
        return row
      })
    )
    set_select_all(false)
  }

  function rows_select_all() {
    setRows(
      rows.map((row) => {
        row["checked"] = true
        return row
      })
    )
    set_select_all(true)
  }

  function rows_selected() {
    return rows.filter((row) => row["checked"] === true)
  }

  function handle_settings_modal() {
    set_settings_modal_active(true)
  }

  function handleStatusModal() {
    setStatusModalActive(true)
  }

  function handleDeploymentTypeModal() {
    setDeploymentTypeModalActive(true)
  }

  async function handle_settings_modal_update(_settingsPackage) {
    if (!rows_selected().length || !_settingsPackage) return
    set_settings_modal_active(false)
    setLoading(true)

    const selected = rows_selected().reduce((coll, curr) => {
      return coll.concat(curr.UID)
    }, [])

    const locations_to_update = locations
      .filter((location) => selected.indexOf(location.uid) > -1)
      .map((location) =>
        Object.assign(location, { feature_flags: location.feature_flags || [] })
      )

    setTimeout(() => {
      API.adminLocationsPut(
        locations_to_update.map((row) =>
          Object.assign(row, {
            settings_package_uid: _settingsPackage,
          })
        )
      )
        .then((res) => {
          set_settings_modal_active(false)
          rows_unselect_all()
          setLoading(false)
          if (res.status === 200) {
            addToastMessage({
              iconClass: "Circled-checkmark",
              message: `Successfully updated product configuration for ${selected.length} locations.`,
              status: "success",
            })
            setCurrentCompany(companyUID, dispatch)
          } else {
            addToastMessage({
              iconClass: "Circled-plus",
              message:
                "There was an error updating product configuration for locations.",
              status: "failure",
            })
          }
        })
        .catch((e) => {
          setLoading(false)
          console.log(`Saving Error: ${e}`)
          addToastMessage({
            iconClass: "Circled-plus",
            message:
              "There was an error updating product configuration for locations.",
            status: "failure",
          })
        })
    }, 1000)
  }

  async function handleStatusUpdate() {
    if (!rows_selected().length || !status) return
    const selected = rows_selected().map((r) => r["UID"])
    const updaters = locations
      .filter((l) => selected.includes(l.uid))
      .map((l) => Object.assign(l, { status }))
      .map((l) => Object.assign(l, { featureFlags: l.feature_flags || [] }))

    console.log(updaters)

    API.adminLocationsPut(updaters).then((res) => {
      if (res.status === 200) {
        addToastMessage({
          iconClass: "Circled-checkmark",
          message: `Status "${status}" applied to ${selected.length} locations`,
          status: "success",
        })
        setCurrentCompany(companyUID, dispatch)
        setStatusModalActive(false)
        rows_unselect_all()
      }
    })
  }

  async function handleDeploymentTypeUpdate() {
    if (!rows_selected().length || !deployment_type) return
    const selected = rows_selected().map((r) => r["UID"])
    const updaters = locations
      .filter((l) => selected.includes(l.uid))
      .map((l) => Object.assign(l, { deployment_type }))
      .map((l) => Object.assign(l, { featureFlags: l.feature_flags || [] }))

    API.adminLocationsPut(updaters).then((res) => {
      if (res.status === 200) {
        addToastMessage({
          iconClass: "Circled-checkmark",
          message: `Deployment Type "${deployment_type}" applied to ${selected.length} locations`,
          status: "success",
        })
        setCurrentCompany(companyUID, dispatch)
        setDeploymentTypeModalActive(false)
        rows_unselect_all()
      }
    })
  }

  const updateActiveTab = (tab) => {
    if (tab) setActiveTab(tab)
    else if (window.location.href.split("#")[1])
      setActiveTab(window.location.href.split("#")[1])
    else setActiveTab("locations")
  }

  const filterSettingsPackages = (e) => {
    if (e === "0") {
      setRows(construct_rows(locations))
    } else if (e === "1") {
      setRows(
        construct_rows(locations).filter((r) => {
          return (
            r["Product Configuration"] == undefined ||
            r["Product Configuration"] == ""
          )
        })
      )
    } else {
      setRows(
        construct_rows(locations).filter((r) => {
          return r["Product Configuration"] == filteredSP[e]
        })
      )
    }
  }

  function handleActionSelect(idx) {
    if (idx == "0") {
      handle_settings_modal()
    }
    if (idx === "1") {
      handleStatusModal()
    }
    // if (idx === "2") {
    //   handleDeploymentTypeModal()
    // }
  }

  const SettingsPackageModal = ({ setVal, val }) => {
    const [_settingsPackage, _setSettingsPackage] = useState("null")

    return (
      <ModalModern
        header={"Update Product Configurations"}
        shown={settings_modal_active}
        closeModal={() => set_settings_modal_active(false)}
        actionLabel={"Update"}
        cancelLabel={"Nevermind"}
        callback={() => handle_settings_modal_update(_settingsPackage)}
        id={"Settings-Package-Locations-Modal"}
        validValueFlag={_settingsPackage}
      >
        <div>{`Choose a Product Configuration to apply to ${selected_count} selected ${
          selected_count == 1 ? "location" : "locations"
        }.`}</div>
        <div className="location-settings-package-modal">
          <div className="select">
            <DropDownSelect
              options={settingsPackagesOptions}
              val={_settingsPackage || undefined}
              onChange={(e) => {
                e.persist()
                _setSettingsPackage(e.target.value)
              }}
              nullOption={false}
              nullOptionLabel={"None"}
              className={"round"}
            />
          </div>
        </div>
      </ModalModern>
    )
  }

  return (
    <div className="admin wrapper Location-listing-container">
      {settingsPackagesOptions && settings_modal_active && (
        <SettingsPackageModal
          val={settings_package}
          setVal={set_settings_package}
        />
      )}
      <StatusModal />
      <DeploymentTypeModal />
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${companyMap ? companyMap[companyUID] : ""}`} • Kinetic</title>
      </Helmet>
      <main className="admin container">
        {render && renderSidePanel("COMPANY")}
        {passBreadCrumbs([
          { content: <Icon iconClass={"Skyline"} />, route: "/companies" },
          { content: companyMap && companyMap[companyUID], route: "" },
        ])}
        <div className="main-content">
          <div className="main-heading-parent">
            <a
              className={`main-heading tab ${
                activeTab === "locations" ? "active" : ""
              }`}
              href="#locations"
              onClick={() => updateActiveTab("locations")}
            >
              Locations
            </a>
            <a
              className={`main-heading tab ${
                activeTab === "managers" ? "active" : ""
              }`}
              href="#managers"
              onClick={() => updateActiveTab("managers")}
            >
              Managers
            </a>
            <div
              className={`main-heading-tab-slider ${activeTab} ${
                render ? "active" : ""
              }`}
            ></div>
          </div>
          <div className="search-and-action-parent">
            {activeTab === "managers" && (
              <SearchBar
                clearFilterData={onFilterData}
                placeholder={`Search ${activeTab}`}
                handleSearch={onFilterData}
                data={
                  activeTab === "locations"
                    ? rows
                    : activeTab === "managers" &&
                      managers?.length > 0 &&
                      managers
                }
              />
            )}
            <div className="Action-menu-parent left">
              {activeTab === "locations" && (
                <div className="searchbar">
                  {search_text !== "" ? (
                    <Icon
                      iconClass="Circled-plus"
                      size={"1.1rem"}
                      onClick={() => set_search_text("")}
                    />
                  ) : (
                    <Icon iconClass="Search" size={"1.1rem"} />
                  )}
                  <input
                    value={search_text}
                    type="text"
                    placeholder={`Search locations...`}
                    onChange={(e) => set_search_text(e.target.value)}
                  />
                </div>
              )}
              {activeTab === "locations" && filteredSP.length > 0 && (
                <div className="settings-package-select">
                  <SelectBox
                    full
                    initialValue="All Product Configurations"
                    options={filteredSP}
                    onChange={(e) => filterSettingsPackages(e)}
                  />
                </div>
              )}
            </div>
            <div className="Action-menu-parent right">
              <div className="Action-menu">
                {render && activeTab === "locations" ? (
                  <>
                    <div
                      className={`add-button button secondary ${
                        locations && locations.length === 0 ? "disabled" : ""
                      }`}
                      onClick={() => setDownloadCSV(true)}
                    >
                      <Icon iconClass={"Download"} />
                      <p>DOWNLOAD CSV</p>
                    </div>
                    <Link
                      to={`/locations/csv`}
                      className="add-button upload button secondary"
                    >
                      <Icon iconClass={"Upload"} />
                      IMPORT CSV
                    </Link>
                    <div
                      onClick={() => {
                        history.push(`/locations/add?company=${companyUID}`)
                        renderSidePanel()
                      }}
                      className="add-button button"
                    >
                      <Icon iconClass={"Circled-plus"} />
                      ADD LOCATION
                    </div>
                  </>
                ) : (
                  managers &&
                  activeTab === "managers" && (
                    <>
                      <div
                        className={`add-button button secondary ${
                          managers && managers.length === 0 ? "disabled" : ""
                        }`}
                        onClick={() => setDownloadCSV(true)}
                      >
                        <Icon iconClass={"Download"} />
                        <p>DOWNLOAD CSV</p>
                      </div>
                      <Link
                        to={`/companies/${companyUID}/managers/csv`}
                        className="add-button upload button secondary"
                      >
                        <Icon iconClass={"Upload"} />
                        <p>IMPORT FROM CSV</p>
                      </Link>
                      <Link
                        to={
                          locations && locations.length > 0
                            ? `/companies/${companyUID}/managers/add`
                            : `/locations/add`
                        }
                        className="add-button button"
                      >
                        <Icon iconClass={"Circled-plus"} />
                        <p>
                          {locations && locations.length > 0
                            ? "ADD MANAGER"
                            : "MUST ADD LOCATION"}
                        </p>
                      </Link>
                    </>
                  )
                )}
              </div>
            </div>
            {loading && (
              <Loading
                errorState={errorState}
                message={message}
                setLoading={setLoading}
                backdropFilter={"blur(4px)"}
              />
            )}
          </div>
          <section className="table-container">
            {render ? (
              activeTab === "locations" ? (
                <div>
                  <div className="flex">
                    <div className="count">{selected_count} selected</div>
                    {/* <div
                      onClick={handle_settings_modal}
                      className={`border pad ${
                        !(selected_count > 0) && "transparent"
                      } ${selected_count > 0 && "pointer"}`}
                    >
                      Update Product Configuration
                    </div> */}
                    <div>
                      <SelectBox
                        disabled={rows_selected().length < 1}
                        full
                        initialValue="Actions"
                        options={[
                          "Update Product Configuration",
                          "Update Status",
                          //"Update Deployment Type",
                        ]}
                        onChange={(e) => handleActionSelect(e)}
                      />
                    </div>
                  </div>

                  <SimpleTableNext
                    checks
                    checkbox
                    fallback={
                      "There are no locations present for this company."
                    }
                    rows={rows}
                    headers={[
                      "Name",
                      "UID",
                      "Devices pinged in last 24 hrs",
                      "Product Configuration",
                      "Required Device Version",
                      "IR Reporting Start Date",
                      // "SD Report Start",
                      "Employees with HRP Vib.",
                      "Status",
                      //"Deployment Type",
                      "Created",
                    ]}
                    handle_sort={handle_sort}
                    sort_direction={sort_direction}
                    sort_header={sort_header}
                    handle_select={handle_select}
                    select_all={select_all}
                    toggle_select_all={toggle_select_all}
                  />
                </div>
              ) : (
                activeTab === "managers" && (
                  <SimpleTable
                    ignoreKeys={[
                      "uid",
                      "hashedPassword",
                      "device",
                      "companyUID",
                      "groupUID",
                      "superuser",
                      "role",
                    ]}
                    rows={
                      filteredRows.length > 0
                        ? filteredRows
                        : managers?.length > 0
                        ? managers
                        : [{ noData: "This company has no managers" }]
                    }
                    editDisabled={true}
                  />
                )
              )
            ) : (
              <Loading
                background={"none"}
                positionRelative={true}
                height={"600px"}
              />
            )}
          </section>
        </div>
      </main>
      {downloadCSV && activeTab === "managers" && (
        <CSVdownloadModal
          csvData={managers}
          filename={`managers - ${companyMap[companyUID]}.csv`}
          close={() => setDownloadCSV(false)}
          category="Managers"
          ignoreKeys={["superuser", "device"]}
        />
      )}
      {downloadCSV && activeTab === "locations" && (
        <CSVdownloadModal
          csvData={locations}
          filename={`locations - ${companyMap[companyUID]}.csv`}
          close={() => setDownloadCSV(false)}
          category="Locations"
          ignoreKeys={[
            "address1",
            "address2",
            "city",
            "country",
            "created_at",
            "dnsServers",
            "ntpServers",
            "postal_code",
            "province",
            "proxyServers",
            "shiftLengthHours",
            "staticIPs",
            "timezoneName",
            "wifiESSID",
            "wifiPassword",
            "status",
            "deployment_type",
            "feedback_on_count",
          ]}
        />
      )}
    </div>
  )

  function StatusModal() {
    return (
      <Modal
        title="Update Status"
        shown={statusModalActive}
        closeModal={() => setStatusModalActive(false)}
        id="updatestatus"
      >
        <div>{`Choose a status to apply to ${selected_count} selected ${
          selected_count == 1 ? "location" : "locations"
        }.`}</div>
        <div className="location-settings-package-modal">
          <div className="select">
            <SelectBox
              value={status ? status : ""}
              initialValue={status ? status : ""}
              full
              style={{ width: "100%" }}
              options={{ open: "Open", closed: "Closed" }}
              onChange={(e) => setStatus(e)}
            />
          </div>
          <div className="button-container">
            <div
              onClick={() => setStatusModalActive(false)}
              className="location-button exit cursor"
            >
              Nevermind
            </div>
            <div
              className={`location-button accept ${!status && "transparent"} ${
                status && "cursor"
              }`}
              onClick={handleStatusUpdate}
            >
              Update
            </div>
          </div>
        </div>
      </Modal>
    )
  }

  function DeploymentTypeModal() {
    return (
      <Modal
        title="Update Deployment Type"
        shown={deploymentTypeModalActive}
        closeModal={() => setDeploymentTypeModalActive(false)}
        id="update-deployment-type"
      >
        <div>{`Choose a deployment type to apply to ${selected_count} selected ${
          selected_count == 1 ? "location" : "locations"
        }.`}</div>
        <div className="location-settings-package-modal">
          <div className="select">
            <SelectBox
              value={deployment_type ? deployment_type : ""}
              initialValue={deployment_type ? deployment_type : ""}
              full
              style={{ width: "100%" }}
              options={{ pilot: "Pilot", deployment: "Deployment" }}
              onChange={(e) => setDeploymentType(e)}
            />
          </div>
          <div className="button-container">
            <div
              onClick={() => setDeploymentTypeModalActive(false)}
              className="location-button exit cursor"
            >
              Nevermind
            </div>
            <div
              className={`location-button accept ${
                !deployment_type && "transparent"
              } ${deployment_type && "cursor"}`}
              onClick={handleDeploymentTypeUpdate}
            >
              Update
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}
