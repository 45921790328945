import React, { useEffect, useState, useRef, useCallback } from "react"
import { useSelector, useDispatch, useStore } from "react-redux"

import { Loading, Icon } from "../../../components/ui"
import ChangeLocation from "./change-location"

import { setCurrentCompany } from "../../../redux/actions/admin"
import { API } from "../../../utils/api"

import { useToastContext } from "../../../hooks"

export function InventoryEditModal({
  close,
  action,
  category,
  companyUIDfromRedux,
  disableSetCompany,
  redirect,
  selectedLocation,
}) {
  const {
    adminLocations,
    companyList,
    companyMap,
    selectedCheckboxItems,
    locationData,
  } = useSelector((state) => ({
    companyList: state.admin.companyList,
    selectedCheckboxItems: state.admin.selectedCheckboxItems,
    companyMap: state.admin.companyMap,
    adminLocations: state.admin.locationsList,
    locationData: state.admin.locationData,
  }))
  const addToastMessage = useToastContext()
  const dispatch = useDispatch()
  const _modal = useRef()
  const [locationsList, setLocationsList] = useState()
  const store = useStore()
  const [companyUID, setcompanyUID] = useState()
  const [locationUID, setLocationUID] = useState()
  const [locationName, setLocationName] = useState()
  const [selectedCheckboxItemsUIDs, setSelectedCheckboxItemsUIDs] = useState()
  const [loading, setLoading] = useState(false)
  const [fromLocation, setFromLocation] = useState()

  useEffect(() => {
    if (locationData && locationData.location) {
      setFromLocation(locationData.location.name)
    }
  }, [locationData])

  useEffect(() => {
    setTimeout(() => {
      _modal.current.classList.add("Open")
    }, 0)
  }, [])

  useEffect(() => {
    if (companyList) {
      setLocationsList(companyList)
    }
  }, [companyList])

  useEffect(() => {
    const selectedCheckboxItemsUIDsArr = []
    if (selectedCheckboxItems) {
      selectedCheckboxItems.map((o) => {
        selectedCheckboxItemsUIDsArr.push(o.uid)
      })
    }
    setSelectedCheckboxItemsUIDs(selectedCheckboxItemsUIDsArr)
  }, [selectedCheckboxItems])

  async function setCompany(company) {
    setcompanyUID(company)
    await setCurrentCompany(company, dispatch)
    setLocationsList(store.getState().admin.locationsList)
  }

  async function apiCallHelper(num = 0) {
    setLoading(true)
    if (category === "router(s)") {
      if (locationUID && selectedCheckboxItemsUIDs) {
        await API.updateConnectivityDevices(
          locationUID,
          selectedCheckboxItemsUIDs
        )
      }
      setTimeout(() => {
        setLoading(false)
      }, 500)
    } else if (category === "device(s)") {
      if (locationUID && selectedCheckboxItemsUIDs) {
        await API.moveWus(selectedCheckboxItemsUIDs, locationUID, "json").then(
          () => {
            setLoading(false)
            closeModal()
            addToastMessage({
              message: `${num} devices were moved from ${fromLocation}`,
              status: "success",
              iconClass: "Circled-checkmark",
            })
            // setTimeout(() => {
            //   setLoading(false)
            //   close()
            // }, 1800)
            // setTimeout(() => {
            redirect(locationUID)
            // }, 1850)
          }
        )
      }
    }
  }

  const closeModal = () => {
    _modal.current.classList.remove("Open")
    setTimeout(() => {
      close()
    }, 300)
  }

  return (
    <div className={"Inventory-manager-modal"} ref={_modal}>
      <div className="Inventory-manager-modal-inner">
        {action && (
          <h3>{`${action} ${selectedCheckboxItems.length} ${category}`}</h3>
        )}
        <p className={"Close-button"} onClick={closeModal}>
          <Icon iconClass={"X-mark"} size={"1.4rem"} />
        </p>
        {locationsList && (
          <ChangeLocation
            disableSetCompany={disableSetCompany}
            companyUID={companyUIDfromRedux ? companyUIDfromRedux : companyUID}
            companyList={companyList}
            setCompany={setCompany}
            setcompanyUID={setcompanyUID}
            companyMap={companyMap}
            locationsList={locationsList}
            setLocationUID={setLocationUID}
            locationUID={locationUID}
            apiCallHelper={(number) => apiCallHelper(number)}
            setLocationName={setLocationName}
            selectedDevices={selectedCheckboxItems}
          />
        )}
      </div>
      {loading && <Loading message={`Redirecting to ${locationName}...`} />}
    </div>
  )
}
