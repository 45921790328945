import React, { useState } from "react"
import ReactDOM from "react-dom"
import styled from "styled-components"
import { Theme } from "./theme.js"

export const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;

  visibility: ${(props) => (props.active ? "visible" : "hidden")};
`

export const ModalContainer = styled.div`
  padding: 2rem 2.5rem;
  background: white;
  border-radius: 8px;
`

export const ModalTitle = styled.div`
  font-size: 1.5rem;
  font-family: "MikroMedium";
  color: ${Theme.colors.gray1};
`

export function Modal({ active, children }) {
  return ReactDOM.createPortal(
    <Overlay active={active}>
      <ModalContainer>{children}</ModalContainer>
    </Overlay>,
    document.getElementById("portal-root")
  )
}

export function useModal() {
  const [active, set_active] = useState(false)

  return {
    props: {
      active,
    },
    show: () => set_active(true),
    hide: () => set_active(false),
  }
}
