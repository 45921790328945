export const deviceStatusOptions = [
  { value: "active", display: "active" },
  { value: "demo", display: "demo" },
  { value: "dev", display: "dev" },
  { value: "graveyard", display: "graveyard" },
  { value: "inactive", display: "inactive" },
  { value: "manufacturing", display: "manufacturing" },
  { value: "testing", display: "testing" },
  { value: "collection", display: "collection" },
]
