export function validateEmailAddress(email, domains) {
  const domain = email.split("@")[1]
  const emailTest = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)

  if (emailTest && domains ? domains.includes(domain) : emailTest) {
    return true
  } else {
    return false
  }
}
