import { GET_DATA_SUCCEEDED, GET_DATA_ATTEMPT } from "./app-state"

// --------------------------- Action constants --------------------------
export const UPDATE_DELTA_STATE = "delta/UPDATE_DELTA_STATE"

// --------------------------- Reducer function --------------------------
const initialState = {
  loaded: false,
  devices: {},
  employees: {},
  jobs: {},
  company: {},
  location: {},
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_DATA_ATTEMPT:
      if (action.payload && action.payload.noLoading) {
        return state
      } else {
        return {
          ...state,
          loaded: false,
        }
      }
    case GET_DATA_SUCCEEDED:
    case UPDATE_DELTA_STATE:
      return {
        ...state,
        loaded: true,
        employees: action.data.employees,
        jobs: action.data.jobs,
        devices: action.data.devices,
        company: action.data.company,
        location: action.data.location,
      }
    default:
      return state
  }
}
