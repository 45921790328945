import React, { useState } from "react"
import { connect } from "react-redux"
import { Link, useHistory } from "react-router-dom"
import QS from "query-string"

import "./styles.scss"
import {
  logIn,
  getLogInMethods,
  initialRedirectTo,
  setEmail,
} from "../../../redux/actions/user"

let oneTimeErrorMessage =
  window.KINETIC_DASHBOARD_AUTH && window.KINETIC_DASHBOARD_AUTH.message

const Login = ({ user, dispatch }) => {
  const [pending, setPending] = useState(false)
  const [methods, setMethods] = useState()
  const [errorMessage, setErrorMessage] = useState(oneTimeErrorMessage)

  const onSubmit = async (e) => {
    e.preventDefault()

    setPending(true)
    setErrorMessage(undefined)

    const { to } = QS.parse(window.location.search)

    const form = e.target
    const email = form.querySelector("[name=email]").value

    // If the login methods aren't yet known, request them.

    if (methods === undefined) {
      try {
        const res = await getLogInMethods(email, to || "/", dispatch)

        if (res.saml && !res.password) {
          window.location.href = res.saml.redirectURL
          return
        }

        setMethods(res)
      } catch (err) {
        setErrorMessage(err.message)
        return
      } finally {
        setPending(false)
      }
    } else {
      // Otherwise, the user has requested a password login.

      const password = form.querySelector("[name=password]").value

      setPending(true)

      try {
        await logIn(email, password, dispatch)
        await initialRedirectTo(to)
      } catch (err) {
        if (err.message === "should-reset") {
          setErrorMessage(
            <span>
              We have updated our security measures.{" "}
              <a href="/forgot-password">Please reset your password</a>
            </span>
          )
        } else {
          setErrorMessage(err.message)
        }

        return
      } finally {
        setPending(false)
      }
    }
  }

  return (
    <div className="login-container">
      <form onSubmit={onSubmit}>
        <h1>Welcome to Kinetic</h1>
        <p className="heading">Sign in to your organization</p>

        {methods && methods.saml && (
          <div className="saml-redirect-link">
            Or log in with{" "}
            <a href={methods.saml.redirectURL}>{methods.saml.name} →</a>
          </div>
        )}

        {errorMessage !== undefined && (
          <p className="error-msg">{errorMessage}</p>
        )}

        <input
          type="email"
          name="email"
          defaultValue={user.email}
          placeholder="you@organization.com"
          onChange={(e) => setEmail(e.value, dispatch)}
        />

        {methods && methods.password && (
          <>
            <input type="password" name="password" placeholder="password" />
            <Link to="forgot-password">Forgot password?</Link>
          </>
        )}

        <input type="submit" value="Sign in" disabled={pending} />

        <div className="new-to-kinetic">
          New to Kinetic? <Link to="/activate">Activate your account</Link>.
        </div>
      </form>
    </div>
  )
}

export default connect((state) => ({
  user: state.user,
}))(Login)
