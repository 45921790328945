import React, { useEffect, useReducer, useState, useRef } from "react"
import { useHistory } from "react-router-dom"
import { Icon } from "../../../components/ui"

import { API } from "../../../utils/api"

import {
  Button,
  FormInputSelect,
  IconChevronDown,
  DropdownContainer,
  DropdownOption,
  useDropdown,
  IconCheckboxSelected,
  IconCheckboxUnselected,
  FormInputMultiSelect,
  FormInputDiv,
  MultiSelectValue,
  IconXMark,
  FormLabel,
  FormInput,
  Theme,
  IconCalendar,
  Modal,
  useModal,
  IconLoading,
} from "../../../components/next"

import styled from "styled-components"
import { useBreadCrumbsContext } from "../../../hooks"
import { timezones } from "../../../utils/timezones"
import DayPicker from "react-day-picker"
import moment from "moment"

import "./style.scss"
import setupTermsPage from "../../setup-terms-page"

const Checkbox = styled.img`
  height: 1rem;
  margin-right: 0.5rem;
`

const Split = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  padding: 40px 0;
  border-bottom: 1px solid ${Theme.colors.gray6};
  width: 600px;
`

const FormSection = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 25px;
`

const FormSplit = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  column-gap: 20px;
`

const Title = styled.p`
  color: ${Theme.colors.gray1};
  font-weight: bold;
  font-size: 0.875rem;
`

const ChevronWrapper = styled.img`
  height: 13px;
  right: 6px;
  position: absolute;
  cursor: pointer;
  top: calc(50% + 6px);
`

const Chevron = ({ onClick }) => (
  <ChevronWrapper src={IconChevronDown} onClick={() => onClick && onClick()} />
)

const FormInputCalendar = styled(FormInputDiv)`
  padding-left: 31px;
`

const FormInputMulti = styled(FormInputDiv)`
  height: auto;
  flex-wrap: wrap;
`

const GrayContainer = styled.div`
  background: ${Theme.colors.gray7};
  padding: 1rem;
`

const FormLabelThin = styled(FormLabel)`
  font-weight: normal;
`

const CalendarWrapper = styled.img`
  position: absolute;
  top: 36px;
  left: 8px;
  height: 16px;
`

const Calendar = () => <CalendarWrapper src={IconCalendar} />

const Container = styled.div`
  position: relative;
`

const XMark = styled.img`
  height: 11px;
  right: 10px;
  top: 39px;
  position: absolute;
  cursor: pointer;
`

const XMarkMulti = styled.img`
  margin-left: 10px;
  height: 10px;
`

const DayPickerContainer = styled.div`
  position: absolute;
  background: white;
  z-index: 5000;
`

const ButtonContainer = styled.div`
  justify-content: flex-end;
  display: flex;
  margin-top: 50px;
`

const DropdownContainerErgo = styled(DropdownContainer)`
  overflow: visible;
  left: -35px;
`

const DropdownContainerTimezone = styled(DropdownContainer)`
  width: 400px;
  height: 200px;
`

const DropdownContainerFull = styled(DropdownContainer)``

const Horizon = styled.div`
  background: ${Theme.colors.gray5};
  height: 1px;
`

const HorizonMargin = styled(Horizon)`
  margin: 0 16px;
`

const PageTitle = styled.p`
  color: ${Theme.colors.gray1};
  font-size: 1.5rem;
  font-weight: bold;
`

const OptionDisabled = styled(DropdownOption)`
  cursor: not-allowed;
  opacity: 0.5;
`
const LoadingIcon = styled.img`
  width: 2rem;
`

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const PageTitleMgn = styled(PageTitle)`
  margin-bottom: 1rem;
`

const FormInputDivAutoHeight = styled(FormInputDiv)`
  height: auto;
`

const CalendarInputDiv = styled.div`
  margin-top: 4px;
`

const FlagsTextArea = styled.textarea`
  width: 100%;
  color: ${Theme.colors.gray1};
  padding: ${Theme.size(8)};
  font-size: ${Theme.size(14)};
  border: 1px solid #dfe1ea !important;
`

function LocationDetailPage() {
  const history = useHistory()
  const passBreadCrumbs = useBreadCrumbsContext()

  const [product_configs, set_product_configs] = useState([])
  const [mode, set_mode] = useState("Add")
  const [flag_overflow, set_flag_overflow] = useState(0)
  const [loading_message, set_loading_message] = useState()
  const [optional_flags_add_count, optional_flags_add_count_set] = useState(0)
  const [location_company, location_company_set] = useState("")
  const [location_status, location_status_set] = useState("closed")
  const [location_timezone, location_timezone_set] = useState()
  const [location_shift_length, location_shift_length_set] = useState(8)
  const [
    location_product_config_uid,
    location_product_config_uid_set,
  ] = useState(null)

  const [location_name, location_name_set] = useState("")
  const [
    location_reporting_ergo_start,
    location_reporting_ergo_start_set,
  ] = useState(null)

  const [initial_location_name, initial_location_name_set] = useState("")
  const [location_postal_code, location_postal_code_set] = useState("")
  const [location_uid, location_uid_set] = useState(null)
  const [location_address1, location_address1_set] = useState("")
  const [location_city, location_city_set] = useState("")
  const [location_province, location_province_set] = useState("")
  const [location_country, location_country_set] = useState("")

  const [location_required_version, location_required_version_set] = useState(
    ""
  )
  const [feature_flags, feature_flags_set] = useState({})
  const [location_base, location_base_set] = useState({})
  const [company_name, company_name_set] = useState("")
  const [
    location_feature_flags_loaded,
    location_feature_flags_loaded_set,
  ] = useState(false)

  const [location_flags, location_flags_set] = useState("")

  useEffect(() => {
    const path = window.location.pathname.split("/")
    const page_mode = path[path.length - 1].toLowerCase()

    set_mode(page_mode)
  }, [])

  useEffect(() => {
    async function get_product_configs() {
      const configs_res = await API.getProductConfigs()
      const configs = configs_res.data.data || []
      set_product_configs(configs.map((r) => r.product_config))
    }

    get_product_configs()
  }, [])

  useEffect(() => {
    async function set_company() {
      const params = new URLSearchParams(window.location.search)
      const company_id = params.get("company")

      const companies_res = await API.getCompaniesNext()

      const company = companies_res.data.data.filter(
        (c) => c.uid === company_id.toLowerCase()
      )[0]

      company_name_set(company.name)
    }

    set_company()
  }, [])

  useEffect(() => {
    async function get_feature_flags() {
      const flags_res = await API.getFeatureFlags()
      const base = flags_res.data.data.reduce((coll, curr) => {
        coll[curr.uid] = {
          ...curr,
          is_config: false,
          is_required: false,
          action: "delete",
        }
        return coll
      }, {})

      feature_flags_set(base)
    }
    get_feature_flags()
  }, [])

  useEffect(() => {
    if (mode === "add" && product_configs.length) {
      const default_product_config = product_configs.filter(
        (cfg) => cfg.default_package
      )[0]

      location_product_config_uid_set(default_product_config?.uid || null)
    }
  }, [product_configs, mode])

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    if (mode === "add") {
      location_base_set({ company_uid: params.get("company") })
      location_status_set("closed")
      location_timezone_set("America/New_York")
      location_shift_length_set(8)

      setTimeout(() => {
        loading_modal.hide()
      }, 1000)
    }
  }, [mode])

  useEffect(() => {
    async function populate_location() {
      const path = window.location.pathname.split("/")
      const location_id = path[path.length - 2]
      const data = await API.getAdminData(location_id)

      const {
        name,
        product_config_uid,
        shift_length,
        status,
        timezone,
        uid,
        address1,
        city,
        province,
        postal_code,
        country,
        reporting_ergo_start,
        feature_flags,
        required_version,
      } = data.location
      const params = new URLSearchParams(window.location.search)

      location_name_set(name || "")
      initial_location_name_set(name || "")
      location_product_config_uid_set(product_config_uid || null)
      location_shift_length_set(shift_length || null)
      location_status_set(status || null)
      location_timezone_set(timezone || null)
      location_base_set({ ...data.location })
      location_company_set(params.get("company"))
      location_uid_set(uid)
      location_address1_set(address1 || "")
      location_city_set(city || "")
      location_province_set(province || "")
      location_postal_code_set(postal_code || "")
      location_country_set(country || "")
      location_reporting_ergo_start_set(reporting_ergo_start || null)
      location_flags_set(feature_flags ? feature_flags.join(",") : "")
      location_required_version_set(required_version || "")

      const location_flags_res = await API.getLocationFeatureFlags(uid)
      const location_flags = location_flags_res.data.data
      const flags_res = await API.getFeatureFlags()

      const base = flags_res.data.data.reduce((coll, curr) => {
        coll[curr.uid] = {
          ...curr,
          is_config: false,
          is_required: false,
          action: "delete",
        }
        return coll
      }, {})

      for (let flag of location_flags || []) {
        base[flag.uid] = {
          ...base[flag.uid],
          action: "add",
        }
      }

      check_product_flags(product_config_uid, base, true)

      setTimeout(() => {
        loading_modal.hide()
      }, 1000)
    }

    if (mode === "edit") {
      set_loading_message("Loading location data...")
      loading_modal.show()
      populate_location()
    }
  }, [mode])

  async function check_product_flags(config, flags, initial = false) {
    if (!config) {
      feature_flags_set(flags)
      return
    }
    const product_config_res = await API.getProductConfig(config)
    const product_config =
      product_config_res?.data?.data[0]?.product_config || {}

    if (!initial) {
      for (let flag_id of Object.keys(flags)) {
        if (flags[flag_id].action === "add") {
          flags[flag_id].action = "delete"
        }

        flags[flag_id].is_required = false
        flags[flag_id].is_config = false
      }
    }

    for (let flag of product_config.product_config_feature_flags) {
      flags[flag.feature_flag_uid] = {
        ...flags[flag.feature_flag_uid],
        is_required: flag.is_required,
        is_config: true,
      }
    }

    feature_flags_set(flags)
  }

  useEffect(() => {
    const count = Object.values(feature_flags).reduce((coll, curr) => {
      if (curr.is_config && curr.action === "add" && curr.required != true) {
        coll = coll + 1
      }
      return coll
    }, 0)

    optional_flags_add_count_set(count)
  }, [feature_flags])

  function switch_product_config(product_config_uid) {
    const flags_copy = { ...feature_flags }

    for (let flag_id of Object.keys(flags_copy)) {
      if (flags_copy[flag_id].action === "add") {
        flags_copy[flag_id].action = "delete"
      }

      if (!product_config_uid) {
        flags_copy[flag_id].is_config = false
        flags_copy[flag_id].is_required = false
      }
    }

    check_product_flags(product_config_uid, flags_copy)
    location_product_config_uid_set(product_config_uid)
  }

  async function save_flags(location_uid) {
    const delete_flags = Object.values(feature_flags).filter(
      (f) => f.action === "delete"
    )
    const add_flags = Object.values(feature_flags).filter(
      (f) => f.action === "add"
    )

    for (let flag of delete_flags) {
      await API.deleteLocationFeatureFlags(location_uid, flag.uid)
    }
    for (let flag of add_flags) {
      await API.postLocationFeatureFlags(location_uid, flag.uid)
    }
  }

  async function handle_save() {
    loading_modal.show()
    set_loading_message(
      mode === "edit" ? "Updating location data..." : "Creating location..."
    )

    const sync = {
      ...location_base,
      name: location_name,
      status: location_status,
      reporting_ergo_start: location_reporting_ergo_start,
      product_config_uid: location_product_config_uid,
      address1: location_address1,
      city: location_city,
      province: location_province,
      postal_code: location_postal_code,
      country: location_country,
      timezone: location_timezone,
      shift_length: location_shift_length,
      required_version: location_required_version,
      feature_flags: location_flags,
    }

    if (mode === "edit") {
      await API.putAdminLocation(sync)
      await save_flags(sync.uid)
    }
    if (mode === "add") {
      const created = await API.postAdminLocation(sync)
      const comp_locations = await API.getCompanyLocations(sync.company_uid)
      const match = comp_locations.locationsList.find(
        (cl) => cl.location.name === sync.name
      )
      await save_flags(match.location.uid)
    }

    const to =
      mode === "add"
        ? `/companies/${sync.company_uid}`
        : `/locations/${sync.uid}`

    setTimeout(() => {
      loading_modal.hide()
      history.push(to)
    }, 1000)
  }

  function uppercase(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  const ref_ergo_input = useRef()
  const ref_ergo_dropdown = useRef()
  const dropdown_ergo = useDropdown(
    ref_ergo_input.current,
    ref_ergo_dropdown.current
  )

  const ref_config_input = useRef()
  const ref_config_dropdown = useRef()
  const dropdown_config = useDropdown(
    ref_config_input.current,
    ref_config_dropdown.current
  )

  const ref_flags_product_input = useRef()
  const ref_flags_product_dropdown = useRef()
  const dropdown_flags_product = useDropdown(
    ref_flags_product_input.current,
    ref_flags_product_dropdown.current
  )

  const ref_flags_input = useRef()
  const ref_flags_dropdown = useRef()
  const dropdown_flags = useDropdown(
    ref_flags_input.current,
    ref_flags_dropdown.current
  )

  const ref_status_input = useRef()
  const ref_status_dropdown = useRef()
  const dropdown_status = useDropdown(
    ref_status_input.current,
    ref_status_dropdown.current
  )

  const ref_timezone_input = useRef()
  const ref_timezone_dropdown = useRef()
  const dropdown_timezone = useDropdown(
    ref_timezone_input.current,
    ref_timezone_dropdown.current
  )

  const ref_required_input = useRef()
  const ref_required_dropdown = useRef()
  const dropdown_required = useDropdown(
    ref_required_input.current,
    ref_required_dropdown.current
  )

  const loading_modal = useModal()

  function toggle_flag(flag) {
    const flags_copy = { ...feature_flags }
    const action = flags_copy[flag.uid].action

    flags_copy[flag.uid].action =
      !action || action === "delete" ? "add" : "delete"

    feature_flags_set(flags_copy)
  }

  useEffect(() => {
    const length = Object.values(feature_flags).filter(
      (f) => !f.is_config && f.action === "add"
    ).length

    length > 2 && set_flag_overflow(length - 2)
    length <= 2 && set_flag_overflow(0)
  }, [feature_flags])

  return (
    <div className="admin wrapper" id="location-detail-page">
      <Modal {...loading_modal.props}>
        <ModalContainer>
          <PageTitleMgn>{loading_message}</PageTitleMgn>
          <LoadingIcon src={IconLoading} />
        </ModalContainer>
      </Modal>
      <main className="container">
        {passBreadCrumbs([
          { content: <Icon iconClass={"Skyline"} />, route: "/companies" },
          {
            content: company_name,
            route: `/companies/${location_base.company_uid}/`,
          },
          { content: uppercase(mode) + " Location", route: "" },
        ])}
        <div className="main-content detail-page">
          {/* <h1 className="main-heading"> */}
          <PageTitle>
            {mode === "edit" ? `Edit ${initial_location_name}` : `Add Location`}
          </PageTitle>
          <Split>
            <Title>Basic Info</Title>
            <FormSection>
              <Container>
                <FormLabel>Location Name</FormLabel>
                <FormInput
                  value={location_name}
                  onChange={(e) => location_name_set(e.target.value)}
                />
              </Container>
              <FormSplit>
                <Container>
                  <FormLabel>Status</FormLabel>
                  <Container>
                    <FormInputDiv
                      ref={ref_status_input}
                      onClick={() => dropdown_status.show()}
                    >
                      {location_status
                        ? location_status.charAt(0).toUpperCase() +
                          location_status.slice(1)
                        : null}
                    </FormInputDiv>
                  </Container>
                  <DropdownContainer
                    ref={ref_status_dropdown}
                    {...dropdown_status.props}
                    coords={{ top: 65 }}
                  >
                    <DropdownOption
                      onClick={() => {
                        location_status_set("open")
                        dropdown_status.hide()
                      }}
                    >
                      Open
                    </DropdownOption>
                    <DropdownOption
                      onClick={() => {
                        location_status_set("closed")
                        dropdown_status.hide()
                      }}
                    >
                      Closed
                    </DropdownOption>
                  </DropdownContainer>
                  <Chevron onClick={() => dropdown_status.show()} />
                </Container>
                <Container>
                  <FormLabel>Reporting Start Date</FormLabel>
                  <FormInputCalendar
                    onClick={() => dropdown_ergo.show()}
                    placeholder="No Date Set"
                    ref={ref_ergo_input}
                  >
                    <Calendar />
                    <CalendarInputDiv>
                      {location_reporting_ergo_start
                        ? moment(location_reporting_ergo_start).format(
                            "ddd, MMMM DD, YYYY"
                          )
                        : "None Selected"}
                    </CalendarInputDiv>
                  </FormInputCalendar>
                  {location_reporting_ergo_start && (
                    <XMark
                      src={IconXMark}
                      onClick={(e) => {
                        e.stopPropagation()
                        location_reporting_ergo_start_set(null)
                      }}
                    />
                  )}
                  <DropdownContainerErgo
                    ref={ref_ergo_dropdown}
                    {...dropdown_ergo.props}
                    coords={{ top: 65 }}
                  >
                    <DayPickerContainer>
                      <DayPicker
                        selectedDays={[new Date(location_reporting_ergo_start)]}
                        onDayClick={(e) => {
                          dropdown_ergo.hide()
                          location_reporting_ergo_start_set(e.toISOString())
                        }}
                      />
                    </DayPickerContainer>
                  </DropdownContainerErgo>
                  {!location_reporting_ergo_start && (
                    <Chevron onClick={() => dropdown_ergo.show()} />
                  )}
                </Container>
              </FormSplit>
            </FormSection>
          </Split>
          <Split>
            <Title>Product Settings</Title>
            <div>
              <FormLabel>Feature Settings</FormLabel>
              <GrayContainer>
                <FormSection>
                  <Container>
                    <FormLabelThin>
                      1. Select Product Configuration
                    </FormLabelThin>
                    <FormInputDivAutoHeight
                      ref={ref_config_input}
                      onClick={() => dropdown_config.show()}
                    >
                      {product_configs.filter(
                        (c) => c.uid === location_product_config_uid
                      )[0]?.name || "None Selected"}
                    </FormInputDivAutoHeight>
                    <DropdownContainerFull
                      ref={ref_config_dropdown}
                      {...dropdown_config.props}
                      coords={{ top: 65 }}
                    >
                      <DropdownOption
                        onClick={() => {
                          switch_product_config(null)
                          dropdown_config.hide()
                        }}
                      >
                        None
                      </DropdownOption>
                      <HorizonMargin />
                      {product_configs.map((config) => {
                        return (
                          <DropdownOption
                            onClick={() => {
                              switch_product_config(config.uid)
                              dropdown_config.hide()
                            }}
                          >
                            {config.name}
                          </DropdownOption>
                        )
                      })}
                    </DropdownContainerFull>
                    <Chevron onClick={() => dropdown_config.show()} />
                  </Container>
                  <Container>
                    <FormLabelThin>2. Choose Optional Features</FormLabelThin>
                    <FormInputDiv
                      ref={ref_flags_product_input}
                      onClick={() => dropdown_flags_product.show()}
                    >
                      {optional_flags_add_count === 0
                        ? "None Selected"
                        : `${optional_flags_add_count} selected`}
                      &nbsp;
                    </FormInputDiv>
                    <DropdownContainerFull
                      ref={ref_flags_product_dropdown}
                      {...dropdown_flags_product.props}
                      coords={{ top: 65 }}
                    >
                      {Object.values(feature_flags)
                        .filter((f) => f.is_required)
                        .map((flag) => {
                          return (
                            <OptionDisabled>
                              <Checkbox src={IconCheckboxSelected} />
                              {flag.flag_name}
                            </OptionDisabled>
                          )
                        })}
                      {Object.values(feature_flags)
                        .filter((f) => !f.is_required && f.is_config)
                        .map((flag) => {
                          return (
                            <DropdownOption onClick={() => toggle_flag(flag)}>
                              <Checkbox
                                src={
                                  flag.action === "add"
                                    ? IconCheckboxSelected
                                    : IconCheckboxUnselected
                                }
                              />
                              {flag.flag_name}
                            </DropdownOption>
                          )
                        })}
                    </DropdownContainerFull>
                    <Chevron onClick={() => dropdown_flags_product.show()} />
                  </Container>
                  <Horizon />
                  <Container>
                    <FormLabelThin>Additional Feature Flags</FormLabelThin>
                    <FlagsTextArea
                      value={location_flags}
                      onChange={(e) => location_flags_set(e.target.value)}
                    />
                    {/* <FormInputMulti
                      ref={ref_flags_input}
                      onClick={() => dropdown_flags.show()}
                    >
                      {(flag_overflow > 0
                        ? Object.values(feature_flags)
                            .filter((f) => !f.is_config && f.action === "add")
                            .slice(0, 2)
                        : Object.values(feature_flags).filter(
                            (f) => !f.is_config && f.action === "add"
                          )
                      ).map((flag) => {
                        return (
                          <MultiSelectValue
                            onClick={(e) => {
                              e.stopPropagation()
                            }}
                          >
                            {flag.flag_name}
                            <XMarkMulti
                              src={IconXMark}
                              onClick={() => toggle_flag(flag)}
                            />
                          </MultiSelectValue>
                        )
                      })}
                      {flag_overflow > 0 && (
                        <MultiSelectValue>+{flag_overflow}</MultiSelectValue>
                      )}
                      {Object.values(feature_flags).filter(
                        (f) => !f.is_config && f.action === "add"
                      ).length === 0 && "None Selected"}
                    </FormInputMulti> */}
                    {/* <DropdownContainerFull
                      ref={ref_flags_dropdown}
                      {...dropdown_flags.props}
                      coords={{ top: 65 }}
                    >
                      {Object.values(feature_flags)
                        .filter((f) => !f.is_config)
                        .map((flag) => {
                          return (
                            <DropdownOption onClick={() => toggle_flag(flag)}>
                              <Checkbox
                                src={
                                  feature_flags[flag.uid]?.action === "add"
                                    ? IconCheckboxSelected
                                    : IconCheckboxUnselected
                                }
                              />
                              {flag.flag_name}
                            </DropdownOption>
                          )
                        })}
                    </DropdownContainerFull> */}
                    {/* <Chevron onClick={() => dropdown_flags.show()} /> */}
                  </Container>
                </FormSection>
              </GrayContainer>
            </div>
          </Split>
          <Split>
            <Title>
              Geographic <br /> Information
            </Title>
            <FormSection>
              <Container>
                <FormLabel>Address</FormLabel>
                <FormInput
                  value={location_address1}
                  onChange={(e) => location_address1_set(e.target.value)}
                />
              </Container>
              <Container>
                <FormLabel>City</FormLabel>
                <FormInput
                  value={location_city}
                  onChange={(e) => location_city_set(e.target.value)}
                />
              </Container>
              <FormSplit>
                <Container>
                  <FormLabel>State</FormLabel>
                  <FormInput
                    value={location_province}
                    onChange={(e) => location_province_set(e.target.value)}
                  />
                </Container>
                <Container>
                  <FormLabel>Postal Code</FormLabel>
                  <FormInput
                    value={location_postal_code}
                    onChange={(e) => location_postal_code_set(e.target.value)}
                  />
                </Container>
              </FormSplit>
              <Container>
                <FormLabel>Country</FormLabel>
                <FormInput
                  value={location_country}
                  onChange={(e) => location_country_set(e.target.value)}
                />
              </Container>
              <Container>
                <FormLabel>Timezone</FormLabel>
                <FormInputDiv
                  value={location_timezone}
                  ref={ref_timezone_input}
                  onClick={dropdown_timezone.show}
                >
                  {location_timezone || "None Selected"}
                </FormInputDiv>
                <Chevron src={IconChevronDown} />
                <DropdownContainerTimezone
                  ref={ref_timezone_dropdown}
                  {...dropdown_timezone.props}
                  coords={{ top: 70 }}
                >
                  {timezones.map((tz) => {
                    return (
                      <DropdownOption
                        onClick={(e) => {
                          location_timezone_set(tz)
                          dropdown_timezone.hide()
                        }}
                      >
                        {tz}
                      </DropdownOption>
                    )
                  })}
                </DropdownContainerTimezone>
              </Container>
            </FormSection>
          </Split>
          <Split>
            <Title>
              Other <br /> Configurations
            </Title>
            <FormSection>
              <Container>
                <FormLabel>Shift Length</FormLabel>
                <FormInput
                  value={location_shift_length}
                  type="number"
                  onChange={(e) => location_shift_length_set(e.target.value)}
                />
              </Container>
              <Container>
                <FormLabel>Required Version</FormLabel>
                <FormInput
                  value={location_required_version}
                  onChange={(e) =>
                    location_required_version_set(e.target.value)
                  }
                />
              </Container>
              <ButtonContainer vairant="secondary">
                <Button
                  variant="secondary"
                  onClick={() => history.push(`/locations/${location_uid}`)}
                >
                  Cancel
                </Button>
                <Button css="margin-left: 1rem;" onClick={() => handle_save()}>
                  Save Changes
                </Button>
              </ButtonContainer>
            </FormSection>
          </Split>
        </div>
      </main>
    </div>
  )
}

export default LocationDetailPage
