export const timezones = [
  "America/Anchorage",
  "America/Chicago",
  "America/Denver",
  "America/Indianapolis",
  "America/Los_Angeles",
  "America/New_York",
  "America/Phoenix",
  "America/Puerto_Rico",
  "America/Sao_Paulo",
  "Asia/Shanghai",
  "Australia/Sydney",
  "Europe/Lisbon",
  "Europe/London",
  "Europe/Paris",
  "Pacific/Honolulu",
]
