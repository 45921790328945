import React from "react"
import styled from "styled-components"
import { Theme } from "./theme"

// sizes
const sm = `
  height: 1.75rem;
  padding: 0.5rem;
  font-size: ${Theme.text.text1};
`

const md = `
  height: 2.5rem;
  padding: 1.125rem;
  font-size: ${Theme.text.text2};
`

// colors
const primary = `
  background: ${Theme.colors.blue1};
  color: ${Theme.colors.white};
`

const secondary = `
  color: ${Theme.colors.blue1};
  background: white; 
  border: 1px solid ${Theme.colors.blue1}; 
`

const gray = `
  background: ${Theme.colors.gray6};
  color: ${Theme.colors.gray1};
  padding: 1.125rem;
`

const purple = `
  background: ${Theme.colors.blue3};
  color: ${Theme.colors.gray1};
`

const white = `
  background: ${Theme.colors.white};
  color: ${Theme.colors.gray1};
`

const black = `
  background: #333; 
  color: white;
`

const Container = styled.div`
  margin-right: 0.4rem;
`

// types
const rounded = `
  border-radius: 25px;
  padding: 0 1rem;
  white-space: nowrap;
`

// effects
const hover = `
  transition: opacity 200ms ease;

  &:hover {
    opacity: 0.6;
  }
`

const sizes = {
  sm,
  md,
}

const variants = {
  primary,
  gray,
  purple,
  white,
  black,
  secondary,
}

export const Button = styled.button`
  ${hover}
  ${(props) => props.css}

  ${(props) => variants[props.variant || "primary"]};
  ${(props) => props.rounded && rounded}
  ${(props) => sizes[props.size || "md"]};

  display: flex;
  align-items: center;
  font-family: "MikroMedium";
`
